import moment from 'moment';
import i18n from '@/i18n'
import Axios from 'axios';
import _ from 'lodash';
import { httpAPI, errorHandler } from "@/api/httpAPI";

export default {
    ModulePrefix: Object.freeze(
    { 
        Saylau: "/saylau"
    }),
    Configurations: Object.freeze(
    { 
        C4: "C4",
        C5: "C5"
    }),
    guidEmpty(){
        return '00000000-0000-0000-0000-000000000000';
    },
    generateUUID() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },
    dateFormat(date, returnFormat, dateFormat){
        if (!date)
            return null;

        var dateMoment = moment(date, dateFormat);

        if (dateMoment != null && dateMoment.isValid()) {
            return dateMoment.format(returnFormat);            
        }

        return null;
    },
    checkSearch(source, search) {

        if (source == null)
            return false;

        let sourceLowerCase = source.toLowerCase();  
        let searchLowerCase = search.toLowerCase();
        let searchTokens = searchLowerCase.split(' ');
      
        return sourceLowerCase.includes(searchLowerCase) || searchTokens.every((e) => sourceLowerCase.includes(e));
    },
    checkSearchWithScore(searchStr, source, property = "name") {
        if (!source || !source)
            return false;

        let searchWords = this.getNormalizedArray(searchStr.toLowerCase());
        let sourceWord = source[property].toLowerCase();
        let score = this.calculateScore(searchWords, sourceWord);
        source.score = score;

        return score > 0 ? true : false;
    },
    checkSearchWithScoreGetArray(searchStr, sourceArr, property = "name", addproperty = null) {
        if (sourceArr == null || !Array.isArray(sourceArr) || sourceArr.length == 0)
            return [];

        if (!searchStr)
            return sourceArr;

        let searchWords = this.getNormalizedArray(searchStr.toLowerCase());
        let results = [];
    
        sourceArr.forEach(sourceElem => {
            if (!sourceElem || !sourceElem[property] || (addproperty && !sourceElem[addproperty]))
                return;

            let sourceWord = sourceElem[property].toLowerCase();
            if (addproperty && sourceElem[addproperty])
                sourceWord += ` ${sourceElem[addproperty]}`;
            let score = this.calculateScore(searchWords, sourceWord);

            if (score > 0)
                results.push({ ...sourceElem, score });
        });
        
        return _.orderBy(results, ['score', property], ['desc', 'asc']);
    },
    calculateScore(searchWords, sourceWord) {
        // модификаторы токена
        let mods = ['*', '+', '^', '$'];
        const modification = (token, word) => {
            token = token.replaceAll('*', '.*');
            token = token.replaceAll('+', '.+');
            const expression = new RegExp(token, 'g');
            return expression.test(word) ? 0 : -1;
        };

        // массив слов исходной строки
        let sourceWords = this.getNormalizedArray(sourceWord);
        // кол-во найденных токенов поиска в словах исходной строки
        let accuracy = 0;
        // штраф, по нахождению в порядке исходной строки
        let penalty = 0;
        // максимальная длина последовательности вхождений
        let maxSequence = -1;
        // текущая длина последовательности вхождения
        let sequence = -1;

        let wordsFound = [];

        // перебираем каждый токен поиска
        for (let tokenIndex = 0; tokenIndex < searchWords.length; tokenIndex++) {
            let token = searchWords[tokenIndex];
            let modified = [...token].some((x) => mods.includes(x));

            // для каждого слова исходной строки
            for (let wordIndex = 0; wordIndex < sourceWords.length; wordIndex++) {

                if (wordsFound.includes(sourceWords[wordIndex]))
                    continue;

                let index = -1;

                if (modified)
                    index = modification(token, sourceWords[wordIndex]);
                else
                    index = sourceWords[wordIndex].indexOf(token);

                // если вхождение найдено
                if (index === 0 && wordIndex >= tokenIndex) {
                    wordsFound.push(sourceWords[wordIndex]);
                    accuracy++;
                    sequence++;
                    penalty += wordIndex;
                    break;
                }
                else {
                    // если вхождение не было найдено, проверяем последовательности
                    if (sequence > maxSequence)
                        maxSequence = sequence;
                    
                    // сбрасываем последовательность
                    sequence = 0;
                    // увеличиваем штраф
                    penalty += 1;
                }
            }
        }

        // регулирование чувствительности поиска
        if (accuracy < searchWords.length)
            return 0;

        // если все токены найдены подряд
        if (sequence > maxSequence)
            maxSequence = sequence;

        // расчет цены по приоритетам
        let score = (accuracy * 100 + maxSequence * 10 - penalty) - (sourceWords.length - accuracy);
        return score;
    },
    getNormalizedArray(source) {
        if (!source) return [];
        
        return source
                .split(' ').join(',')
                .split('-').join(',')
                .split('.').join(',')
                .split("'").join(',')
                .split('"').join(',')
                .split('«').join(',')
                .split('»').join(',')
                .split(',')
                .filter(x => x);
    },
    filterNumberField: function(evt, maxlength = 0, allowfirstzero = true) {
        evt = (evt) ? evt : window.event;
        let expect = evt.target.value.toString() + evt.key.toString();
        let caretPositionStart = evt.target.selectionStart;
        let caretPositionEnd = evt.target.selectionEnd;
        if (maxlength > 0 && expect.length > maxlength && caretPositionStart == caretPositionEnd){
            evt.preventDefault();
        } else if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
            evt.preventDefault();
        } else if (expect.length > 1 && evt.key == "0" && caretPositionStart == 0 && caretPositionStart == caretPositionEnd) {
            evt.preventDefault();
        } else if (!allowfirstzero && expect.length > 1 && evt.target.value == 0 && caretPositionStart == 1) {
            evt.preventDefault();
        } else {
            return true;
        }
    },
    prepareResolutionTaskExecuter(taskId, index, selectedMember) {
        // если орг-ция ExecuterID = Guid.Empty
        // EnterpriseID должен быть заполнен всегда
        // IsInner - true, если из моей орг-ции, остальные false
        return {
            __type: "ResolutionTaskExecuter:#Avrora.Objects.Modules.Docflow.DocflowObjects",
            ParentId: taskId,
            ExecuterID: selectedMember.type === 1 ? selectedMember.workplaceId : this.guidEmpty(),
            ExecuterName: selectedMember.name,
            IsInner: selectedMember.inner,
            EnterpriseID: selectedMember.enterprise,
            EnterpriseName: selectedMember.type === 1 ? "" : selectedMember.name,
            WorkStatus: 0, // константа
            IndexInTask: index,
            Svod: false, // константа
            DelRec: false, // константа
            is_new_record: true
        };
    },
    hashCode(str) {
        var hash = 0,
          i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
          chr = str.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
    },
    getValueFromDN(DNstring, name){
        let value = "";
        let mask = name + "=";
        let separator = ",";
        let tokens = DNstring.split(separator);
        let NameToken = tokens.find(x => x.startsWith(mask));
        if (typeof NameToken !== 'undefined')
            value = NameToken.replace(mask, "");
        return value;
    },
    getNameFromDN(DNstring) {
        let name = "";

        if (!DNstring)
            return name;
        
        let separator = ",";
        let CN_MASK = "CN=";
        let GIVENNAME_MASKS = ["GIVENNAME=", "G="];

        let tokens = DNstring.split(separator);
        let CNToken = tokens.find(x => x.startsWith(CN_MASK));

        if (typeof CNToken !== 'undefined')
            name = CNToken.replace(CN_MASK, "");

        for (let GIVENNAME_MASK of GIVENNAME_MASKS) {
            let GIVENNAMEToken = tokens.find(x => x.startsWith(GIVENNAME_MASK));

            if (typeof GIVENNAMEToken !== 'undefined') {
                let givenname = GIVENNAMEToken.replace(GIVENNAME_MASK, "");

                if (givenname) {
                    name = name.concat(" ", givenname);
                }

                break;
            }
        }
    
        return name;
    },
    sanitizeName(source)
    {
        const capitalizeFirstLetter = function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
        
        if (!source)
            return "";
            
        if (source.indexOf('(') > -1)
        {
            let splitResult = source.split('(');

            if (splitResult.length <= 0)
                return source;

            source = splitResult[0].trim();
        }

        if (source.indexOf(' ') == 0)
        {
            source = source.substring(1);
        }

        let detailedName = source.split(' ');

        if (detailedName.length <= 0)
            return source;

        let resultName;
        detailedName.forEach(function(namePart, index) {
            if (index === 0)
                resultName = capitalizeFirstLetter(namePart.toLowerCase());
            else
                resultName += namePart[0] ? ` ${namePart[0]}.` : '';
        });

        return resultName;
    },
    MAX_FILE_NAME_CHAR_COUNT: 100,
    MAX_FILE_SIZE: 15728640,
    MAX_FILES_SIZE: 99614720,
    MAX_FILE_COUNT: 80,
    FORBIDDEN_EXTENSIONS: [
        "ade",
        "adp",
        "gz",
        "tar",
        "bat",
        "chm",
        "cmd",
        "com",
        "cpl",
        "exe",
        "hta",
        "ins",
        "isp",
        "jse",
        "lib",
        "lnk",
        "mde",
        "msc",
        "msp",
        "mst",
        "pif",
        "scr",
        "sct",
        "shb",
        "sys",
        "vb",
        "vbe",
        "vbs",
        "vxd",
        "wsc",
        "wsf",
        "wsh",
        "apx",
        "dll",
        "url"
    ],
    FORBIDDEN_SEQUENCES: [
        "!!"
    ],
    prepareAttachment(file, dataSource, notify) 
    {
        var attachment = {
            file: file,
            Id: this.generateUUID(),
            Name: file.name,   
            Length: file.size,
            Group: 0
        };
        
        if (file.name.length > this.MAX_FILE_NAME_CHAR_COUNT)
            attachment.Message = i18n.t("Превышена_допустимая_длина_имени_файла_limit.message", { limit: this.MAX_FILE_NAME_CHAR_COUNT });

        if (!attachment.Message && this.FORBIDDEN_SEQUENCES.some(seq => file.name.includes(seq)))
            attachment.Message = i18n.t("Недопустимое_имя_файла");

        if (!attachment.Message && file.size > this.MAX_FILE_SIZE)
            attachment.Message = i18n.t("Превышен_лимит_размера_вложения_limit.message", { limit: '15 Мб' });
        
        let currentDSSize = dataSource.reduce((dsSize, file) => {
            if (!!file.Message || !!file.Progress)
                return dsSize;
            else
                return dsSize += file.Length;
        }, 0);

        if (!attachment.Message && currentDSSize + file.size > this.MAX_FILES_SIZE)
            attachment.Message = i18n.t("Превышен_лимит_размера_вложений_limit.message", { limit: '95 Мб' });
        
        //Закомментировано по задаче Iceblooms/AvroraIssuesRepo#74
        // if (!attachment.Message && dataSource.length + 1 > this.MAX_FILE_COUNT)
        //     attachment.Message = i18n.t("Превышен_лимит_количества_вложений_limit.message", { limit: this.MAX_FILE_COUNT });

        var fileExtention = file.name.match(new RegExp('[^.]+$'))[0].toLowerCase()
        
        if(!attachment.Message && this.FORBIDDEN_EXTENSIONS.includes(fileExtention))
            attachment.Message = i18n.t("Недопустимое_расширение_файла");

        if(!attachment.Message && dataSource.find(i => i.Name == attachment.Name))
            attachment.Message = i18n.t("Вложение_с_таким_именем_уже_существует");

        if (!attachment.Message)
        {
            attachment.Progress = '0%';
            attachment.CancelTokenSource = Axios.CancelToken.source();
        }
        else
        {
            notify.alert(i18n.t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: attachment.Name, reason: attachment.Message }));
        }
        
        return attachment;
    },
    getDaysDiff(controlDate) {
        let diff = moment(controlDate,'DD.MM.YYYY HH:mm:ss').diff(moment(Date.now()), 'days');
        return diff;
    },
    getExecuterStatusColor(stringValue) {
        switch(stringValue)
        {
            case 'success':
                return "#43B581";
            case 'warning':
                return "#FAA61A";
            case 'danger':
                return "#F04747";
            default:
                return null;
        }
    },
    exportList(url, notify, exportFileName){
        let onResolve = (response) => {
            let filename = exportFileName ?? "Export.xlsx";
            let headerLine = response.headers['content-disposition'];

            if (headerLine) {
                let token = headerLine.split(';').map(t => t.trim()).find(t => t.startsWith("filename*=UTF-8"));

                if (token)
                    filename = decodeURIComponent(token.replace("filename*=UTF-8''", ""));
            }

            notify.success(`Загрузка файла "${filename}" завершена`);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        };

        let onReject = (error) => {
            errorHandler(error);
        };
        if (url){
            let request = httpAPI.get(`${url}&export=true`, { responseType: 'blob', skipErrorHandler: true });
            notify.async(request, onResolve, onReject, i18n.t("Подготовка_данных"), { icons: { enabled: true } });
        }
    }
};
