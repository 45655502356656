export default {
    namespaced: true,
    state: () => ({
        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "createdate" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
        category: "-1",
        tableSearch: ""
    }),
    mutations: {
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
        SET_CATEGORY (state, payload) {
            state.category = payload;
        },
        SET_TABLE_SEARCH (state, payload) {
            state.tableSearch = payload;
        }
    },
    getters: {
        getTableOptions: (s) => s.tableOptions,
        getCategory: (s) =>  s.category,
        getTableSearch: (s) => s.tableSearch
    }
}