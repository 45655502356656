import votersInPartList from "@/store/saylau/lists/store/voters-in-part-list";
import tempVotersInPartList from "@/store/saylau/lists/store/temp-voters-in-part-list";
import votersOnDistribution from "@/store/saylau/lists/store/voters-on-distribution";
import votersOutDistribution from "@/store/saylau/lists/store/voters-out-distribution";
import votersOnActualization from "@/store/saylau/lists/store/voters-on-actualization";
import electionParts from "@/store/saylau/lists/store/election-parts";
import addresses from "@/store/saylau/lists/store/addresses";
import localSearch from "@/store/saylau/lists/store/local-search";
import gbdflSearch from "@/store/saylau/lists/store/gbdfl-search";

const lists = {
    namespaced: true,
    state: {

    },
    actions: {

    },
    modules: {
        votersInPartList,
        tempVotersInPartList,
        votersOnDistribution,
        votersOutDistribution,
        votersOnActualization,
        electionParts,
        addresses,
        localSearch,
        gbdflSearch
    }
}

export default lists;