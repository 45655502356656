export default {
    namespaced: true,
    state: () => ({
        status: "AllOutgoings",
        search: null,
        extendedFilter: {
            EFTheme: {
                label: "Краткое_содержание",
                value: null
            },
            RegNumber: {
                label: "Исходящий_номер",
                value: null
            },
            RegDateFrom: {
                label: "Дата_исходящего_c",
                value: null
            },
            RegDateTo: {
                label: "Дата_исходящего_по",
                value: null
            },
            Language: {
                label: "Язык_документа",
                value: null
            },
            NomenclatureId: {
                label: "Номенклатура",
                value: []
            },
            JuridicalDocType: {
                label: "Тип_документа",
                value: null
            },
            OutgoingExecuters: {
                label: "Исполнитель",
                value: []
            },
            Recipients: {
                label: "Получатель",
                value: []
            },
            SendStatus: {
                label: "Статус_отправки",
                value: null
            },
            SignerEmployeeIds: {
                label: "Подписал",
                value: []
            },
            DocumentAuthor: {
                label: "Кем_создано",
                value: []
            }
        },
        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "regdate" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
    }),
    mutations: {
        SET_STATUS (state, payload) {
            state.status = payload;
            state.tableOptions.page = 1;
        },
        SET_SEARCH (state, payload) {
            state.search = payload;
            state.tableOptions.page = 1;
        },
        SET_EXTENDED_FILTER (state, payload) {
            state.extendedFilter = payload;
            state.tableOptions.page = 1;
        },
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
    },
    getters: {
        getTableOptions: (s) => s.tableOptions,
        getStatus: (s) => s.status,
        getSearch: (s) => s.search,
        getExtendedFilter: (s) => s.extendedFilter,
    }
}
