export default {
    namespaced: true,
    state: () => ({
        search: '',
        status: '1',
        srok: 'All',
        extendedFilter: {
            EFTheme: {
                label: "Краткое_содержание",
                value: null
            },
            RegNumber: {
                label: "Рег._номер",
                value: null
            },
            Correspondent: {
                label: "Корреспондент",
                value: []
            },
            RegDateFrom: {
                label: "Дата_регистрации_c",
                value: null
            },
            RegDateTo: {
                label: "Дата_регистрации_по",
                value: null
            },
            Language: {
                label: "Язык_документа",
                value: null
            },
            Executers: {
                label: "Исполнитель",
                value: []
            },
            ResolutionAuthor: {
                label: "Автор_резолюции",
                value: []
            },
            HasAnswer: {
                label: "Дан_ответ",
                value: null
            },
            Counter: {
                label: "Журнал_регистрации",
                value: null
            },
            NomenclatureId: {
                label: "Номенклатура",
                value: []
            }
        },
        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "controldate" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
    }),
    mutations: {
        SET_SEARCH (state, payload){
            state.search = payload;
        },
        SET_STATUS (state, payload){
            state.status = payload;
        },
        SET_SROK (state, payload){
            state.srok = payload;
        },
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
        SET_EXTENDED_FILTER (state, payload) {
            state.extendedFilter = payload;
        }
    },
    getters: {
        getSearch: (state) => state.search,
        getStatus: (state) => state.status,
        getSrok: (state) => state.srok,
        getExtendedFilter: (state) => state.extendedFilter,
        getTableOptions: (state) => state.tableOptions,
    }
}