import router from '@/router';
import { httpAPI } from "@/api/httpAPI";
import { resetStoreState } from '@/store';
import i18n from '@/i18n';

const auth = {
    namespaced: true,
    state: {
        authorized: false,
        authPending: false,
        logoutPending: false,
        userInfo: null
    },
    mutations: {
        SET_AUTHORIZED(state, payload) {
            state.authorized = payload.authorized;
        },
        SET_AUTH_PENDING(state, payload) {
            state.authPending = payload.pending;
        },
        SET_LOGOUT_PENDING(state, payload) {
            state.logoutPending = payload.pending;
        },
        SET_USER_INFO(state, payload) {
            state.userInfo = payload.userInfo;

            if (payload.userInfo) {
                httpAPI.defaults.headers.common['API_KEY'] = payload.userInfo.Token;
                httpAPI.defaults.headers.common['API_USER'] = Buffer.from(payload.userInfo.UserName).toString('base64');
            }
            else {
                delete httpAPI.defaults.headers.common['API_KEY'];
                delete httpAPI.defaults.headers.common['API_USER'];
            }
        }
    },
    actions: {
        async fetchAuthorize({ state, getters, commit, dispatch }, credentials) {
            if (state.authPending)
                return;

            resetStoreState();
            this._vm.$notify.closeToasts();
            commit({ type: 'SET_AUTH_PENDING', pending: true });

            let response = await httpAPI({
                url: '/api/auth',
                method: 'POST',
                data: JSON.stringify({ Username: credentials.login, Password: credentials.password, Language: credentials.locale }),
                headers: { 'Content-Type': 'application/json' }
            });

            commit({ type: 'SET_AUTH_PENDING', pending: false });

            if (response) {
                commit({ type: 'SET_AUTHORIZED', authorized: true });
                commit({ type: 'SET_USER_INFO',  userInfo: response.data.Payload });
                localStorage.setItem("app-uies", Buffer.from(JSON.stringify(response.data.Payload)).toString('base64'));

                if (getters.getUserInfo.NeedChangePassword) {
                    try
                    {
                        let result = await dispatch('global/dialogs/userReplacePassword/open', { showmessage: true, oldpass: credentials.password }, { root: true });

                        if (result)
                            this._vm.$notify.success(result);
                    }
                    catch(ex)
                    {
                        await dispatch('fetchLogout');
                        this._vm.$notify.alert(i18n.t('Процедура_смены_пароля_прервана'));
                        return;
                    }
                }
                
                await router.push({ name: 'Main' });

                if (getters.getUserInfo.CertificateEndDate) {
                    let certEndDate = this._vm.$moment(response.data.Payload.CertificateEndDate);
                    let certDaysRemain = certEndDate.diff(this._vm.$moment(), 'days');
                    
                    if (certDaysRemain > 0 && certDaysRemain <= 31)
                        await dispatch('dialogs/certificateExpires/open', { certEndDate: certEndDate.format('DD.MM.YYYY'), certDaysRemain }, { root: true });
                }
            }
        },
        async fetchAuthorizeBySign({ state, getters, commit, dispatch }, credentials) {
            if (state.authPending)
                return;

            resetStoreState();
            this._vm.$notify.closeToasts();
            commit({ type: 'SET_AUTH_PENDING', pending: true });

            let response = await httpAPI({
                url: '/api/auth/signature',
                method: 'POST',
                data: JSON.stringify({ Signature: credentials.sign, SignatureType: credentials.signType, AuthSalt: credentials.salt, Language: credentials.locale }),
                headers: { 'Content-Type': 'application/json' }
            });

            commit({ type: 'SET_AUTH_PENDING', pending: false });

            if (response) {
                commit({ type: 'SET_AUTHORIZED', authorized: true });
                commit({ type: 'SET_USER_INFO',  userInfo: response.data.Payload });
                localStorage.setItem("app-uies", Buffer.from(JSON.stringify(response.data.Payload)).toString('base64'));

                if (getters.getUserInfo.NeedChangePassword) {
                    try
                    {
                        let result = await dispatch('global/dialogs/userReplacePassword/open', { showmessage: true, oldpass: '' }, { root: true });

                        if (result)
                            this._vm.$notify.success(result);
                    }
                    catch(ex)
                    {
                        await dispatch('fetchLogout');
                        this._vm.$notify.alert(i18n.t('Процедура_смены_пароля_прервана'));
                        return;   
                    }
                }
                
                await router.push({ name: 'Main' });

                if (getters.getUserInfo.CertificateEndDate) {
                    let certEndDate = this._vm.$moment(response.data.Payload.CertificateEndDate);
                    let certDaysRemain = certEndDate.diff(this._vm.$moment(), 'days');
                    
                    if (certDaysRemain > 0 && certDaysRemain <= 31)
                        await dispatch('dialogs/certificateExpires/open', { certEndDate: certEndDate.format('DD.MM.YYYY'), certDaysRemain }, { root: true });
                }
            }
        },
        async restoreAuthorize({ commit }, userInfo) {
            commit({ type: 'SET_AUTHORIZED', authorized: true });
            commit({ type: 'SET_USER_INFO',  userInfo });
            localStorage.setItem("app-uies", Buffer.from(JSON.stringify(userInfo)).toString('base64'));
        },
        async fetchLogout({ state, commit, dispatch }) {
            if (state.authPending)
                return;

            this._vm.$notify.closeToasts();
            dispatch('setOverlayVisible', { visible: true, text: `Выход из системы...` }, { root: true });
            commit({ type: 'SET_LOGOUT_PENDING', pending: true });

            let response = await httpAPI({
                url: 'api/auth/logout',
                method: 'GET'
            });

            commit({ type: 'SET_LOGOUT_PENDING', pending: false });
            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                commit({ type: 'SET_AUTHORIZED', authorized: false });

                if (router.currentRoute.name !== 'Login')
                    await router.replace({ name: 'Login' });

                commit({ type: 'SET_USER_INFO', userInfo: null });
                localStorage.removeItem("app-uies");
            }
            else {
                await dispatch('fetchEmergencyLogout');
            }
        },
        async fetchEmergencyLogout({ commit }) {
            commit({ type: 'SET_AUTHORIZED', authorized: false });

            if (router.currentRoute.name !== 'Login')
                await router.replace({ name: 'Login' });

            commit({ type: 'SET_USER_INFO', userInfo: null });
            localStorage.removeItem("app-uies");
        }
    },
    getters: {
        isAuthorized: (s) => s.authorized,
        isPendingData: (s) => s.authPending,
        getUserInfo: (s) => s.userInfo,
        getUserPermissions: (s) => s.userInfo?.Permissions ?? [],
        isCommonServiceEnabled: (s) => s.userInfo?.Permissions?.includes('CommonDocumentService') ?? false,
        getUserEnterpriseId: (s) => s.userInfo?.enterpriseId ?? null,
        getUserPositionFlag: (s) => s.userInfo?.position ?? 100,
        getUserConfiguration: (s) => s.userInfo?.Configuration ?? -1
    },
};

export default auth;
