import returnreason from "./store/returnreason";
import sendreportonexecution from "./store/sendreportonexecution";

const dialogs = {
    namespaced: true,
    modules: {
        returnreason,
        sendreportonexecution
    }
}

export default dialogs;