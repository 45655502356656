import _ from 'lodash';
import registered from './registered';
import unregistered from './unregistered';

export default {
    namespaced: true,
    state: () => ({
        collection: null,
        statuses: [
            {
                collection: "IncomingRegistered",
                value: "All"
            },
            {
                collection: "IncomingUnregistered",
                value: "-10"
            },
        ],
        searches: [
            { collection: "IncomingRegistered", value: null },
            { collection: "IncomingUnregistered", value: null }
        ],
        filter: null,
        filterData: {
            Filter: {
                Title: "Категория",
                Items: [
                    {
                        Name: "Незарегистрированные",
                        Value: "IncomingUnregistered",
                        Count: -1,
                        HasItems: true,
                        Requires: [ "ChancelleryIncomingDocumentsList" ],
                        Items: {
                            Title: "Статус",
                            Items: [
                                {
                                    Name: "На_регистрации",
                                    Value: "-10",
                                    Count: -1,
                                },
                                {
                                    Name: "Без_вложений",
                                    Value: "-400",
                                    Count: -1,
                                },
                                {
                                    Name: "Отклоненные",
                                    Value: "3",
                                    Count: -1,
                                },
                                {
                                    Name: "Удаленные",
                                    Value: "-100",
                                    Count: -1,
                                }
                            ]
                        }
                    },
                    {
                        Name: "Зарегистрированные",
                        Value: "IncomingRegistered",
                        Count: -1,
                        HasItems: true,
                        Requires: [ "DocumentsCorrespondence" ],
                        Items: {
                            Title: "Контроль",
                            Items: [
                                {
                                    Name: "Любой_контроль",
                                    Value: "All",
                                    Count: -1,
                                },
                                {
                                    Name: "Неотписанные",
                                    Value: "NotUnsubscribed",
                                    Count: -1,
                                },
                                {
                                    Name: "Без_контроля",
                                    Value: "NoControl",
                                    Count: -1,
                                },
                                {
                                    Name: "На_контроле",
                                    Value: "OnControl",
                                    Count: -1,
                                },
                                {
                                    Name: "Со_сроком_исполнения_сегодня",
                                    Value: "Today",
                                    Count: -1,
                                },
                                {
                                    Name: "Просрочено",
                                    Value: "Expired",
                                    Count: -1,
                                },
                                {
                                    Name: "Снятые_с_контроля",
                                    Value: "TakeOffControl",
                                    Count: -1,
                                }
                            ]
                        }
                    }
                ]
            }
        },
        extendedFilters: [
            {
                collection: "IncomingRegistered",
                value:
                {
                    EFTheme: {
                        label: "Краткое_содержание",
                        value: null
                    },
                    RegNumber: {
                        label: "Рег._номер",
                        value: null
                    },
                    OutNumber: {
                        label: "Исходящий_номер",
                        value: null
                    },
                    HigherNumber: {
                        label: "Номер_вышестоящего_корреспондента",
                        value: null
                    },
                    Correspondent: {
                        label: "Корреспондент",
                        value: []
                    },
                    ExecuterName: {
                        label: "Исполнитель",
                        value: null
                    },
                    JuridicalDocType: {
                        label: "Тип_документа",
                        value: null
                    },
                    SignerName: {
                        label: "Подписал",
                        value: null
                    },
                    RegDateFrom: {
                        label: "Дата_регистрации_c",
                        value: null
                    },
                    RegDateTo: {
                        label: "Дата_регистрации_по",
                        value: null
                    },
                    Recipients: {
                        label: "Получатель",
                        value: []
                    },
                    OutDateFrom: {
                        label: "Дата_исходящего_c",
                        value: null
                    },
                    OutDateTo: {
                        label: "Дата_исходящего_по",
                        value: null
                    },
                    Language: {
                        label: "Язык_документа",
                        value: null
                    },
                    Executers: {
                        label: "Исполнитель",
                        value: []
                    },
                    ResolutionAuthor: {
                        label: "Автор_резолюции",
                        value: []
                    },
                    HasAnswer: {
                        label: "Дан_ответ",
                        value: null
                    },
                    ExecuteStatus: {
                        label: "Статус_исполнения",
                        value: []
                    },
                    IsDuplicate: {
                        label: "Дубликат",
                        value: null
                    },
                    IsIncomingAnswer: {
                        label: "Входящий_ответ",
                        value: null
                    },
                    Counter: {
                        label: "Журнал_регистрации",
                        value: null
                    },
                    NomenclatureId: {
                        label: "Номенклатура",
                        value: []
                    }
                }
            },
            {
                collection: "IncomingUnregistered",
                value: 
                {
                    Correspondent:
                    {
                        label: "Корреспондент",
                        value: []
                    },
                    ExecuterName:
                    {
                        label: "Исполнитель",
                        value: null
                    },
                    OutNumber:
                    {
                        label: "Исходящий_номер",
                        value: null
                    },
                    OutDateFrom: 
                    {
                        label: "Дата_исходящего_c",
                        value: null
                    },
                    OutDateTo: 
                    {
                        label: "Дата_исходящего_по",
                        value: null
                    },
                    HigherNumber:
                    {
                        label: "Входящий_номер_вышестоящего",
                        value: null
                    },
                    RecomDurationFrom:
                    {
                        label: "Рекомендуемый_срок_с",
                        value: null
                    },
                    RecomDurationTo:
                    {
                        label: "Рекомендуемый_срок_по",
                        value: null
                    },
                    Languages:
                    {
                        label: "Язык_документа",
                        value: []
                    },
                    CarrierType:
                    {
                        label: "Тип_носителя",
                        value: []
                    },
                }
            }
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        },
        PUSH_EXTENDED_FILTER (state, payload) {
            let exists = state.extendedFilters.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.extendedFilters.push(payload);
            }
        },
        SET_FILTER(state, payload) {
            state.filter = payload;
        }
    },
    actions: {
        prepareDefaults({ state, commit, rootGetters }) {
            let permissions = rootGetters['global/auth/getUserInfo']?.Permissions ?? [];
            let items = state.filterData.Filter.Items.filter(i => _.difference(i.Requires, permissions).length === 0);

            commit('SET_FILTER', { Filter: { Title: state.filterData.Filter.Title, Items: items } });

            if (!state.collection)
                commit('SET_COLLECTION', items?.[0]?.Value ?? 'unknown');
        },
        async updateFilterCounters({ commit, getters }, counters) {
            let currentFilter = getters.getFilter;

            currentFilter.Filter.Items.forEach((item, index) => {
                item.Count = counters?.[0][index] ?? -1;
                
                if (item.HasItems && item.Value == getters.getCollection) {
                    item.Items.Items.forEach((nestedItem, nestedIndex) => {
                        nestedItem.Count = counters?.[1][nestedIndex] ?? -1;
                    });
                }
            });

            commit('SET_FILTER', currentFilter);
        }
    },
    getters: {
        getCollection(state) {
            return state.collection;
        },
        getStatus(state) {
            return state.statuses.find(x => x.collection === state.collection).value;
        },
        getSearch(state) {
            return state.searches.find(x => x.collection === state.collection).value;
        },
        getFilter(state) {
            return state.filter;            
        },
        getExtendedFilter(state) {
            return state.extendedFilters.find(x => x.collection === state.collection)?.value ?? {};
        }
    },
    modules: {
        registered,
        unregistered
    }
}