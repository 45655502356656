import Vue from 'vue'
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import moment from 'moment';
import _ from "lodash";
import ifvisible from "ifvisible.js";
import AsyncComputed from 'vue-async-computed';

import { EventBus } from './event-bus';
import system from './services/system';
import notifier from './plugins/notifier';
import helpers from './plugins/helper-functions';
import ComponentMapper from './plugins/component-mapper';
import PermissionsHelper from './plugins/permissions-helper';

document.title = process.env.VUE_APP_TITLE || 'СЭД «ИНТЕГРО»';

Vue.prototype.$moment = moment;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$_ = _;
Vue.prototype.$ifvisible = ifvisible;
Vue.prototype.$system = system;
/*число видимых кнопок в пагинации на списках разделов*/
Vue.prototype.$listPaginationTotal = 10;
Vue.config.productionTip = false;

Vue.prototype.$refLocale = (refItem, i18nlocale) => {
    let uniLocale;

    switch(i18nlocale)
    {
        case 'ru':
            uniLocale = 'ru-RU';
            break;

        default:
            uniLocale = 'kk-KZ';
            break;
    }
    
    if (Object.prototype.hasOwnProperty.call(refItem, 'UniValue') && refItem.UniValue) {
        return refItem.UniValue[uniLocale] // если в объекте UniValue есть свойство с именем uniLocale и оно не falsy (undefined, null, empty, e.t.c)
            ? refItem.UniValue[uniLocale] // возвращаем его
            : refItem.UniValue['ru-RU'] // в противном случае проверяем свойство ru-RU и если оно не falsy (undefined, null, empty, e.t.c)
                ? refItem.UniValue['ru-RU'] // возвращаем его
                : refItem.Value; // в противном случае возвращаем свойство Value
    }

    return refItem.Value;
};

Vue.use(AsyncComputed, {
    errorHandler (stack) {
        console.log('AsyncComputed error!')
        console.log('---')
        console.log(stack)
    }
});
Vue.use(PermissionsHelper, store);

Vue.component(`v-doc-type-chip`, () => import('@/components/documents/chips/v-doc-type-chip.vue'));
Vue.component(`v-doc-chip`, () => import('@/components/documents/chips/v-doc-chip.vue'));
Vue.component(`history-tab`, () => import('@/components/documents/tabs/history.vue'));
Vue.component(`linkeddocs-tab`, () => import('@/components/documents/tabs/linkeddocs.vue'));
Vue.component(`signs-tab`, () => import('@/components/documents/tabs/signs.vue'));
Vue.component(`notifications-tab`, () => import('@/components/documents/tabs/notifications.vue'));
Vue.component(`adjustments-tab`, () => import('@/components/documents/tabs/adjustments.vue'));
Vue.component(`euol-versions-tab`, () => import('@/components/documents/tabs/euolversions.vue'));
Vue.component(`v-document-link`, () => import('@/components/documents/DocumentLink.vue'));

Vue.component(`v-card-panel`, () => import('@/components/global/card/v-card-panel.vue'));
Vue.component(`v-field`, () => import('@/components/global/card/v-field.vue'));
Vue.component(`v-field-edit-bool`, () => import('@/components/global/card/v-field-edit-bool.vue'));
Vue.component(`v-field-edit-text`, () => import('@/components/global/card/v-field-edit-text.vue'));
Vue.component(`v-field-edit-date`, () => import('@/components/global/card/v-field-edit-date.vue'));
Vue.component(`v-field-edit-number`, () => import('@/components/global/card/v-field-edit-number.vue'));
Vue.component(`v-field-edit-select`, () => import('@/components/global/card/v-field-edit-select.vue'));
Vue.component(`v-label`, () => import('@/components/global/card/v-label.vue'));
Vue.component(`v-attachment-item`, () => import('@/components/global/card/v-attachment-item.vue'));

Vue.component(`v-execution-tree`, () => import('@/components/global/card/v-execution-tree.vue'));
Vue.component(`v-select-members-chip`, () => import('@/components/global/chips/SelectMembersChip.vue'));
Vue.component(`v-contact-item`, () => import('@/components/global/chips/v-contact-item.vue'));
Vue.component(`v-file-item`, () => import('@/components/global/chips/v-file-item.vue'));
Vue.component(`v-workplace-chip`, () => import('@/components/global/chips/v-workplace-chip.vue'));
Vue.component(`v-employee-chip`, () => import('@/components/global/chips/v-employee-chip.vue'));
Vue.component(`v-enterprise-chip`, () => import('@/components/global/chips/v-enterprise-chip.vue'));
Vue.component(`v-common-chip`, () => import('@/components/global/chips/CommonDocumentChip.vue'));

Vue.component(`attachments-tab`, () => import('@/components/global/tabs/attachments.vue'));
Vue.component(`resolutions-tab`, () => import('@/components/global/tabs/resolutions.vue'));
Vue.component(`comments-tab`, () => import('@/components/global/tabs/comments-tab.vue'));
Vue.component(`actitems-tab`, () => import('@/components/global/tabs/actitems.vue'));

Vue.component(`report-interaction-tab`, () => import('@/components/reportsgeneration/tabs/report-interaction-tab.vue'));

Vue.component(`v-doc-version-chip`, () => import('@/components/documents/DocVersionChip.vue'));


function init() {
    return new Promise(resolve => {
        if (localStorage.getItem("app-uies"))
            store.dispatch('global/auth/restoreAuthorize', JSON.parse(Buffer.from(localStorage.getItem("app-uies"), 'base64').toString()) , { root: true });
        
        resolve();
    });
} 

init().then(() => {
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App),
        created() {
            //региструем плагин и передаем в него хранилище нашего инстанса Vue
            Vue.use(notifier, i18n);
            Vue.use(helpers);
            Vue.use(ComponentMapper);
        }
    }).$mount('#app') 
});