import _ from 'lodash';
import onregistration from './onregistration';
import unsended from './unsended';
import sended from './sended';
import rejected from './rejected';


const unregisteredFilter =
{
    Recipients:
    {
        label: "Получатель",
        value: []
    },
    DocumentAuthor:
    {
        label: "Автор",
        value: []
    },                   
    Languages:
    {
        label: "Язык_документа",
        value: []
    },
    CarrierType:
    {
        label: "Тип_носителя",
        value: []
    },
};
const registeredFilter = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Исходящий_номер",
        value: null
    },
    RegDateFrom: {
        label: "Дата_исходящего_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_исходящего_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
    OutgoingExecuters: {
        label: "Исполнитель",
        value: []
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    SignerEmployeeIds: {
        label: "Подписал",
        value: []
    },
    DocumentAuthor: {
        label: "Кем_создано",
        value: []
    }
};

export default {
    namespaced: true,
    state: () => ({
        collection : null,
        statuses: [
            {
                collection: "OutgoingsOnRegistration",
                value: "Any"
            },
            {
                collection: "OutgoingsUnsended",
                value: "AllOutgoings"
            },
            {
                collection: "OutgoingsSended",
                value: "AllOutgoings"
            },
            {
                collection: "OutgoingsRejected",
                value: "Any"
            },
        ],
        searches: [
            { collection: "OutgoingsOnRegistration", value: null },
            { collection: "OutgoingsUnsended", value: null },
            { collection: "OutgoingsSended", value: null },
            { collection: "OutgoingsRejected", value: null },
        ],
        filter: null,
        filterData: {
            Filter: {
                Title: "Категория",
                Items: [
                    {
                        Name: "На_регистрации",
                        Value: "OutgoingsOnRegistration",
                        Count: -1,
                        Requires: [ "ChancelleryOutcomingDocumentsList" ]
                    },
                    {
                        Name: "Неотправленные",
                        Value: "OutgoingsUnsended",
                        Count: -1,
                        HasItems: true,
                        Requires: [ "DocumentsCorrespondence" ],
                        Items: {
                            Title: "Тип",
                            Items: [
                                {
                                    Name: "Любой_тип",
                                    Value: "AllOutgoings",
                                    Count: -1,
                                },
                                {
                                    Name: "Инициативный",
                                    Value: "Initiative",
                                    Count: -1,
                                },
                                {
                                    Name: "Ответ",
                                    Value: "Answer",
                                    Count: -1,
                                }
                            ]
                        }
                    },
                    {
                        Name: "Отправленные",
                        Value: "OutgoingsSended",
                        Count: -1,
                        HasItems: true,
                        Requires: [ "DocumentsCorrespondence" ],
                        Items: {
                            Title: "Тип",
                            Items: [
                                {
                                    Name: "Любой_тип",
                                    Value: "AllOutgoings",
                                    Count: -1,
                                },
                                {
                                    Name: "Инициативный",
                                    Value: "Initiative",
                                    Count: -1,
                                },
                                {
                                    Name: "Ответ",
                                    Value: "Answer",
                                    Count: -1,
                                }
                            ]
                        }
                    },
                    {
                        Name: "Отклоненные",
                        Value: "OutgoingsRejected",
                        Count: -1,
                        Requires: [ "ChancelleryOutcomingDocumentsList" ]
                    },
                ]
            }
        },
        extendedFilters: [
            {
                collection: "OutgoingsOnRegistration",
                value: unregisteredFilter
            },
            {
                collection: "OutgoingsUnsended",
                value: registeredFilter
            },
            {
                collection: "OutgoingsSended",
                value: registeredFilter
            },
            {
                collection: "OutgoingsRejected",
                value: unregisteredFilter
            },
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        },
        PUSH_EXTENDED_FILTER (state, payload) {
            let exists = state.extendedFilters.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.extendedFilters.push(payload);
            }
        },
        SET_FILTER(state, payload) {
            state.filter = payload;
        }
    },
    actions: {
        prepareDefaults({ state, commit, rootGetters }) {
            let permissions = rootGetters['global/auth/getUserInfo']?.Permissions ?? [];
            let items = state.filterData.Filter.Items.filter(i => _.difference(i.Requires, permissions).length === 0);

            commit('SET_FILTER', { Filter: { Title: state.filterData.Filter.Title, Items: items } });

            if (!state.collection)
                commit('SET_COLLECTION', items?.[0]?.Value ?? 'unknown');
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.statuses.find(x => x.collection === state.collection).value;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection).value;
        },
        getFilter(state) {
            return state.filter;            
        },
        getExtendedFilter (state) {
            return state.extendedFilters.find(x => x.collection === state.collection)?.value ?? {};
        }
    },
    modules: {
        onregistration,
        unsended,
        sended,
        rejected
    }
}