export default {
    namespaced: true,
    state: () => ({
        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "begindate" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
    }),
    mutations: {
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
    },
    getters: {
        getTableOptions (state) {
            return state.tableOptions;
        },
    }
}