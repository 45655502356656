import list from './lists/event-list';
import calendar from './lists/event-calendar';
import addMeetingQuestion from "./dialogs/add-meeting-question";
import addProtocolLink from "./dialogs/add-protocol-link";

export default {
    namespaced: true,
    state: () => ({
        avaibleStatuses:{
            event_performed: {
                text: "Проводится",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            event_completed: {
                text: "Завершено",
                style: {
                    'background-color': "#43B581"
                }
            },
            event_planned: {
                text: "Запланировано",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            event_canceled: {
                text: "Отменено",
                style: {
                    'background-color': "#9e9e9e"
                }
            },
        }
    }),
    mutations: {
    },
    getters: {
        GetAvaibleStatuses: (s) => s.avaibleStatuses,
    },
    modules: {
        list,
        calendar,
        addMeetingQuestion,
        addProtocolLink
    }
}