import adjusted from './adjusted';
import executed from './executed';
import inspected from './inspected';
import signed from './signed';

export default {
    namespaced: true,
    state: () => ({
        collection : "Documents.My.Inspected",
        status: null,
        searches: []
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        SET_STATUS (state, payload) {
            state.status = payload;
        },
        PUSH_SEARCH (state, payload) {
            let searchExists = state.searches.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.status;
        },
        getSearches (state) {
            return state.searches;
        }
    },
    modules: {
        adjusted,
        executed,
        inspected,
        signed
    }
}