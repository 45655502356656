const setDocumentControl = {
    namespaced: true,
    state: {
        visible: false,

        from: null,
        fromSet: false,
        text: '',
        controlDate: null,
        controlDateRange: null,

        resolve: null,
        reject: null,

        executers: []
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_FROM(state, payload) {
            state.from = payload;
        },
        SET_FROMSET(state, payload) {
            state.fromSet = payload;
        },
        SET_TEXT(state, payload) {
            state.text = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_EXECUTERS(state, payload) {
            state.executers = payload;
        },
        SET_CONTROLDATE(state, payload) {
            state.controlDate = payload ? this._vm.$moment(payload) : null;
        },
        SET_CONTROLDATERANGE(state, payload){
            state.controlDateRange = payload;
        }
    },
    actions: {
        async open({ dispatch, commit }, { controlDate, from }) {
            if (from){
                commit('SET_FROM', from);
                commit('SET_FROMSET', true);
            }
            else {
                commit('SET_FROM', null);
                commit('SET_FROMSET', false);
            }
            commit('SET_TEXT', '');
            commit('SET_CONTROLDATE', controlDate);
            let Max = '';
            let Min = '';
            let max = '';
            if (controlDate)
                max = this._vm.$moment(controlDate).startOf('day');
            let min = this._vm.$moment(await dispatch('documents/references/getTimestamp', null, { root: true })).startOf('day');
            if (max && max.isValid()) {
                Max = max.format('YYYY-MM-DD');

                if (min && min.isValid() && min.isBefore(max))                           
                    Min = min.format('YYYY-MM-DD'); 
                else
                    Min = Max;      
            }
            else if (min && min.isValid()) {
                Min = min.format('YYYY-MM-DD');
            }
            commit('SET_CONTROLDATERANGE', { Max, Min });
            commit('SET_EXECUTERS', []);
            commit('SET_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ from: state.from, text: state.text, executers: state.executers, controldate: (state.controlDate? state.controlDate.format('YYYY-MM-DD') : null) });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },

    },
    getters: {
        isVisible: (s) => s.visible,
        isValid: () => true,
        isFromSet: (s) => s.fromSet,
        getFrom: (s) => s.from,
        getText: (s) => s.text,
        getExecuters: (s) => s.executers,
        getControlDate: (s) => s.controlDate ? s.controlDate.format('YYYY-MM-DD') : '',
        getControlDateRange: (s) => s.controlDateRange,
    }
}

export default setDocumentControl;