export default {
    namespaced: true,
    state: () => ({
        search: null,

        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "regdate" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        }
    }),
    mutations: {
        SET_SEARCH (state, payload) {
            state.search = payload;
        },
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        }
    },
    getters: {
        getTableOptions: (s) => s.tableOptions,
        getSearch: (s) => s.search
    }
}