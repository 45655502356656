import projects from "./projects";
import incomings from "./incomings";
import outgoings from "./outgoings";
import inners from "./inners";

import inWork from "./in-work";

const edslight = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        projects,
        incomings,
        outgoings,
        inners,
        inWork
    }
}

export default edslight;