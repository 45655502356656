import allmyreportslist from './store/allmyreportslist';
import mycreatedlist from './store/mycreatedlist';
import myexecutionlist from './store/myexecutionlist';

const lists = {
    namespaced: true,
    modules: {
        allmyreportslist,
        mycreatedlist,
        myexecutionlist
    }
}

export default lists;