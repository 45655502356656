import Axios from 'axios';
//import { httpAPI } from "@/api/httpAPI";
//import Vue from 'vue';

// //private
// // const funNameRegex = /this\.([^(]+)/;
// // const funcStrParams = /\((.*)\)/;
// const funArrayParamsRegex = /[^;()]+(?:\([^()]*(?:\([^()]*\)[^()]*)*\))?/g;


// // function columnSumm(dataSource, sectionKey, name, tdKey) {
// //     return dataSource.sections[sectionKey][name].data.reduce((a, v) => {
// //         if (v.readonly)
// //             return a;

// //         let value = parseInt(v.values[tdKey]);

// //         if (!isNaN(value))                            
// //             a += value;                            

// //         return a;
// //     }, 0);
// // }

// // function getFuncName(value)
// // {
// //     let match = value.match(funNameRegex);
// //     if (Array.isArray(match) && match.length == 2)
// //         return match[1];

// //     return null;
// // }
// // function getFuncStrParams(value)
// // {
// //     let match = value.match(funcStrParams)
// //     if (Array.isArray(match) && match.length == 2)
// //         return match[1];

// //     return null;
// // }

// function getFuncArrayParams(value)
// {
//     let match = value.match(funArrayParamsRegex)
//     if(Array.isArray(match))
//         return match;

//     return null;
// }

function fillDataCols(data, dataCols)
{
    for (const element of data)
    {
        if(element.export)
            dataCols.push(element);

        if(Array.isArray(element.items))
        {                    
            if (element.items.length == 0)
                dataCols.push(element);
            else
            fillDataCols(element.items, dataCols);
        }
    }
}

function getReadonlyValue(value, index)
{
    if (typeof value == 'boolean')
        return value;
    else if (Array.isArray(value) && value.length > 0 && value.length - 1 >= index)
        return typeof value[index] == 'boolean' ? value[index] : true;
    else true;
}

const reportinteraction = {
    namespaced: true,   
    state: {
        isActive: false,
        isPending: null,
        cancellationTokenSorce: null,
        dataSource: null,
        updateKey: 0
    },    
    mutations: {
        SET_IS_ACTIVE(state, payload) {
            state.isActive = payload;
        },
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_CANCELLATION_TOKEN_SOURCE(state, payload) {
            state.cancellationTokenSorce = payload;
        },
        SET_DATASOURCE(state, payload) {
            state.dataSource = payload;
        },        
        UPDATE_DATASOURCE(state, payload) {
            this._vm.$_.set(state.dataSource, payload.property, payload.value)
        },
        SET_UPDATEKEY(state, payload){
            state.updateKey = payload;
        }
    },
    actions: {  
        setDataSource({commit}, dataSource) {
            commit({ type: 'SET_DATASOURCE', dataSource });
        },
        /**
         * Загруза данных для генерирования формы (вкладки?) отчета
         * @param {*} id - идентификатор запроса на формирование, чтобы загрузить данные с сервера
         * @param {*} description - объект, который содержит поля rawScheme (строка-схема отчета) и rawData (строка-данные для отчета)
         */
        // eslint-disable-next-line no-unused-vars
        async loadDataSource({ state, commit}, { id, description }) {
            commit('SET_IS_PENDING', true);

            if (state.cancellationTokenSorce)
                state.cancellationTokenSorce.cancel('New request started');

            commit('SET_CANCELLATION_TOKEN_SOURCE', Axios.CancelToken.source());
            
            let dataSource = null;
            let data = null;

            if (description) {
                dataSource = description.rawScheme ? JSON.parse(description.rawScheme) : null;
                data = description.rawData ? JSON.parse(description.rawData) : null;
            }
            /* else {
                //load some data here
            } */

            //eslint-disable-next-line no-unused-vars
            for(const [sectionIdx, section] of dataSource.sections.entries())
                for (let key of Object.keys(section))
                {
                    let item = section[key];
                    if (item.type && item.type != 'label') { //fields
                        item.value = data && Object.prototype.hasOwnProperty.call(data, key) ? data[key] : item.default;
                    }
                    else if (item.data && item.header) { //tables

                        let dataCols = [];
                        
                        fillDataCols(item.header, dataCols);
  
                        //eslint-disable-next-line no-unused-vars
                        item.data.forEach((d, di) => {
                            
                            if (data && Object.prototype.hasOwnProperty.call(data, key)) {
                                // eslint-disable-next-line no-unused-vars
                                d.values.forEach((v, vi) => {
                                    if (!getReadonlyValue(d.readonly, vi) && dataCols[vi].type && (typeof v === "string" ? !v.startsWith("this.") : true))
                                        d.values[vi] = data[key][di][vi];       
                                });
                            }                            
                        });
                    }
                }

            commit('SET_IS_PENDING', false);

            if (dataSource)
                commit('SET_DATASOURCE', dataSource);
            else
                commit('SET_DATASOURCE', []);
        }, 
        setIsActive({commit}, isActive) {
            commit('SET_IS_ACTIVE', isActive);
        },
        updateDataSource({ commit }, data) {
            commit('UPDATE_DATASOURCE', data);
        },
        resetUpdateKey({commit}){
            commit('SET_UPDATEKEY', this._vm.$moment.utc().valueOf());
        },
        fetchCurrentFillData({ state }) {
            if (!state.dataSource)
                return null;

            let result = {};

            // eslint-disable-next-line no-unused-vars
            state.dataSource.sections.forEach((s, si) => {
                    Object.entries(s)
                    // eslint-disable-next-line no-unused-vars
                    .filter(([key, item]) => {                        
                        return key != 'title' && key != 'description';
                    })
                    // eslint-disable-next-line no-unused-vars
                    .forEach(([key, item]) => {
                        if (Object.prototype.hasOwnProperty.call(item, 'value')) {
                            if (item.visible)
                                result[key] = item.value;
                        }
                        else if (Object.prototype.hasOwnProperty.call(item, 'data') && Object.prototype.hasOwnProperty.call(item, 'header'))
                        {
                            let dataCols = [];
                        
                            fillDataCols(item.header, dataCols);

                            let data = item.data.reduce((a, r) => {
                                let dataRow = r.values.reduce((ra, rd, ri) => {
                                    
                                    if (dataCols[ri].type && !getReadonlyValue(r.readonly, ri))
                                        ra.push(rd);
                                    else
                                        ra.push(null);
            
                                    return ra;
                                }, []);
            
                                if (dataRow.length > 0)
                                    a.push(dataRow);
            
                                return a;
            
                            }, []);

                            result[key] = data;
                        }
                    });
            });

            return result;
        },        
    },
    getters: {
        isActive: s => s.isActive,
        isPending: s => s.isPendig,
        getDataSource: s => s.dataSource,
        getUpdateKey: s => s.updateKey  
    },
};

export default reportinteraction;
