import { httpAPI } from "@/api/httpAPI";

const executerAnswerView = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        resolve: null,
        reject: null,

        executionObject: null,
        common: false
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_EXECUTION_OBJECT(state, payload) {
            state.executionObject = payload
        },
        SET_COMMON(state, payload) {
            state.common = payload;
        },
    },
    actions: {
        async open({ commit }, content) {
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);            

            let response = await httpAPI({
                url: 'api/actions/executerexecution',
                method: 'POST',
                data: { Content: JSON.stringify({ resolutionId: content.resolutionId, executionId: content.executionId }) },
                headers: { 'Content-Type': 'application/json', 'isCommon': content.common }
            });

            commit('SET_COMMON', content.common);
            commit('SET_EXECUTION_OBJECT', response?.data.Payload);
            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async apply({ commit, state }, resolutionId) {
            commit('SET_VISIBLE', false);
            state.resolve({
                action: 'ExecuterSetExecuted',
                param: {
                    resolutionId,
                    taskId: state.executionObject.Data.Object.Executer.ResolutionTaskID,
                    executerId: state.executionObject.Data.Object.Executer.id
                }
            });
        },
        async rework({ commit, state }, resolutionId, byAuthor) {
            commit('SET_VISIBLE', false);
            state.resolve({
                action: 'ExecuterReturnToRework',
                param: {
                    resolutionId,
                    taskId: state.executionObject.Data.Object.Executer.ResolutionTaskID,
                    executerId: state.executionObject.Data.Object.Executer.id,
                    byAuthor
                }
            });
        },
        async cancelExecution({ commit, state, rootGetters }, resolutionId) {
            commit('SET_VISIBLE', false);
            state.resolve({
                action: 'CancelExecution',
                param: {
                    updateSource : !rootGetters['global/actionsource/isDataSourceDocument'],
                    actionData: {
                        id: resolutionId,
                        taskId: state.executionObject.Data.Object.Executer.ResolutionTaskID,
                        executerId: state.executionObject.Data.Object.Executer.id
                    }
                }
            });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getExecutionObject: (s) => s.executionObject,
        isCommon: (s) => s.common,
    }
}

export default executerAnswerView;