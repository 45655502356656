import lists from './lists'

const chancellery = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        lists
    }
}

export default chancellery;