export default {
    namespaced: true,
    state: () => ({
        status: "-10",
        search: null,

        tableOptions:
        {
            page: 1,
            itemsPerPage: 10,
            sortBy : [ "createdate" ],
            sortDesc : [ true ],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: false 
        },

        extendedFilter: {
            Correspondent:
            {
                label: "Корреспондент",
                value: []
            },
            ExecuterName:
            {
                label: "Исполнитель",
                value: null
            },
            OutNumber:
            {
                label: "Исходящий_номер",
                value: null
            },
            OutDateFrom: 
            {
                label: "Дата_исходящего_c",
                value: null
            },
            OutDateTo: 
            {
                label: "Дата_исходящего_по",
                value: null
            },
            HigherNumber:
            {
                label: "Входящий_номер_вышестоящего",
                value: null
            },
            RecomDurationFrom:
            {
                label: "Рекомендуемый_срок_с",
                value: null
            },
            RecomDurationTo:
            {
                label: "Рекомендуемый_срок_по",
                value: null
            },
            Languages:
            {
                label: "Язык_документа",
                value: []
            },
            CarrierType:
            {
                label: "Тип_носителя",
                value: []
            }
        }
    }),
    mutations: {
        SET_STATUS (state, payload) {
            state.status = payload;
        },
        SET_SEARCH (state, payload) {
            state.search = payload;
        },
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
        SET_EXTENDED_FILTER (state, payload) {
            state.extendedFilter = payload;
        },
    },
    getters: {
        getTableOptions: (s) => s.tableOptions,
        getStatus: (s) => s.status,
        getSearch: (s) => s.search,
        getExtendedFilter: (s) => s.extendedFilter,
    }
}