const dialogs = {
    namespaced: true,
    state: {

    },
    actions: {

    },
    modules: {
        
    }
}

export default dialogs;