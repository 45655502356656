import auth from "../global/auth";
import signing from "../global/signing";
import dialogs from "../global/dialogs";
import actionsource from "../global/actionsource";
import attachments from "../global/attachments";
import comments from "../global/comments";
import selectMembersChip from "../global/select-members-chip";

const global = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        auth,
        signing,
        dialogs,
        actionsource,
        attachments,
        comments,
        selectMembersChip        
    }
}

export default global;