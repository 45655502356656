export default {
    namespaced: true,
    state: () => ({
        search: '',
        status: 'ListToday',
        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "meeting_date" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
    }),
    mutations: {
        SET_SEARCH (state, payload){
            state.search = payload;
        },
        SET_STATUS (state, payload){
            state.status = payload;
        },
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
    },
    getters: {
        getSearch: (state) => state.search,
        getStatus: (state) => state.status,
        getTableOptions (state) {
            return state.tableOptions;
        },
    }
}