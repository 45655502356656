import documents from './documentsArchive';
import euol from './euolArchive';

const lists = {
    namespaced: true,
    modules: {
        documents,
        euol
    }
}

export default lists;