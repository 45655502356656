import projects from "./projects";
import inWork from "./in-work";
import handled from "./handled";

import incomings from "./lists/incomings";
import outgoings from "./lists/outgoings";
import inners from "./lists/inners";
import dirords from "./lists/dirords";
import incomingActItems from "./lists/incoming-act-items";
import ords from "./lists/ords";
import innerActItems from "./lists/inner-act-items";

const correspondence = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        projects,
        inWork,
        handled,
        incomings,
        outgoings,
        inners,
        dirords,
        incomingActItems,
        ords,
        innerActItems
    }
}

export default correspondence;