import router from '@/router';

const archiveCardsTypes = {
    ArchiveIncomingDocument: "Document",
    ArchiveOutgoingDocument: "Document",
    ArchiveOutgoingDocumentCommon: "Document",
    ArchiveInnerDocument: "Document",
    ArchiveResolution: "Resolution",
    ArchiveActItem: "Resolution",
    ArchiveEuolStatement: "Euol",
    ArchiveEuolAnswer: "Document",
    ArchiveEuolAnswerCommon: "Document",
    ArchiveEuolResolution: "Resolution"
};

const archiveDocumentToolbar = [
    {
        Action: "Print",
        Icon: "print",
        Items: [
        {
            Action: "PrintCard",
            Icon: "print",
            Items: null,
            Text: "Печать_карточки",
            ToolTip: "Печать_карточки_документа",
            Type: "Chancellery|Documents|CitizenStatements.DocumentCard",
            Localize: true
        },
        {
            Action: "PrintHistory",
            Icon: "print",
            Items: null,
            Text: "Печать_истории",
            ToolTip: "Печать_истории_документа",
            Type: "Chancellery|Documents|CitizenStatements.DocumentHistory",
            Localize: true
        }
        ],
        Text: "Печать",
        ToolTip: "Подменю_печати",
        Type: null,
        Localize: true
    }
];
const archiveResolutionToolbar = [
    {
        Action: "PrintFishka",
        Icon: "print",
        Items: null,
        Text: "Печать_фишки",
        ToolTip: "Печать_фишки_поручения",
        Type: "Documents|Orders|CitizenStatements.Fishka",
        Localize: true
    }
];
const archiveEuolStatementToolbar = [
    {
        Action: "PrintCard",
        Icon: "print",
        Items: null,
        Text: "Печать_карточки",
        ToolTip: "Печать_карточки_документа",
        Type: "Chancellery|Documents|CitizenStatements.DocumentCard",
        Localize: true
    }
];

const toolbar = {
    namespaced: true,
    state: {
        menu: [],
        isLock: false,
        lockedActions: []
    },
    mutations: {
        SET_MENU(state, payload) {           
            state.menu = payload.menu;
        },
        SET_ISLOCK(state, payload) {     
            state.isLock = payload.isLock;
        },
        SET_BTNLOCK(state, payload){
            state.lockedActions.push(payload.lockedAction);
        },
        SET_BTNUNLOCK(state, payload){
            let idx = state.lockedActions.indexOf(payload.lockedAction);
            if (idx > -1) {
                state.lockedActions.splice(idx,1);
            }
        },
    },
    actions: {
        setMenu({ commit }, { menu }) {
            commit({ type: 'SET_MENU', menu });
        },
        setToolbarIsLock({ commit }, isLock) {
            commit({ type: 'SET_ISLOCK', isLock });
        },
        setButtonLock({ commit }, lockedAction) {
            commit({ type: 'SET_BTNLOCK', lockedAction });
        },
        setButtonUnLock({ commit }, lockedAction) {
            commit({ type: 'SET_BTNUNLOCK', lockedAction });
        },
    },
    getters: {
        isToolbarLock: (s) => s.isLock,
        lockedActions: (s) => s.lockedActions,        
        getMenu(s, g, rs, rg) {
            let module = rg['getCurrentModule'];
            let result = Array.from(s.menu);

            if (module === "archive") {
                let currentRouteName = router?.currentRoute?.name;
                let cardType = archiveCardsTypes[currentRouteName];

                switch (cardType)
                {
                    case "Document":
                        result = archiveDocumentToolbar;
                        break;

                    case "Resolution":
                        result = archiveResolutionToolbar;
                        break;

                    case "Euol":
                        result = archiveEuolStatementToolbar;
                        break;

                    default: break;
                }
            }

            return result;
        }
    },
};

export default toolbar;