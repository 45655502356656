const KnownStrategy = Object.freeze({
    "HAS_ALL": (rp, ap) => rp.every(r => ap.includes(r)),
    "HAS_ANY": (rp, ap) => rp.some(r => ap.includes(r)),
    "CUSTOM": (rp, ap, o) => {
        if (typeof o == "function")
            return o(rp, ap);

        return null;
    }
});

const ValidatePermission = (requiredPermits, availablePermits, strategy, operation) => {
    if (!KnownStrategy[strategy])
        return false;

    if (!requiredPermits.length)
        return true;

    let validation = KnownStrategy[strategy];

    if (validation == null)
        return false;

    return validation(requiredPermits, availablePermits, operation);
};

export default {
    install (Vue, store) {
        Vue.prototype.$validatePermission = (options = {}) => {
            const defaults = {
                permissions: [],
                strategy: 'HAS_ALL',
                operation: null,
                requiredConfig: []
            };
        
            const settings = Object.assign({}, defaults, options);

            let userPermissions = store?.getters?.['global/auth/getUserPermissions'] ?? [];
            let userConfig = store?.getters?.['global/auth/getUserConfiguration'];
            
            let validationResult = Array.isArray(settings.permissions) && settings.permissions.length > 0
                ? ValidatePermission(settings.permissions, userPermissions, settings.strategy, settings.operation)
                : true;

            let isConfigurationProvided = Array.isArray(settings.requiredConfig) && settings.requiredConfig.length > 0;

            if (isConfigurationProvided)
                return settings.requiredConfig.some(c => c == userConfig) && validationResult;

            return validationResult;
        };
    }
};