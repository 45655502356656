import references from "@/store/saylau/references";
import lists from "@/store/saylau/lists";
import dialogs from "@/store/saylau/dialogs";
import components from "@/store/saylau/components";

const saylau = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        components,
        dialogs,
        lists,
        references        
    }
}

export default saylau;