<template>
    <v-main>
        <div class="d-flex">
            <div class="modul-menu-box">
                <!--Меню модулей-->
                <ModuleMenuPanel />
            </div>
            <div class="main-page-box">
                            
                <div class="header">
                    <div class="header-wrapper">
                        
                        <div 
                            v-if="hasNavigation"
                            class="sandwich" 
                            @click.stop="slideLeftMenuMethod('sandwich')"
                        >
                            <v-btn icon>
                                <v-icon>far fa-bars</v-icon>
                            </v-btn>
                        </div>

                        <div :class="{'logo' : true,
                            'logo-ml' : !hasNavigation }"
                        >
                            <div 
                                class="logo-title" 
                            >
                                <template v-if="moduleLogoName">
                                    <div class="first-part">{{ moduleLogoName && moduleLogoName.lgnamefirst ? moduleLogoName.lgnamefirst : "" }}</div>
                                    <div>{{ moduleLogoName && moduleLogoName.lgnamesecond ? moduleLogoName.lgnamesecond : "" }}</div>
                                </template>
                                <template v-else>
                                    <div class="text-lowercase">{{ this.$route.name }}</div>
                                </template>
                            </div>
                        </div>

                        <div 
                            :class="{'personal' : true, 'ml-auto' : true }"
                        >
                            <v-popover trigger="click">
                                <v-btn
                                    class="p-lang-select-btn"
                                    outlined
                                    color="#6c6c6c"
                                    x-small
                                >{{ locales.find(el => el.id == locale).shortValue }}</v-btn>
                                <template slot="popover">
                                    <ul class="ribbon-button-submenu" >
                                        <li 
                                            v-for="(item, index) in locales"
                                            :key="index"
                                            :class="[locale != item.id ? 'p-lang-fake-icon-padd' : '']"
                                            v-close-popover
                                            @click="onLocaleInput(item)">
                                            <v-icon v-if="locale == item.id" left>fas fa-check</v-icon>
                                            {{ item.value }}   
                                        </li>
                                    </ul>
                                    <span class="ribbon-button-submenu-title">{{ $t('Выбор_языка_интерфейса') }}</span>
                                </template>
                            </v-popover>
                            
                            <div class="user-menu">
                                <div class="user-field">
                                    <div>
                                        <span class="user-name">
                                            <div><span class="uname" v-tooltip.top-center="userInfo.name">{{ userInfo.name }}</span></div>
                                            <div><span class="user-workplace">{{ userInfo.position }}</span></div>
                                        </span>
                                    </div>
                                </div>
                            </div>  
                                
                            <i
                                @click="logout"
                                class="far fa-sign-out p-other-i" 
                                v-tooltip.left-center="$t('Выйти')"
                            ></i>
                                        
                        </div>

                    </div>
                </div>
        
                <router-view ref="routercomponent" />

                <div class="version-info-in-page">
                    {{ $t('Версия') }}: {{ getVersion() }}
                </div>

            </div>
        </div>

        <PersonModal ref="PersonModalRef"/>
        <DocumentVersionsDlg ref="DocumentVersionsDlgRef" />

        <ExecuterAnswerViewDlg v-if="isExecuterAnswerViewVisible" />
        <SelectMembersNewDlg v-if="isSelectMembersNewVisible" />
        <SetDocumentControlDlg v-if="isSetDocumentControlVisible" />
        <AddDocumentLinkDlg v-if="isAddDocumentLinkVisible" />
        <SelectNomenclatureDlg v-if="isSelectNomenclatureVisible" />
        <PDFViewDlg v-if="isPdfViewVisible" />
        <PrintFishkaDlg v-if="isPrintFishkaVisible" />
        <ExecuterAnswerDlg v-if="isExecuterAnswerVisible" />
        <RegisterSectionDlg v-if="isRegisterSectionVisible" />
        <NotifySignErrorConfirmDlg v-if="isNotifySignErrorConfirmVisible" />
        <RegisterDocumentDlg v-if="isRegisterDocumentVisible" />
        <SetAdjustmentDlg v-if="isSetAdjustmentVisible" />
        <SetSignDlg v-if="isSetSignVisible" />
        <CreateNewVersionDlg v-if="isCreateNewVersionVisible" />
        <NextPeriodDlg v-if="isNextPeriodDateDlgVisible" /> 
        <ChangeControlDlg v-if="isChangeControlVisible" />
        <PromptProfileDlg v-if="isPromptProfileVisible" />
        <PromptPasswordDlg v-if="isPromptPasswordVisible" />
        <ProlongateDlg v-if="isProlongateVisible" />
        <RejectDlg v-if="isRejectVisible"/>
        <BeginAdjustmentDlg v-if="isBeginAdjustmentVisible" />
        <AttachDuplicateDlg v-if="isAttachDuplicatesVisible" />

        <!-- Для загрузки файлов -->
        <input
            type="file"
            style="display: none"
            ref="inputFile"
            multiple
            @change="onInputChange"
        />
        <!-- Для загрузки файлов в комментарии -->
        <input
            type="file"
            style="display: none"
            ref="inputFileToComment"
            multiple
            @change="onInputFileToCommentChange"
        />
        
    </v-main>
</template>

<script>
import { httpAPI, errorHandler } from "@/api/httpAPI";
import sys from '@/services/system';
import { mapGetters } from 'vuex';
import i18n from '@/i18n';
import { mapActions } from 'vuex';
import PersonModal from '@/components/global/dialogs/PersonModal';
import ModuleMenuPanel from '@/components/global/ModuleMenuPanel.vue';
import DocumentVersionsDlg from '@/components/global/dialogs/DocumentVersionsDlg';

export default {
    components: {
        ModuleMenuPanel,
        PersonModal,
        DocumentVersionsDlg,
        ExecuterAnswerViewDlg: () => import('@/components/global/dialogs/ExecuterAnswerViewDlg.vue'),
        SelectMembersNewDlg: () => import('@/components/global/dialogs/SelectMembersNewDlg'),
        SetDocumentControlDlg: () => import('@/components/global/dialogs/SetDocumentControlDlg'),
        AddDocumentLinkDlg: () => import('@/components/global/dialogs/AddDocumentLinkDlg'),
        SelectNomenclatureDlg: () => import('@/components/global/dialogs/SelectNomenclatureDlg'),
        PDFViewDlg: () => import('@/components/global/dialogs/PDFViewDlg'),
        PrintFishkaDlg: () => import('@/components/global/dialogs/PrintFishkaDlg'),
        ExecuterAnswerDlg: () => import('@/components/global/dialogs/ExecuterAnswerDlg'),
        RegisterSectionDlg: () => import('@/components/global/dialogs/RegisterSectionDlg'),
        NotifySignErrorConfirmDlg: () => import('@/components/global/dialogs/NotifySignErrorConfirmDlg'),
        RegisterDocumentDlg: () => import('@/components/global/dialogs/RegisterDocumentDlg'),
        SetAdjustmentDlg: () => import('@/components/global/dialogs/SetAdjustmentDlg'),
        SetSignDlg: () => import('@/components/global/dialogs/SetSignDlg'),
        CreateNewVersionDlg: () => import('@/components/global/dialogs/CreateNewVersionDlg'),
        NextPeriodDlg: () => import('@/components/global/dialogs/NextPeriodDateDlg'),
        ChangeControlDlg: () => import('@/components/global/dialogs/ChangeControlDlg'),
        PromptProfileDlg: () => import('@/components/global/dialogs/signing/PromptProfileDlg'),
        PromptPasswordDlg: () => import('@/components/global/dialogs/signing/PromptPasswordDlg'),
        ProlongateDlg: () => import('@/components/global/dialogs/ProlongateDlg'),
        RejectDlg: () => import('@/components/global/dialogs/RejectDlg'),
        BeginAdjustmentDlg: () => import('@/components/global/dialogs/BeginAdjustmentDlg'),
        AttachDuplicateDlg: () => import('@/components/global/dialogs/AttachDuplicateDlg'),
    },
    data: () => ({
        locales: [ 
            { id: 'kk', value: 'Қазақ', shortValue: 'Қаз' },
            { id: 'ru', value: 'Русский', shortValue: 'Рус' }
        ],
        urls: [],
        headerElements: null
    }),
    computed:{
        ...mapGetters(
            {
                drawerLeftMenuMini: 'isDrawerLeftMenuMinimized'
            }),
        ...mapGetters('global/actionsource', { dataSourceType: 'getDataSourceType', dataSourceIdentifier: 'getDataSourceIdentifier', isDocumentCommon: 'isDataSourceCommon' }),
        ...mapGetters('global/attachments', { attachmentsCurrentDocumentId: 'getCurrentDocumentId'}),
        ...mapGetters('global/signing', [ 'isPromptPasswordVisible', 'isPromptProfileVisible' ]),
        ...mapGetters('global/dialogs/executerAnswerView', { isExecuterAnswerViewVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/selectMembersNew', { isSelectMembersNewVisible : 'isVisible' }),
        ...mapGetters('global/dialogs/setDocumentControl', { isSetDocumentControlVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/addDocumentLink', { isAddDocumentLinkVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/selectNomenclature', { isSelectNomenclatureVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/pdfView', { isPdfViewVisible : 'isVisible' }),
        ...mapGetters('global/dialogs/printFishka', { isPrintFishkaVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/executerAnswer', { isExecuterAnswerVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/registerSection', { isRegisterSectionVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/notifySignErrorConfirm', { isNotifySignErrorConfirmVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/registerDocument', { isRegisterDocumentVisible : 'isVisible' }),
        ...mapGetters('global/dialogs/setAdjustment', { isSetAdjustmentVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/setSign', { isSetSignVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/createNewVersion', { isCreateNewVersionVisible : 'isVisible' }),
        ...mapGetters('global/dialogs/nextPeriodDate', { isNextPeriodDateDlgVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/changeControl', { isChangeControlVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/prolongate', { isProlongateVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/reject', { isRejectVisible: 'isVisible' }),
        ...mapGetters('global/dialogs/beginAdjustment', { isBeginAdjustmentVisible : 'isVisible' }),
        ...mapGetters('global/dialogs/attachDuplicates', { isAttachDuplicatesVisible : 'isVisible' }),

        hasNavigation(){
            return this.$route?.meta?.hasModuleNav ?? true;
        },    
        drawerLeftMenuMini: {
            get: function() {
                return this.$store.getters['isDrawerLeftMenuMinimized'];
            },
            set: function(value) {
                this.$store.commit('SET_DRAWER_LEFT_MENU_MINIMIZED', value);
            }
        },
        isC5Configuration() {
            return process.env.VUE_APP_CONFIGURATION == sys.Configurations.C5
        },
        isC4Configuration() {
            return process.env.VUE_APP_CONFIGURATION == sys.Configurations.C4
        },
        locale() {
            return this.$i18n.locale;
        },
        userInfo() {
            let userData = this.$store.getters['global/auth/getUserInfo'];
            let userName = userData.UserName.replace(/ *\([^)]*\) */g, "");
            let userPosition = userData.UserName.match(/\((.*)\)/)?.pop() ?? "";
                /*if (userData.warningMessagePassword)
                    this.$notify.warning(this.$t('До_окончания_срока_действия_пароля_осталось_value_дней.message', {value: userData.passwordDaysLeft }));*/
           
            return { name: userName, position: userPosition };
        },
        ...mapGetters({ systemModules: 'getModules' }),
        moduleLogoName(){
            let result = [];
            result = this.systemModules.filter(m => m.route === this.$route.matched[0].path);
            return result[0];
        }
    },
    methods: {
        ...mapActions('global/attachments', ['setCurrentDocumentId', 'onFilePicked', 'updateDataSource']),
        ...mapActions('global/actionsource', ['loadDataSource']),
        ...mapActions('global/comments', ['onFileCommentPicked']),
        userReplacePasswordWindow() {

        },
        fetchLogout() {
            
        },
        onInputChange(e) {
            this.onFilePicked({ e, id: this.getCurrentDocumentId });
        },
        onInputFileToCommentChange(e) {
            this.onFileCommentPicked({ e, id: this.getCurrentDocumentId })
        },
        getVersion() {
            let isDebug = process?.env?.VUE_APP_DEBUG === "true";
            let version = process?.env?.VUE_APP_VERSION ?? "0.0.0.0.0000"
            version = `${version}/${this.$store.getters['global/auth/getUserConfiguration'] ?? 0}`;
            if (this.isC4Configuration)
                return isDebug ? `${version}/DEBUG` : `${version}/RELEASE`;

            if (this.isC5Configuration)
                return `${version}/${this.userRegion}`;
        },
        async onLocaleInput(locale) {
            await httpAPI({
                url: `/api/actions/changeuserlanguage`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content: locale.id },
                skipErrorHandler: true
            });
            localStorage.setItem('app-locale', locale.id)
            i18n.locale = locale.id;
            this.$vuetify.lang.current = locale.id;
        },
        //открываем/закрываем левое меню по ситуации
        slideLeftMenuMethod(activate) {
            if (window.innerWidth <= 1280) {
                this.drawerLeftMenuMini = true;  
            }
            else {
                if (activate == 'sandwich') {
                    this.drawerLeftMenuMini = !this.drawerLeftMenuMini;
                }
                else {
                    if (activate?.UIEvent == 'undefined')
                        this.drawerLeftMenuMini = false;
                }
            }
        },
        //в левом меню, подсвечиваем категорию, если активен какой либо из ее дочерних подпунктов
        selectGroupByActiveSubitems(){
            //сначала сбросим со всех заголовков групп, класс активности
            let headerElements = this.$el.querySelectorAll('.v-list-group__header');
            headerElements.forEach(element => {
                element.classList.remove('active-group-header');
            });
            //вычисляем по url активный пункт меню и добавляем к его заголовку группы класс активности
            let activeElement = document.querySelector(`a[href*="${this.$route.path}"]`);
            if(activeElement)
                activeElement.closest('.v-list-group')?.getElementsByClassName('v-list-group__header')[0].classList.add('active-group-header');
        },
        scrollPageContentMethod(){
            /*var element = document.querySelector('.content-box');
            element.onscroll = function() {
                const selects = document.querySelectorAll('.v-select--is-menu-active .v-input__slot');
                    selects.forEach(select => {
                    select.click(); // Симулировать клик на элементе, открывающем выпадающий список
                    console.log(selects);
                });

            };*/
        },
        logout() {
            this.$store.dispatch('global/auth/fetchLogout');
        }
    },
    created() {

    },
    mounted() {
        this.$eventBus.$on('open-document', async data => {
            this.loadDataSource({ id: data.id, openType: data.type, navigate: true, common: data.common ?? false });
            
        });

        this.$eventBus.$on('open-linked-document', async data =>
        {
            this.loadDataSource(
            { 
                id: "OpenLinkedPlaceholderId",
                openType: data.type,
                navigate: true,
                common: data.common ?? false,
                params: { ...data, isLinkedDocument: true }
            });
        });
        
        this.$eventBus.$on('on-input-file', async (id) => {            
            let needUpdateSource = this.attachmentsCurrentDocumentId != id;
            this.setCurrentDocumentId(id);

            if (needUpdateSource){
                await this.updateDataSource();
            }
            
            this.$refs.inputFile.click();
        });
        
        this.$eventBus.$on('on-input-file-to-comment', async () => {
            this.$refs.inputFileToComment.click();
        });

        this.$eventBus.$on('show-employee-information', async id => {
            let promise = httpAPI({ url: `/api/references/employee/${id}`, method: 'GET' });
            await this.$refs.PersonModalRef.open(promise);
        });

        this.$eventBus.$on('show-workplace-information', async id =>
        {
            let promise = httpAPI({ url: `/api/references/workplace/${id}`, method: 'GET' });
            await this.$refs.PersonModalRef.open(promise);
        });

        //для выделения группы в меню
        this.$nextTick(this.selectGroupByActiveSubitems());
        this.$router.afterEach(() => {
            this.selectGroupByActiveSubitems();
        });
        //слушаем клик по группе меню
        this.headerElements = this.$el.querySelectorAll('.v-list-group__header');
        this.headerElements.forEach(element => {
            element.addEventListener('click', this.selectGroupByActiveSubitems);
        });
        
        this.$eventBus.$on('on-download-file', async ({ id, name }) => {
            let type = this.dataSourceType === 'Meeting' ? 'meetingattachment' : 'attachment';
            let url = `api/download/${type}?id=${id}&name=${name}`;
            let reportRequst = httpAPI({ url, method: 'GET', responseType: 'blob', skipErrorHandler: true, headers: { 'isCommon': this.isDocumentCommon }, });

            // при успешном выполнении запроса на формирование отчёта выполнить:
            var onResolve = (response) => {
                this.$notify.success(`Загрузка файла ${name} завершена`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.urls.push(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name );
                document.body.appendChild(link);
                link.click();
            };
            // при неудачном выполнении запроса на формирование отчёта выполнить:
            var onReject = (error) => {
                errorHandler(error);
            };
            
            let asyncReportToastMessage = `Загрузка файла ${name}`;
            this.$notify.async(reportRequst, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });
        });

        this.$eventBus.$on('on-get-report', async repData => {
            let reportRequst = httpAPI({ url: repData.url, method: 'GET', responseType: 'blob', skipErrorHandler: true });

            // при успешном выполнении запроса на формирование отчёта выполнить:
            var onResolve = (response) => {
                this.$notify.success(`${this.$t(repData.text)}`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.urls.push(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', repData.type === 'PDF' ? `${this.$t(repData.text)}.pdf` : `${this.$t(repData.text)}.xls` );
                document.body.appendChild(link);
                link.click();
            };
            // при неудачном выполнении запроса на формирование отчёта выполнить:
            var onReject = (error) => {
                errorHandler(error);
            };
            
            let asyncReportToastMessage = `${ this.$t(repData.text) }`;
            this.$notify.async(reportRequst, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });
        });

        this.$eventBus.$on('show-versions-information', async data =>
        {
            try
            {
                if (this.$refs.DocumentVersionsDlgRef) {
                    let { id, common } = await this.$refs.DocumentVersionsDlgRef.open(data.documentId);
                    this.loadDataSource({ id, openType: "Chancellery|Documents.Document", navigate: true, common: common ?? false });
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        });

        //для левого меню
        this.$nextTick(this.slideLeftMenuMethod);
        window?.addEventListener('resize', (event) => {this.slideLeftMenuMethod(event)});
        //для отслеживания события прокрутки мыши на блоке с контентом
        document.querySelector('.content-box')?.addEventListener('wheel', (event) => {this.scrollPageContentMethod(event)});
    },
    beforeDestroy() {
        this.$eventBus.$off('open-document');
        this.$eventBus.$off('open-linked-document');
        this.$eventBus.$off('on-input-file');
        this.$eventBus.$off('on-input-file-to-comment');
        this.$eventBus.$off('show-employee-information');
        this.$eventBus.$off('show-workplace-information');
        this.$eventBus.$off('on-download-file');
        this.$eventBus.$off('on-get-report');
        
        this.urls.forEach(url => URL.revokeObjectURL(url));
        // Убираем обработчики событий перед уничтожением компонента
        window?.removeEventListener('resize', this.slideLeftMenuMethod);
        document.querySelector('.content-box')?.removeEventListener('wheel', this.scrollPageContentMethod);
        this.headerElements.forEach(element => {
            element.removeEventListener('click', this.selectGroupByActiveSubitems);
        });
    }
};
</script>