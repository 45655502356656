import userReplacePassword from "../dialogs/store/user-replace-password";
import executerAnswerView from "../dialogs/store/executer-answer-view";
import importCertificate from "./store/import-certificate";
import selectMembersNew from './store/select-members-new';
import setDocumentControl from "./store/set-document-control";
import addDocumentLink from "./store/add-document-link";
import selectNomenclature from "./store/select-nomenclature";
import pdfView from "./store/pdf-view";
import printFishka from "./store/print-fishka";
import executerAnswer from "./store/executer-answer";
import registerSection from "./store/register-section";
import notifySignErrorConfirm from "./store/notify-sign-error-confirm";
import registerDocument from "./store/register";
import setAdjustment from "./store/set-adjustment";
import setSign from "./store/set-sign";
import createNewVersion from "./store/create-new-version";
import nextPeriodDate from "./store/set_next_period_date";
import changeControl from "./store/change-control";
import prolongate from "./store/prolongate";
import reject from "./store/reject";
import beginAdjustment from "./store/begin-adjustment";
import attachDuplicates from "./store/attach-duplicate";

const dialogs = {
    namespaced: true,
    state: {

    },
    actions: {

    },
    modules: {
        userReplacePassword,
        executerAnswerView,
        importCertificate,
        selectMembersNew,
        setDocumentControl,
        addDocumentLink,
        selectNomenclature,
        pdfView,
        printFishka,
        executerAnswer,
        registerSection,
        notifySignErrorConfirm,
        registerDocument,
        setAdjustment,
        setSign,
        createNewVersion,
        nextPeriodDate,
        changeControl,
        prolongate,
        reject,
        beginAdjustment,
        attachDuplicates
    }
}

export default dialogs;