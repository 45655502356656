<template>
    <v-app>
        <Overlay class="top-level" absolute />
        <component :is="layout" />
    </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import LoginLayout from '@/layouts/LoginLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import Overlay from '@/components/global/Overlay';
import { httpAPI } from "@/api/httpAPI";

export default {
    name: 'App',
    components: {
        LoginLayout,
        MainLayout,
        Overlay
    },
    data: () => ({}),
    computed: {
        ...mapGetters({ userActivityState: 'getUserActivityState' }),
        layout() {
            return this.$route.meta.layout || 'login-layout';
        },
    },
    methods: {
        ...mapMutations({ setUserActivityState: 'SET_USER_ACTIVITY_STATE' }),
        getLanguage() {
            switch (this.$i18n.locale)
            {
                case "kk":
                    return 0;

                default:
                    return 1;
            }
        },
        callLangStyles() {

            if(this.customStyle == null){
                this.customStyle = document.createElement('style');
                document.head.appendChild(this.customStyle);
            }
            
            for (let index = 0; index < this.customStyle.sheet.cssRules.length; index++) {
                
                this.customStyle.sheet.deleteRule(index);
            }
            
            if (this.getLanguage() == 0){
                
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after { content: "Иә" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch .v-input--switch__track:after { content: "Жоқ" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.chipAreaNoData::after, .time-picker.time-empty::after { content: "Міндетті өріс" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .vue__time-picker .dropdown .hours .hint::after { content: 'Сағат' }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .vue__time-picker .dropdown .minutes .hint::after { content: 'Минут' }`, 0
                );
            }    
            else
            {
                
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after { content: "Да" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch .v-input--switch__track:after { content: "Нет" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.chipAreaNoData::after, .time-picker.time-empty::after { content: "Обязательное поле" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .vue__time-picker .dropdown .hours .hint::after { content: 'Часы' }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .vue__time-picker .dropdown .minutes .hint::after { content: 'Минуты' }`, 0
                );
            }    
                        
        }
    },
    updated() {
        this.callLangStyles();
    },
    mounted() {
        //tick's triggers only ifvisible
        this.$ifvisible.onEvery(5, async () => {
            let newState = { type: 'active', date: this.$moment().valueOf() };
            let key = this.$store.getters['global/auth/getUserInfo'];
            if (this.userActivityState && key) {
                let checkDate = this.$moment(this.userActivityState.date);
                let diff = this.$moment().diff(checkDate, 'seconds');

                if (diff > 299)
                    await httpAPI({ url: `/api/auth/confirm`, method: 'GET', skipErrorHandler: true });
                else
                    newState.date = this.userActivityState.date;
            }
            this.setUserActivityState(newState);
        });
    }
};
</script>

<style lang="scss">
    @import "./assets/styles/common.css";
    @import "./assets/styles/notifystyle.css";

    .top-level {
        z-index: 1000 !important;
    }
</style>