const selectNomenclature = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        resolve: null,
        reject: null,

        nomenclature: null,
        nomenclatures: [],

        search: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_SEARCH(state, payload) {
            state.search = payload;
            state.nomenclature = null;
        },
        SET_NOMENCLATURE(state, payload) {
            state.nomenclature = payload;
        },
        SET_NOMENCLATURES(state, payload) {
            state.nomenclatures = payload;
        },
    },
    actions: {
        async open({ commit, dispatch }) {
            commit('SET_LOADING', true);

            commit('SET_NOMENCLATURE', null);
            commit('SET_SEARCH', null);
            commit('SET_VISIBLE', true);

            let nomenclatures = await dispatch('documents/references/getNomenclatures', { forDepartment: false }, { root: true });
            commit('SET_NOMENCLATURES', nomenclatures);

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.nomenclature);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        isValid: (s) => s.nomenclature != null,
        getNomenclature: (s) => s.nomenclature,
        getSearch: (s) => s.search,
        getNomenclatures(state) {

            if (state.search)
                return state.nomenclatures?.filter(x => (''+x[1]).concat(' ', x[2]).toLowerCase().includes(state.search.toLowerCase())) ?? [];

            return state.nomenclatures;
        }
    }
}

export default selectNomenclature;