import { httpAPI } from "@/api/httpAPI";

const DocTypes = 
{
    OutgoingDocument: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
    IncomingDocument: "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument"
};

const attachDuplicates = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,

        sourceDocumentType: null,

        reason: null,

        documentSearch: null,
        selectedDocument: null,
        documentPage: 1,
        documentPerPage: 6,
        documents: [],
        documentsTotal: 0,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_REASON(state, payload) {
            state.reason = payload;
        },
        SET_DOCUMENTS(state, payload) {
            state.documents = payload;
        },
        SET_SELECTED_DOCUMENT(state, payload) {
            state.selectedDocument = payload;
        },
        SET_DOCUMENT_SEARCH(state, payload) {
            state.documentSearch = payload;
        },
        SET_DOCUMENT_PAGE(state, payload) {
            state.documentPage = payload;
        },
        SET_DOCUMENT_TOTAL(state, payload) {
            state.documentsTotal = payload;
        },
        SET_SOURCE_DOCUMENT_TYPE(state, payload) {
            state.sourceDocumentType = payload;
        }
    },
    actions: {
        async open({ dispatch, commit }, type) {
            commit('SET_SOURCE_DOCUMENT_TYPE', type);

            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);
            
            commit('SET_DOCUMENT_PAGE', 1);
            commit('SET_DOCUMENT_SEARCH', null);
            commit('SET_SELECTED_DOCUMENT', null);

            commit('SET_REASON', null);

            await dispatch('fetchDocuments');

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ reason: state.reason, document: state.selectedDocument });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async changeSearch({ commit, dispatch }, value) {
            commit('SET_DOCUMENT_SEARCH', value);
            commit('SET_DOCUMENT_PAGE', 1);
            await dispatch('fetchDocuments');
        },
        async changePage({ commit, dispatch }, value) {
            commit('SET_DOCUMENT_PAGE', value);
            await dispatch('fetchDocuments');
        },
        async fetchDocuments({ commit, state }) {

            let filter = 
            {
                SearchStr: state.documentSearch,
                DocType: DocTypes[state.sourceDocumentType],
                Period: null,
                RegState: 'Registered',
                IncludeDuplicate: false,
                OrderBy: 3,
                OrderDir: true,
                Limit: state.documentPerPage,
                Offset: (state.documentPage - 1) * state.documentPerPage
            };

            let response = await httpAPI({
                url: `/api/correspondence/documentselect?filter=${JSON.stringify(filter)}`,
                method: 'GET'
            });

            if (response) {
                commit('SET_DOCUMENTS', response.data.Payload.data ?? []);
                commit('SET_DOCUMENT_TOTAL', response.data.Payload.total);
            }
            else {
                commit('SET_DOCUMENTS', []);
                commit('SET_DOCUMENT_TOTAL', 0);
            }
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getDocuments: (s) => s.documents,

        
        getDocumentSearch: (s) => s.documentSearch,
        getDocumentPage: (s) => s.documentPage,
        getDocumentPerPage: (s) => s.documentPerPage,
        getDocumentsTotal: (s) => s.documentsTotal,

        getSelectedDocument: (s) => s.selectedDocument,
        getReason: (s) => s.reason,
    }
}

export default attachDuplicates;