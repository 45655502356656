import statements from "./store/euol-statements";
import answers from "./store/euol-answers";

const statementEFOV = {
    EFAnnotation: {
        label: "Краткое_содержание",
        value: null
    },
    Declarant_IIN_BIN:
    {
        label: "ИИН_БИН",
        value: null
    },
    StQuestion:
    {
        label: "Характер_вопроса",
        value: null
    },
    PerCategory:
    {
        label: "Категория_лица",
        value: null
    },
    StLanguage:
    {
        label: "Язык_обращения",
        value: null
    },
    StForm:
    {
        label: "Форма_обращения",
        value: null
    },
    ExecuterWorkplaceId:
    {
        label: "Исполнитель",
        value: null
    },
    ControlDateFrom:
    {
        label: "Срок_исполнения_с",
        value: null
    },
    ControlDateTo:
    {
        label: "Срок_исполнения_по",
        value: null
    },
    EUOL_IsRedirected:
    {
        label: "Перенаправлено",
        value: null
    }
};
const outgoingEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Исходящий_номер",
        value: null
    },
    RegDateFrom: {
        label: "Дата_исходящего_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_исходящего_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    },
    OutgoingExecuters: {
        label: "Исполнитель",
        value: []
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    SendStatus: {
        label: "Статус_отправки",
        value: null
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
    SignerEmployeeIds: {
        label: "Подписал",
        value: []
    },
    DocumentAuthor: {
        label: "Кем_создано",
        value: []
    }
};

const getEFOV = function (source, excludeProps = []) {
    let efov = Object.assign({}, source);

    if (excludeProps.length > 0)
        excludeProps.forEach( prop => delete efov[prop] );

    return efov;
};

const euolArchive = {
    namespaced: true,
    state: {
        collection : "EuolStatements",
        status: null,
        searches: [
            { collection: "EuolStatements", value: null },
            { collection: "EuolAnswers", value: null }
        ],
        extendedFilters:[
            {
                collection: "EuolStatements",
                value: getEFOV(statementEFOV)
            },
            {
                collection: "EuolAnswers",
                value: getEFOV(outgoingEFOV)
            }
        ]
    },
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            state.status = payload;
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        },
        PUSH_EXTENDED_FILTER (state, payload) {
            let exists = state.extendedFilters.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.extendedFilters.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.status;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection).value;
        },
        getExtendedFilter (state) {
            return state.extendedFilters.find(x => x.collection === state.collection)?.value ?? {};
        }
    },
    modules: {
        statements,
        answers
    }
}

export default euolArchive;