import onAdjustment from './on-adjustment';
import onCheck from './on-check';
import onExecution from './on-execution';
import onInspect from './on-inspect';
import onRework from './on-rework';
import onSign from './on-sign';
import onControl from './on-control';
import onInspectResolution from './on-inspect-resolution';

export default {
    namespaced: true,
    state: () => ({
        collection : "Documents.My.OnInspect",
        status: null,
        searches: []
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        SET_STATUS (state, payload) {
            state.status = payload;
        },
        PUSH_SEARCH (state, payload) {
            let searchExists = state.searches.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.status;
        },
        getSearches (state) {
            return state.searches;
        }
    },
    modules: {
        onAdjustment,
        onCheck,
        onExecution,
        onInspect,
        onRework,
        onSign,
        onControl,
        onInspectResolution
    }
}