import { httpAPI } from "@/api/httpAPI";

const RegionsUptime = 480;

const isOutdated = (moment, sync, uptime) => {
    let now = moment(Date.now());
    let syncTime = moment(sync);
    return moment.duration(now.diff(syncTime)).asMinutes() > uptime;
};

export default {
    namespaced: true,
    state: () => ({
        regions: [],
        regionsSync: 0,
    }),
    mutations: {
        SET_REGIONS (state, payload)
        {
            state.regions = payload;
            state.regionsSync = Date.now();
        }
    },
    actions: {
        async fetchRegions({ commit, state, getters }) {

            if (isOutdated(this._vm.$moment, state.regionsSync, RegionsUptime)) {
                try {
                    let regionsResponse = await httpAPI.get('saylau/adressregister/regions');
                    commit('SET_REGIONS', regionsResponse.data);
                }
                catch (error) {
                    console.error('Ошибка обновления данных по регионам.');
                }
            }

            return getters.getRegions;
        }
    },
    getters: {
        getRegions: (s) => s.regions,
    }
}