<template>
    <div>
        <router-view />
        <UserReplacePassword ref="UserReplacePasswordRef" />
        <PromptProfileDlg v-if="isPromptProfileVisible" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserReplacePassword from '@/components/global/dialogs/UserReplacePassword';

export default {
    name: "Login",
    components: {
        UserReplacePassword,
        PromptProfileDlg: () => import('@/components/global/dialogs/signing/PromptProfileDlg'),
    },
    computed: {
        ...mapGetters('global/signing', [ 'isPromptPasswordVisible', 'isPromptProfileVisible' ]),
    }
}
</script>