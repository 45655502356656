import orders from './store/orders';
import actitems from './store/actitems';
import protocolonsignlist from './store/protocolonsignlist'
import protocolonexecutionlist from './store/protocolonexecutionlist'
import protocolonadjustmentlist from './store/protocolonadjustmentlist'
import protocolonexecutioncontrollist from './store/protocolonexecutioncontrollist'
import protocoloncontrollist from './store/protocoloncontrollist'
import svetofor from './store/svetofor';

const lists = {
    namespaced: true,
    modules: {
        orders,
        actitems,
        protocolonsignlist,
        protocolonexecutionlist,
        protocolonadjustmentlist,
        protocolonexecutioncontrollist,
        protocoloncontrollist,
        svetofor
    }
}

export default lists;