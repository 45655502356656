import { httpAPI } from "@/api/httpAPI";

const DocTypes = 
{
    OutgoingDocument: "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument",
    IncomingDocument: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
    InnerDocument: "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument",
};

const addDocumentLink = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,

        sourceDocumentType: null,

        selectedLinkType: null,
        linkTypes: [],

        documentSearch: null,
        selectedDocument: null,
        documentPage: 1,
        documentPerPage: 6,
        documents: [],
        documentsTotal: 0,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_LINK_TYPES(state, payload) {
            state.linkTypes = payload;
        },
        SET_SELECTED_LINK_TYPE(state, payload) {
            state.selectedLinkType = payload;
        },
        SET_DOCUMENTS(state, payload) {
            state.documents = payload;
        },
        SET_SELECTED_DOCUMENT(state, payload) {
            state.selectedDocument = payload;
        },
        SET_DOCUMENT_SEARCH(state, payload) {
            state.documentSearch = payload;
        },
        SET_DOCUMENT_PAGE(state, payload) {
            state.documentPage = payload;
        },
        SET_DOCUMENT_TOTAL(state, payload) {
            state.documentsTotal = payload;
        },
        SET_SOURCE_DOCUMENT_TYPE(state, payload) {
            state.sourceDocumentType = payload;
        }
    },
    actions: {
        async open({ dispatch, commit, rootGetters }, type) {
            commit('SET_SOURCE_DOCUMENT_TYPE', type);

            
            commit('SET_VISIBLE', true);
            
            commit('SET_DOCUMENT_PAGE', 1);
            commit('SET_DOCUMENT_SEARCH', null);
            commit('SET_SELECTED_DOCUMENT', null);
            
            let typesLinks = [];
            switch (type)
            {
                case 'OutgoingDocument':
                    typesLinks = rootGetters['documents/references/GetOutcomingLinksTypes'];
                    break;

                case 'IncomingDocument':
                    typesLinks = rootGetters['documents/references/GetIncomingLinksTypes'];
                    break;

                case 'InnerDocument':
                    typesLinks = rootGetters['documents/references/GetInnerLinksTypes'];
                    break;
            }

            commit('SET_LINK_TYPES', typesLinks);

            commit('SET_SELECTED_LINK_TYPE', typesLinks[0].id);

            await dispatch('fetchDocuments');

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ type: state.selectedLinkType, document: state.selectedDocument });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async changeSearch({ commit, dispatch }, value) {
            commit('SET_DOCUMENT_SEARCH', value);
            commit('SET_DOCUMENT_PAGE', 1);
            await dispatch('fetchDocuments');
        },
        async changePage({ commit, dispatch }, value) {
            commit('SET_DOCUMENT_PAGE', value);
            await dispatch('fetchDocuments');
        },
        async fetchDocuments({ commit, state, rootGetters }) {
            commit('SET_LOADING', true);

            let filter = 
            {
                SearchStr: state.documentSearch,
                DocType: DocTypes[state.sourceDocumentType],
                Period: null,
                RegState: 'Registered',
                IncludeDuplicate: false,
                OrderBy: 3,
                OrderDir: true,
                Limit: state.documentPerPage,
                Offset: (state.documentPage - 1) * state.documentPerPage
            };

            if (state.sourceDocumentType == 'IncomingDocument')
            {
                if (state.selectedLinkType == 101)
                {
                    filter.IncludeDuplicate = true;
                    filter.DocType = "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument";
                }

                if (state.selectedLinkType == 102)
                {
                    filter.HasResolution = true;  
                    filter.DocType = "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument";
                }
            }
            
            // Изменение фильтра для общих исх. проектов, включает в фильтр id выбранных получателей
            /* if (state.sourceDocumentType == 'OutgoingDocument' && rootGetters['global/actionsource/isDataSourceCommon']) {
                let recipients = Array.from(rootGetters['global/actionsource/getDataSourceEntity']?.Card?.Recipients ?? []);
                
                if (recipients.length)
                    filter.CorrespondentIds = `{${recipients.map(x => `${x.Id}`).join()}}`;
            } */

            let response = await httpAPI({
                url: `/api/correspondence/documentselect?filter=${JSON.stringify(filter)}`,
                method: 'GET',
                headers: { 'isCommon': rootGetters['global/actionsource/isDataSourceCommon'] }
            });

            if (response) {
                commit('SET_DOCUMENTS', response.data.Payload.data ?? []);
                commit('SET_DOCUMENT_TOTAL', response.data.Payload.total);
            }
            else {
                commit('SET_DOCUMENTS', []);
                commit('SET_DOCUMENT_TOTAL', 0);
            }
            commit('SET_LOADING', false);
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getDocuments: (s) => s.documents,
        getSelectedDocument: (s) => s.selectedDocument,
        getDocumentSearch: (s) => s.documentSearch,
        getDocumentPage: (s) => s.documentPage,
        getDocumentPerPage: (s) => s.documentPerPage,
        getDocumentsTotal: (s) => s.documentsTotal,
        getLinkTypes: (s) => s.linkTypes,
        getSelectedLinkType: (s) => s.selectedLinkType,
        getSourceDocumentType: (s) => s.sourceDocumentType,
    }
}

export default addDocumentLink;