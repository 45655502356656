<template>
    <div class="m-m-b-wrapper">
        
        <!--Лого-->
        <router-link to="/" class="m-m-b-logo-link" v-tooltip.right="$t('Главная')">
            <img class="m-m-b-logo" src="@/assets/logo_short.svg">
        </router-link>

        <!--Меню модулей-->
        <v-list
            nav
            dense
            class="mt-auto mb-auto"
        >
            <v-list-item-group>

                <template v-for="m in modules">
                    <v-list-item
                        v-if="m.index !== 0"
                        :key="m.name + m.index"
                        v-tooltip.right="$t(m.name)"
                        link
                        :to="m.route"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ m.icon }}</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </template>

            </v-list-item-group>
        </v-list>

        <!--Профиль-->
        <div class="m-m-b-profile-wrap">
            <v-btn
                class="m-m-b-profile"
                icon
                link
                to="/profile"
                v-tooltip.right="$t('Профиль')"
            >
                <v-icon v-if="!ownPhoto || ownPhoto.length == 0">far fa-user-circle</v-icon>
                <v-img
                    v-else
                    :src="ownPhoto"
                    :lazy-src="ownPhoto"
                    width="24"
                    height="24"
                    max-width="24"
                    max-height="24"
                    class="rounded-circle"
                >
                </v-img>
            </v-btn>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpAPI } from '@/api/httpAPI';

export default {
    name: 'ModuleMenuPanel',
    data: () => ({
        ownPhoto: '',
    }),
    computed: {
        ...mapGetters({ systemModules: 'getModules' }),
        modules() {
            let result = [];

            this.systemModules.forEach(systemModule =>
            {
                if (this.$validatePermission(systemModule.access))
                    result.push(systemModule);
            });

            result.sort((a, b) => a - b)

            return result;
        }
    },
    methods:{
        async getPhoto(){
            let url = `/api/ems/getownphoto`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response) {
                this.ownPhoto=response?.data?.Payload?.Data?.Object ?? '';
            }
        },
        async initialize() {
            await this.getPhoto();
        },
    },
    created() {
        (async() => {
            this.initialize();
        })();      
    },
};
</script>