export default {
    namespaced: true,
    state: () => ({
        region_id: null,
        cities:[],
        electionParts:[],
        city_id: null,
        visiblyStatusFilters: false,
        withResidents: true,
        withElectionPart: true,
        search:'',
        tableOptions:
        {
            page: 1,
            itemsPerPage : 25,
            sortBy : ["city"],
            sortDesc : [false],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
    }),
    mutations: {
        SET_REGION_ID(state, payload) {
            state.region_id = payload;
        },
        SET_CITIES(state, payload){
            state.cities = payload;
        },
        SET_ELECTIONPARTS(state, payload){
            state.electionParts = payload;
        },
        SET_CITY_ID(state, payload) {
            state.city_id = payload;
        },
        SET_VISIBLY_STATUS_FILTER(state, payload){
            state.visiblyStatusFilters = payload;
        },
        SET_WITH_RESIDENTS(state, payload) {
            state.withResidents = payload;
        },
        SET_WITH_ELECTION_PART(state, payload) {
            state.withElectionPart = payload;
        },
        SET_SEARCH(state, payload) {
            state.search = payload;
        },
        SET_TABLE_OPTION(state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE(state, payload) {
            state.tableOptions.page = payload;
        },
    },
    getters: {
        getRegionId: (s) => s.region_id,
        getCities: (s) => s.cities,
        getElectionParts: (s) => s.electionParts,
        getCityId: (s) => s.city_id,
        getVisiblyStatusFilter: (s) => s.visiblyStatusFilters,
        getWithResidents: (s) => s.withResidents,
        getWithElectionPart: (s) => s.withElectionPart,
        getSearch: (s) => s.search,
        getTableOptions: (s) => s.tableOptions,
    }
}