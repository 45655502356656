import incomings from './store/incomings';
import outgoings from './store/outgoings';
import inners from './store/inners';
import dirords from './store/dirords';
import ords from './store/ords';

const incomingEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Рег._номер",
        value: null
    },
    OutNumber: {
        label: "Исходящий_номер",
        value: null
    },
    HigherNumber: {
        label: "Номер_вышестоящего_корреспондента",
        value: null
    },
    Correspondent: {
        label: "Корреспондент",
        value: []
    },
    ExecuterName: {
        label: "Исполнитель",
        value: null
    },
    SignerName: {
        label: "Подписал",
        value: null
    },
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    OutDateFrom: {
        label: "Дата_исходящего_c",
        value: null
    },
    OutDateTo: {
        label: "Дата_исходящего_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    Executers: {
        label: "Исполнитель",
        value: []
    },
    ResolutionAuthor: {
        label: "Автор_резолюции",
        value: []
    },
    HasAnswer: {
        label: "Дан_ответ",
        value: null
    },
    ExecuteStatus: {
        label: "Статус_исполнения",
        value: []
    },
    IsDuplicate: {
        label: "Дубликат",
        value: null
    },
    IsIncomingAnswer: {
        label: "Входящий_ответ",
        value: null
    },
    Counter: {
        label: "Журнал_регистрации",
        value: null
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
};
const outgoingEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Исходящий_номер",
        value: null
    },
    RegDateFrom: {
        label: "Дата_исходящего_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_исходящего_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    },
    OutgoingExecuters: {
        label: "Исполнитель",
        value: []
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    SendStatus: {
        label: "Статус_отправки",
        value: null
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
    SignerEmployeeIds: {
        label: "Подписал",
        value: []
    },
    DocumentAuthor: {
        label: "Кем_создано",
        value: []
    }
};
const innerEFOV = {
    EFTheme: {
        label: "Краткое_содержание",
        value: null
    },
    RegNumber: {
        label: "Рег._номер",
        value: null
    },
    RegDateFrom: {
        label: "Дата_регистрации_c",
        value: null
    },
    RegDateTo: {
        label: "Дата_регистрации_по",
        value: null
    },
    Language: {
        label: "Язык_документа",
        value: null
    },
    Executers: {
        label: "Исполнитель",
        value: []
    },
    ResolutionAuthor: {
        label: "Автор_резолюции",
        value: []
    },
    Recipients: {
        label: "Получатель",
        value: []
    },
    NomenclatureId: {
        label: "Номенклатура",
        value: []
    },
    JuridicalDocType: {
        label: "Тип_документа",
        value: null
    },
    SignerEmployeeIds: {
        label: "Подписал",
        value: []
    }
};

const getEFOV = function (source, excludeProps = []) {
    let efov = Object.assign({}, source);

    if (excludeProps.length > 0)
        excludeProps.forEach( prop => delete efov[prop] );

    return efov;
};

const documentsArchive = {
    namespaced: true,
    state: {
        collection : "Incomings",
        status: null,
        searches: [
            { collection: "Incomings", value: null },
            { collection: "Outgoings", value: null },
            { collection: "Inners", value: null },
            { collection: "Dirords", value: null },
            { collection: "Ords", value: null }
        ],
        extendedFilters:[
            {
                collection: "Incomings",
                value: getEFOV(incomingEFOV)
            },
            {
                collection: "Outgoings",
                value: getEFOV(outgoingEFOV)
            },
            {
                collection: "Inners",
                value: getEFOV(innerEFOV, ['SignerEmployeeIds'])
            },
            {
                collection: "Dirords",
                value: getEFOV(incomingEFOV)
            },
            {
                collection: "Ords",
                value: getEFOV(innerEFOV)
            }
        ]
    },
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            state.status = payload;
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        },
        PUSH_EXTENDED_FILTER (state, payload) {
            let exists = state.extendedFilters.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.extendedFilters.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.status;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection).value;
        },
        getExtendedFilter (state) {
            return state.extendedFilters.find(x => x.collection === state.collection)?.value ?? {};
        }
    },
    modules: {
        incomings,
        outgoings,
        inners,
        dirords,
        ords
    }
}

export default documentsArchive;