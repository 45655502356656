import i18n from '@/i18n'

const reject = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        resolve: null,
        reject: null,

        reason: null,
        reasons: null,
        otherReason: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_REASON(state, payload) {
            state.reason = payload;
        },
        SET_OTHER_REASON(state, payload) {
            state.otherReason = payload;
        },
        SET_REASONS(state, payload) {
            state.reasons = payload;
        },
    },
    actions: {
        async open({ commit, dispatch }) {
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);

            commit('SET_REASON', null);
            commit('SET_OTHER_REASON', null);

            let reasonsRefs = await dispatch('documents/references/getReference', { id: 1006 }, { root: true });
            let reasonsToModify = Array.from(reasonsRefs);
            reasonsToModify.push({ id: 0, Value: i18n.t("Другая_причинаL") });

            commit('SET_REASONS', reasonsToModify);

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            
            let result =
            {
                reason: state.reason === 0 ? null : { id: state.reason, ReferenceType: 1006 },
                comment: state.reason === 0 ? state.otherReason : null
            }

            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        isValid: (s) => s.reason === 0 ? s.otherReason?.length : s.reason != null,
        getReasons: (s) => s.reasons,
        getReason: (s) => s.reason,
        getOtherReason: (s) => s.otherReason
    }
}

export default reject;