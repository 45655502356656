export default {
    namespaced: true,
    state: () => ({
        filterPanelState: false,
        region: null,
        tableOptions:
        {
            page: 1,
            itemsPerPage : 25,
            sortBy : [ "fullName" ],
            sortDesc : [ false ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : true
        },

        documentStatus: "All",
        lifeStatus: "All",

        extendedFilter: null
    }),
    mutations: {
        SET_FILTER_PANEL_STATE(state, payload) {
            state.filterPanelState = payload;
        },
        SET_REGION(state, payload) {
            state.region = payload;
            state.documentStatus = "All";
            state.lifeStatus = "All";
        },
        SET_TABLE_OPTION(state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE(state, payload) {
            state.tableOptions.page = payload;
        },
        SET_EXTENDED_FILTER(state, payload) {
            state.extendedFilter = payload;
        },
        SET_DOCUMENT_STATUS(state, payload) {
            state.documentStatus = payload;
        },
        SET_LIFE_STATUS(state, payload) {
            state.lifeStatus = payload;
        },
    },
    getters: {
        getFilterPanelState: (s) => s.filterPanelState,
        getRegion: (s) => s.region,
        getTableOptions: (s) => s.tableOptions,
        getExtendedFilter: (s) => s.extendedFilter,
        getDocumentStatus: (s) => s.documentStatus,
        getLifeStatus: (s) => s.lifeStatus
    }
}