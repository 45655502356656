import { httpAPI, errorHandler } from "@/api/httpAPI";

export default {
    namespaced: true,
    state: () => ({
        tableOptions:
        {
            page: 1,
            itemsPerPage : 25,
            sortBy : [ "created_at" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : true
        },
        searchType: 0,

        filterFio: {
            surname: "",
            name: "",
            patronymic: "",
            birthDate: null
        },
        filterIin: {
            iin: ""
        },

        loading: false,
        entities: [],
        total: 0
    }),
    mutations: {
        SET_TABLE_OPTION(state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE(state, payload) {
            state.tableOptions.page = payload;
        },
        SET_SEARCH_TYPE(state, payload) {
            state.entities = [];
            state.total = 0;
            state.searchType = payload;
        },
        SET_FILTER_FIO(state, payload) {
            state.filterFio = payload;
        },
        SET_FILTER_IIN(state, payload) {
            state.filterIin = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_TABLE_DATA(state, payload) {
            state.entities = payload.items;
            state.total = payload.total;
        }
    },
    actions: {
        async addGbdflSearch({ getters, commit }) {
            commit('SET_LOADING', true);
            commit('SET_TABLE_DATA', { items:[], total: 0 });
            let requestData = null;

            switch (getters.getSearchType)
            {
                case 1:
                    requestData = getters.getFilterFio;
                    break;

                default:
                    requestData = getters.getFilterIin;
                    break;
            }

            requestData['userId'] = '00000000-0000-0000-0000-000000000000';
            requestData['searchType'] = getters.getSearchType;

            let onResolve = (response) => {  
                if (response.data.count == 0)              
                    this._vm.$notify.warning(`В_ГБД_ФЛ_отсутствуют_сведения_по_указанным_критериям_поиска`);
                else {
                    commit('SET_TABLE_DATA', { items: response.data.items, total: response.data.count });
                    this._vm.$notify.success(`Поиск завершен`);
                }
                commit('SET_LOADING', false);
            };

            let onReject = (error) => {
                commit('SET_LOADING', false);
                errorHandler(error);
            };

            let request = httpAPI.post(`saylau/gbdfl-search`, requestData, { skipErrorHandler: true });
            this._vm.$notify.async(request, onResolve, onReject, "Поиск в ГБД ФЛ", { icons: { enabled: true } });
        }
    },
    getters: {
        getTableOptions: (s) => s.tableOptions,
        getSearchType: (s) => s.searchType,
        getFilterFio: (s) => s.filterFio,
        getFilterIin: (s) => s.filterIin,
        isLoading: (s) => s.loading,
        getEntities: (s) => s.entities,
        getTotal: (s) => s.total,
    }
}