import { httpAPI } from "@/api/httpAPI";

import lists from './lists'
import dialogs from './dialogs'

const orders = {
    namespaced: true,
    state: {
        currentCollection: null,
        updateCounterInProcess: false,
        on_execution_count: null,
        on_control_count: null,
        on_adjustment_count: null,
        on_execution_control_count: null
    },
    mutations: {
        SET_CURRENT_COLLECTION(state, payload) {
            state.currentCollection = payload;
        },
        SET_UPDATE_IN_PROCESS(state, payload) {
            state.updateCounterInProcess = payload;
        },
        SET_EXECUTION_COUNT(state, payload){
            state.on_execution_count = payload;
        },
        SET_CONTROL_COUNT(state, payload){
            state.on_control_count = payload;
        },
        SET_ADJUSTMENT_COUNT(state, payload){
            state.on_adjustment_count = payload;
        },
        SET_EXECUTION_CONTROL_COUNT(state, payload){
            state.on_execution_control_count = payload;
        }
    },
    getters: {
        getCurrentCollection: (s) => s.currentCollection,
        getExecutionCount: (s) => s.on_execution_count,
        getControlCount: (s) => s.on_control_count,
        getAdjustmentCount: (s) => s.on_adjustment_count,
        getExecutionControlCount: (s) => s.on_execution_control_count,
    },
    actions:{
        async updateCounter({commit, state}){
            if (!state.updateCounterInProcess){
                commit('SET_UPDATE_IN_PROCESS', true);
                try {
                    let response = await httpAPI({
                        url: `api/orders/counter`,
                        method: 'GET',
                    });
                    if (response?.data?.Payload){
                        let cnt = response.data.Payload.find(item => item.name==='on_control');
                        if (cnt)
                            commit('SET_CONTROL_COUNT', cnt.count);
                        cnt = response.data.Payload.find(item => item.name==='on_execution');
                        if (cnt)
                            commit('SET_EXECUTION_COUNT', cnt.count);
                        cnt = response.data.Payload.find(item => item.name==='on_adjustment');
                        if (cnt)
                            commit('SET_ADJUSTMENT_COUNT', cnt.count);
                        cnt = response.data.Payload.find(item => item.name==='on_execution_control');
                        if (cnt)
                            commit('SET_EXECUTION_CONTROL_COUNT', cnt.count);
                    }
                }
                finally {
                    commit('SET_UPDATE_IN_PROCESS', false);
                }
            }
        }
    },
    modules: {
        lists,
        dialogs
    }
}

export default orders;