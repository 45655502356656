<template>
    <v-overlay :value="visible" absolute>
        <span class="fs16pt">{{ $t(text) }}</span>
        
        <v-progress-linear 
            color="#a94442"
            opacity="1"
            :height="!progress ? 6 : 12"
            rounded
            striped
            :indeterminate="!progress"
            :value="progress"        
        >
            <template v-slot:default="{ value }">
                <strong v-if="progress">{{ value }}%</strong>
            </template>
        </v-progress-linear>

    </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Overlay',
    data: () => ({}),
    computed: {
        ...mapGetters({
            visible: 'isOverlayVisible',
            text: 'getOverlayText',
            progress: 'getOverlayProgress'
        }),
    },
};
</script>

<style lang="scss" scoped>
.fs16pt {
    font-size: 16pt;
}
</style>