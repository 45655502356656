import { httpAPI } from "@/api/httpAPI";

const selectAddressChip = {
    namespaced: true,
    state: {
        params: null,
        items: [],
        loading: true,
        autoFocus: false
    },
    mutations: {
        SET_PARAMS(state, payload) {
            state.params = payload;
        },
        SET_ITEMS(state, payload) {
            state.items = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_AUTO_FOCUS(state, payload) {
            state.autoFocus = payload;
        },
    },
    actions: {
        //вызываем при выпадении меню
        async updateItems({ commit, state }, { params }) {
            if (params.region === state.params?.region)
                return;

            commit('SET_PARAMS', params);
            commit('SET_LOADING', true);

            let tmp = [];
            let addressesResponse = await httpAPI.get(`saylau/adressregister/addresses/${params.region}`);

            if (addressesResponse)
                tmp = addressesResponse.data;

            commit('SET_ITEMS', tmp);
            commit('SET_LOADING', false);
        }
    },
    getters: {
        getParams: (s) => s.params,
        isLoading: (s) => s.loading,
        getItems(state) {

            return state.items;
        },
        isNeedToSetFocus: (s) => s.autoFocus,
    }
};

export default selectAddressChip;