export default {
    namespaced: true,
    state: () => ({
        region: null,
        search: null,
        status: 'All',
        workStatus: 'All',
        tableOptions:
        {
            page: 1,
            itemsPerPage : 25,
            sortBy : [ "number" ],
            sortDesc : [ false ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : true
        }
    }),
    mutations: {
        SET_REGION(state, payload) {
            state.region = payload;
        },
        SET_SEARCH(state, payload) {
            state.search = payload;
        },
        SET_STATUS(state, payload) {
            state.status = payload;
        },
        SET_WORK_STATUS(state, payload) {
            state.workStatus = payload;

            if (payload == 'Disbanded')
                state.status = 'All';
        },
        SET_TABLE_OPTION(state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE(state, payload) {
            state.tableOptions.page = payload;
        }
    },
    getters: {
        getRegion: (s) => s.region,
        getSearch: (s) => s.search,
        getStatus: (s) => s.status,
        getWorkStatus: (s) => s.workStatus,
        getTableOptions: (s) => s.tableOptions,
    }
}