import axios from "axios";
import Vue from 'vue';
import i18n from '@/i18n';
import sys from '@/services/system';
import store from '../store';

const getCancelTokenSource = () => axios.CancelToken.source();
const httpAPI = axios.create();

var isLogoutNotificationActive = false;
var sessionId = sessionStorage.getItem('SessionId');

if (!sessionId) {
    sessionId = sys.generateUUID()
    sessionStorage.setItem('SessionId', sessionId);                          
}

httpAPI.defaults.headers.common['sessionId'] = sessionId;

const errorHandler = async (error) => {    
    if (axios.isCancel(error))
        Vue.prototype.$notify.warning(i18n.t('Действие_отменено'));

    let message = "";
    let statusCode = error?.response?.status;

    if (statusCode === 404) {
        message = i18n.t('Запрашиваемый_ресурс_не_найден');
    }
    else if (statusCode === 500) {
        if (error.response?.data?.type === "text/plain") {
            const reader = new FileReader();
            reader.onload = function() {
                message = this.result;
            };
            reader.readAsText(error?.response?.data);
        }
        else {
            message = i18n.t('Ошибка_обработки_запроса');
        }
    }
    else if (statusCode === 401) {
        if (store.getters['global/auth/isAuthorized']) {
            let message = i18n.t('Необходимо_пройти_повторную_аутентификацию')

            if (error?.response?.data)
                message += `:<br/>${i18n.t('Отсутствует_токен_авторизации')}`;

            if (!isLogoutNotificationActive) {

                isLogoutNotificationActive = true;

                Vue.prototype.$notify.confirm(
                    i18n.t(message),
                    async () =>
                    {
                        await store.dispatch('global/auth/fetchEmergencyLogout');
                        isLogoutNotificationActive = false;
                    },
                    false,
                    {
                        labels: {
                            confirm: i18n.t('Аутентификация')
                        }
                    }
                );
            }
        }
        else {
            Vue.prototype.$notify.alert(error?.response?.data);
        }
    }
    else {
        var errorJson = error.toJSON();
        message = errorJson.message;

        let errorData = error?.response?.data;

        if (error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') != -1) {
                errorData = JSON.parse(await error.response.data.text());
            }

        if (errorData != null) {
            //BadRequest с сообщением?
            if (typeof errorData == 'string') {
                message = errorData;
            }
            else if (typeof errorData == 'object') {
                //ошибка валидации
                if (errorData.errors != null) {
                    message = Object.keys(errorData.errors).map(x => errorData.errors[x].join(', ')).join();
                }
            }
        }
    }

    Vue.prototype.$notify.alert(message);
}

httpAPI.interceptors.response.use(
    // Do something with response data
    function (response) {
        const { config } = response;

        if(config?.skipErrorHandler === true)
            return response;

        if (response.data?.Success === false) {
            Vue.prototype.$notify.alert(response?.data?.Message);
            return null;
        }

        if (response.data?.Success === true && response.data?.Payload?.Result === "ERROR") {
            Vue.prototype.$notify.alert(response.data?.Payload?.Message);
            return null;
        }

        return response;
    },
    // Do something with response error
    (error) => {
        const { config } = error;

        if(config?.skipErrorHandler === true)
            return Promise.reject(error);

        errorHandler(error);
        return null;
    }
);

export { httpAPI, getCancelTokenSource, errorHandler };