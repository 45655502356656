export default {
    namespaced: true,
    state: () => ({
        status: "-10",
        search: null,

        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "createdate" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },

        extendedFilter: {
            DocumentAuthor:
            {
                label: "Автор",
                value: []
            },
            ResolutionAuthor:
            {
                label: "Автор_резолюции",
                value: []
            },                   
            Languages:
            {
                label: "Язык_документа",
                value: []
            },
            CarrierType:
            {
                label: "Тип_носителя",
                value: []
            }
        }
    }),
    mutations: {
        SET_STATUS (state, payload) {
            state.status = payload;
        },
        SET_SEARCH (state, payload) {
            state.search = payload;
        },
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
        SET_EXTENDED_FILTER (state, payload) {
            state.extendedFilter = payload;
        },
    },
    getters: {
        getTableOptions (state) {
            return state.tableOptions;
        },
        getExtendedFilter: (s) => s.extendedFilter,
        getStatus: (s) => s.status,
        getSearch: (s) => s.search
    }
}