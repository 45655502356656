export default {
    namespaced: true,
    state: () => ({
        filterPanelState: false,
        region: null,
        search:'',
        tableOptions:
        {
            page: 1,
            itemsPerPage : 25,
            sortBy : [ "fullName" ],
            sortDesc : [ false ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : true
        },
        extendedFilter: null
    }),
    mutations: {
        SET_FILTER_PANEL_STATE(state, payload) {
            state.filterPanelState = payload;
        },
        SET_REGION(state, payload) {
            state.region = payload;
        },
        SET_SEARCH(state, payload) {
            state.search = payload;
        },
        SET_TABLE_OPTION(state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE(state, payload) {
            state.tableOptions.page = payload;
        },
        SET_EXTENDED_FILTER(state, payload) {
            state.extendedFilter = payload;
        },
    },
    getters: {
        getFilterPanelState: (s) => s.filterPanelState,
        getRegion: (s) => s.region,
        getSearch: (s) => s.search,
        getTableOptions: (s) => s.tableOptions,
        getExtendedFilter: (s) => s.extendedFilter,
    }
}