import references from './references';
import toolbar from './toolbar';

const documents = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        references,
        toolbar
    }
}

export default documents;