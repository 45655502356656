import Vue from 'vue';
import VueRouter from 'vue-router';
import sys from '@/services/system';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: { layout: 'login-layout', config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import('@/views/global/LoginFull.vue'),
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', null);
            next();
        }
    },
    {
        path: '/',
        name: 'Main',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], hasModuleNav: false },
        component: () => import('@/views/global/MainPage.vue'),
        //Пока вместо главной будет календарь, редиректим на него
        redirect: { name: 'MeetingsCalendar' },
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'main');
            next();
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], hasModuleNav: false },
        component: () => import('@/views/global/cards/Profile.vue'),
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'profile');
            next();
        }
    },
    {
        path: '/saylau',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
        component: () => import('@/views/saylau/Main.vue'),
        redirect: { name: 'ElectionParts' },
        children: [
            {
                path: 'election-parts',
                name: 'ElectionParts',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/ElectionParts.vue'),
            },
            {
                path: 'election-parts/:id',
                name: 'ElectionPart',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/cards/ElectionPart.vue'),
                props: true
            },
            {
                path: 'voters',
                name: 'Voters',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/Voters.vue')
            },
            {
                path: 'tmp-voters',
                name: 'TempVoters',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/TempVoters.vue')
            },
            {
                path: 'on-distribution',
                name: 'VotersOnDistribution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/VotersOnDistribution.vue')   
            },
            {
                path: 'out-distribution',
                name: 'VotersOutDistribution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/VotersOutDistribution.vue')   
            },
            {
                path: 'on-actualization',
                name: 'VotersOnActualization',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/VotersOnActualization.vue')   
            },
            {
                path: 'voters/:id',
                name: 'Voter',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/cards/Voter.vue'),
                props: true
            },
            {
                path: 'addresses',
                name: 'Addresses',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/Addresses.vue'),
                props: true
            },
            {
                path: 'votersbyregion',
                name: 'VotersByRegion',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/RepVotersByRegion.vue'),
                props: true
            },
            {
                path: 'election-parts-info',
                name: 'Reports',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/Reports.vue'),
                props: true
            },
            {
                path: 'local-search',
                name: 'LocalSearch',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/LocalSearch.vue'),
            },
            {
                path: 'gbdfl-search',
                name: 'GbdflSearch',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Saylau" ] } },
                component: () => import('@/views/saylau/lists/GbdflSearch.vue'),
            },
        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'saylau');
            next();
        }
    },
    {
        path: '/meetings',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import('@/views/meetings/Main.vue'),
        redirect: { name: 'MeetingsCalendar' },
        children: [
            {
                path: 'meetings-list',
                name: 'MeetingsList',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/meetings/lists/Meetings.vue'),
            },
            {
                path: 'meetings-list/:id',
                name: 'Meeting',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/meetings/cards/MeetingCard.vue'),
            },
            {
                path: 'meetings-calendar',
                name: 'MeetingsCalendar',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/meetings/calendar/Meetings.vue'),
            },
            {
                path: 'meetingprotocol/:id',
                name: 'MeetingProtocolDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/cards/ProtocolCard.vue'),
            },
        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'meetings');
            next();
        }
    },
    {
        path: '/archive',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: [ "Documents" ] } },
        component: () => import('@/views/archive/Main.vue'),
        children: [
            {
                path: 'documents/:year',
                name: 'ArchiveDocuments',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/archive/lists/Documents.vue'),
                props: true
            },
            {
                path: 'documents/:year/incoming/:id',
                name: 'ArchiveIncomingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/IncomingCard.vue'),
                props: true
            },
            {
                path: 'documents/:year/outgoing/:id',
                name: 'ArchiveOutgoingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/OutgoingCard.vue'),
                props: true
            },
            {
                path: 'documents/:year/common-outgoing/:id',
                name: 'ArchiveOutgoingDocumentCommon',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/CommonOutgoingCard.vue'),
                props: true
            },
            {
                path: 'documents/:year/inner/:id',
                name: 'ArchiveInnerDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/InnerCard.vue'),
                props: true
            },
            {
                path: 'documents/:year/resolution/:id',
                name: 'ArchiveResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },
            {
                path: 'documents/:year/act-item/:id',
                name: 'ArchiveActItem',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/ActItem.vue'),
                props: true
            },
            {
                path: 'euol/:year',
                name: 'ArchiveEuol',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/archive/lists/Euol.vue'),
                props: true
            },
            {
                path: 'euol/:year/statement/:id',
                name: 'ArchiveEuolStatement',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/EuolStatement.vue'),
                props: true
            },
            {
                path: 'euol/:year/answer/:id',
                name: 'ArchiveEuolAnswer',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/OutgoingCard.vue'),
                props: true
            },
            {
                path: 'euol/:year/common-answer/:id',
                name: 'ArchiveEuolAnswerCommon',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/CommonOutgoingCard.vue'),
                props: true
            },
            {
                path: 'euol/:year/resolution/:id',
                name: 'ArchiveEuolResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },
        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'archive');
            next();
        }
    },
    {
        path: '/orders',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import('@/views/orders/Main.vue'),
        redirect: { name: 'ProtocolOnExecutionList' },
        children: [
            {
                path: 'orders-list',
                name: 'Orders',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/lists/Orders.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('orders/SET_CURRENT_COLLECTION', 'Orders');
                    next();
                }
            },
            {
                path: 'orders-list/:id',
                name: 'ProtocolDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/cards/ProtocolCard.vue'),
            },
            {
                path: 'orders-list/:protocolid/resolution/:id',
                name: 'ProtocolResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },


            {
                path: 'act-items-list',
                name: 'ActItems',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/lists/Orders.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('orders/SET_CURRENT_COLLECTION', 'ActItems');
                    next();
                }
            },
            {
                path: 'act-items-list/:id',
                name: 'ProtocolActItemDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/cards/ProtocolActItemCard.vue'),
            },
            {
                path: 'act-items-list/:actitemid/resolution/:id',
                name: 'ProtocolActItemResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },


            {
                path: 'svetofor',
                name: 'ProtocolsSvetofor',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/lists/Svetofor.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('orders/SET_CURRENT_COLLECTION', 'ProtocolSvetofor');
                    next();
                }
            },

            {
                path: 'onexecutionlist',
                name: 'ProtocolOnExecutionList',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/lists/Orders.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('orders/SET_CURRENT_COLLECTION', 'OnExecutionList');
                    next();
                },
            },
            {
                path: 'onexecutionlist/:id',
                name: 'ProtocolActItemDocumentOnExecution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/cards/ProtocolActItemCard.vue'),
            },
            {
                path: 'onexecutionlist/:actitemid/resolution/:id',
                name: 'ProtocolActItemResolutionOnExecution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },

            {
                path: 'oncontrollist',
                name: 'ProtocolOnControlList',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/lists/Orders.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('orders/SET_CURRENT_COLLECTION', 'OnControl');
                    next();
                }
            },
            {
                path: 'oncontrollist/:id',
                name: 'ProtocolActItemDocumentOnControl',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/cards/ProtocolActItemCard.vue'),
            },
            {
                path: 'oncontrollist/:actitemid/resolution/:id',
                name: 'ProtocolActItemResolutionOnControl',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },


            {
                path: 'onadjustmentlistlist',
                name: 'ProtocolOnAdjustmentList',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/lists/Orders.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('orders/SET_CURRENT_COLLECTION', 'OnAdjustmentList');
                    next();
                }
            },
            {
                path: 'onadjustmentlist/:id',
                name: 'ProtocolActItemDocumentOnAdjustment',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/cards/ProtocolActItemCard.vue'),
            },
            {
                path: 'onadjustmentlist/:actitemid/resolution/:id',
                name: 'ProtocolActItemResolutionOnAdjustment',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },
            
            
            {
                path: 'onexecutioncontrollist',
                name: 'ProtocolOnExecutionControlList',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/lists/Orders.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('orders/SET_CURRENT_COLLECTION', 'OnExecutionControl');
                    next();
                }
            },
            {
                path: 'onexecutioncontrollist/:id',
                name: 'ProtocolActItemDocumentOnExecutionControl',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/orders/cards/ProtocolActItemCard.vue'),
            },
            {
                path: 'onexecutioncontrollist/:actitemid/resolution/:id',
                name: 'ProtocolActItemResolutionOnExecutionControl',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },


        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'orders');
            next();
        }
    },
    {
        path: '/reportsgeneration',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import('@/views/reportsgeneration/Main.vue'),
        redirect: { name: 'AllMyReport' },
        children: [
            {
                path: 'allmyreport',
                name: 'AllMyReport',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/reportsgeneration/lists/AllMyReport.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('reportsgeneration/SET_CURRENT_COLLECTION', 'AllMyReport');
                    next();
                }
            },
            {
                path: 'allmyreport/:id',
                name: 'ReportQuery',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
                component: () => import('@/views/reportsgeneration/cards/ReportQuery.vue'),
            },
            {
                path: 'allmyreport/reportresolution/:id',
                name: 'AllMyReportReportResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
                component: () => import('@/views/reportsgeneration/cards/ReportTask.vue'),
            },
            {
                path: 'allmyreport/reportinteraction/:queryId/:executionId',
                name: 'ReportInteraction',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
                component: () => import('@/views/reportsgeneration/cards/ReportInteractionCard.vue'),
                props: (route) => ({ queryId: route.query.queryId, executionId: route.query.executionId, ...route.params })
            },

            {
                path: 'mycreated',
                name: 'MyCreated',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/reportsgeneration/lists/MyCreated.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('reportsgeneration/SET_CURRENT_COLLECTION', 'MyCreated');
                    next();
                }
            },
            {
                path: 'mycreated/reportresolution/:id',
                name: 'MyCreatedReportResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
                component: () => import('@/views/reportsgeneration/cards/ReportTask.vue'),
            },

            {
                path: 'myexecution',
                name: 'MyExecution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/reportsgeneration/lists/MyExecution.vue'),
                beforeEnter: (to, from, next) => {
                    store.commit('reportsgeneration/SET_CURRENT_COLLECTION', 'MyExecution');
                    next();
                }
            },
            {
                path: 'myexecution/reportresolution/:id',
                name: 'MyExecutionReportResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ] },
                component: () => import('@/views/reportsgeneration/cards/ReportTask.vue'),
            },
            
        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'reportsgeneration');
            next();
        }
    },
    {
        path: '/chancellery',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryIncomingDocumentsList", "ChancelleryOutcomingDocumentsList", "ChancelleryInternalDocumentsList"], strategy: "HAS_ANY" } },
        component: () => import('@/views/chancellery/Main.vue'),
        children: [
            {
                path: 'incomings',
                name: 'ChancelleryIncomings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryIncomingDocumentsList"] } },
                component: () => import('@/views/chancellery/lists/Incomings.vue'),
                props: true
            },
            {
                path: 'outgoings',
                name: 'ChancelleryOutgoings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryOutcomingDocumentsList"] } },
                component: () => import('@/views/chancellery/lists/Outgoings.vue'),
                props: true
            },
            {
                path: 'inners',
                name: 'ChancelleryInners',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryInternalDocumentsList"] } },
                component: () => import('@/views/chancellery/lists/Inners.vue'),
                props: true   
            },
            {
                path: 'resolutions',
                name: 'ChancelleryResolutions',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryOutcomingDocumentsList", "CanSignChancellery"] } },
                component: () => import('@/views/chancellery/lists/Resolutions.vue'),
                props: true
            },
            //Cards                          
            {
                path: 'inner/:id',
                name: 'ChancelleryInnerDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/InnerCard.vue'),
                props: true
            },
            {
                path: 'outgoing/:id',
                name: 'ChancelleryOutgoingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/OutgoingCard.vue'),
                props: true
            },
            {
                path: 'incoming/:id',
                name: 'ChancelleryIncomingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/IncomingCard.vue'),
                props: true
            },
            {
                path: 'resolution/:id',
                name: 'ChancelleryResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },
        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'chancellery');
            next();
        }
    },
    {
        path: '/correspondence',
        name: 'Correspondence',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence" ], requiredConfig: [ 8 ] } },
        component: () => import('@/views/correspondence/Main.vue'),
        redirect: { name: 'CorrespondenceInWork' },
        children: [
            {
                path: 'projects',
                name: 'CorrespondenceProjects',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/Projects.vue'),
                props: true
            },
            {
                path: 'in-work',
                name: 'CorrespondenceInWork',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/InWork.vue'),
                props: true
            },
            {
                path: 'handled',
                name: 'CorrespondenceHandled',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/Handled.vue'),
                props: true
            },
            {
                path: 'incomings',
                name: 'CorrespondenceIncomings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/lists/Incomings.vue'),
                props: true
            },
            {
                path: 'outgoings',
                name: 'CorrespondenceOutgoings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/lists/Outgoings.vue'),
                props: true
            },
            {
                path: 'inners',
                name: 'CorrespondenceInners',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/lists/Inners.vue'),
                props: true
            },
            {
                path: 'dirords',
                name: 'CorrespondenceDirords',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/lists/Dirords.vue'),
                props: true
            },
            {
                path: 'incoming-act-items',
                name: 'CorrespondenceIncomingActItems',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/lists/IncomingActItems.vue'),
                props: true
            },
            {
                path: 'ords',
                name: 'CorrespondenceOrds',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/lists/Ords.vue'),
                props: true
            },
            {
                path: 'inner-act-items',
                name: 'CorrespondenceInnerActItems',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/correspondence/lists/InnerActItems.vue'),
                props: true
            },
            {
                path: 'chancellery-incomings',
                name: 'CorrespondenceChancelleryIncomings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryIncomingDocumentsList"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/chancellery/lists/Incomings.vue'),
                props: true
            },
            {
                path: 'chancellery-outgoings',
                name: 'CorrespondenceChancelleryOutgoings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryOutcomingDocumentsList"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/chancellery/lists/Outgoings.vue'),
                props: true
            },
            {
                path: 'chancellery-inners',
                name: 'CorrespondenceChancelleryInners',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryInternalDocumentsList"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/chancellery/lists/Inners.vue'),
                props: true   
            },
            {
                path: 'chancellery-resolutions',
                name: 'CorrespondenceChancelleryResolutions',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["ChancelleryOutcomingDocumentsList", "CanSignChancellery"], requiredConfig: [ 8 ] } },
                component: () => import('@/views/chancellery/lists/Resolutions.vue'),
                props: true
            },
            {
                path: 'inner/:id',
                name: 'CorrespondenceInnerDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/InnerCard.vue'),
                props: true
            },
            {
                path: 'outgoing/:id',
                name: 'CorrespondenceOutgoingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/OutgoingCard.vue'),
                props: true
            },
            {
                path: 'outgoing/:id',
                name: 'CorrespondenceOutgoingDocumentCommon',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/CommonOutgoingCard.vue'),
                props: true
            },
            {
                path: 'incoming/:id',
                name: 'CorrespondenceIncomingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/documents/IncomingCard.vue'),
                props: true
            },
            {
                path: 'resolution/:id',
                name: 'CorrespondenceResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/Resolution.vue'),
                props: true
            },
            {
                path: 'act-item/:id',
                name: 'CorrespondenceActItem',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
                component: () => import('@/views/global/cards/ActItem.vue'),
                props: true
            },
        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'correspondence');
            next();
        }
    },
    {
        path: '/eds-light',
        name: 'EdsLight',
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ], access: { permissions: [ "DocumentsCorrespondence" ], requiredConfig: [ 64 ] } },
        component: () => import('@/views/eds-light/Main.vue'),
        redirect: { name: 'EdsLightInWork' },
        children: [
            {
                path: 'incomings',
                name: 'EdsLightIncomings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/Incomings/Incomings.vue'),
            },
            {
                path: 'outgoings',
                name: 'EdsLightOutgoings',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/Outgoings/Outgoings.vue'),
            },
            {
                path: 'projects',
                name: 'EdsLightProjects',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/Projects.vue'),
            },
            {
                path: 'inners',
                name: 'EdsLightInners',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/Inners.vue'),
            },
            {
                path: 'in-work',
                name: 'EdsLightInWork',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/InWork.vue'),
            },
            {
                path: 'handled',
                name: 'EdsLightHandled',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { permissions: ["DocumentsCorrespondence"], requiredConfig: [ 64 ] } },
                component: () => import('@/views/correspondence/Handled.vue'),
                props: true
            },
            {
                path: 'inner/:id',
                name: 'EdsLightInnerDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/documents/InnerCard.vue'),
                props: true
            },
            {
                path: 'outgoing/:id',
                name: 'EdsLightOutgoingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/documents/OutgoingCard.vue'),
                props: true
            },
            {
                path: 'incoming/:id',
                name: 'EdsLightIncomingDocument',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/documents/IncomingCard.vue'),
                props: true
            },
            {
                path: 'resolution/:id',
                name: 'EdsLightResolution',
                meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ], access: { requiredConfig: [ 64 ] } },
                component: () => import('@/views/eds-light/documents/ResolutionCard.vue'),
                props: true
            },
        ],
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', 'eds-light');
            next();
        }
    },
    {
        path: "*",
        meta: { layout: 'main-layout', authRequired: true, config: [ sys.Configurations.C4, sys.Configurations.C5 ] },
        component: () => import("@/views/global/NotFound.vue"),
        beforeEnter: (to, from, next) => {
            store.commit('SET_CURRENT_MODULE', null);
            next();
        }
    },
];

const router = new VueRouter({
    routes: routes.filter(route => route.meta.config.includes(process.env.VUE_APP_CONFIGURATION)),
});

const routeNameOpenTypes = {
    ChancelleryInnerDocument: "Chancellery|Documents.Document", 
    ChancelleryIncomingDocument: "Chancellery|Documents.Document",
    ChancelleryOutgoingDocument: "Chancellery|Documents.Document",
    ChancelleryResolution: "Documents|Orders|CitizenStatements.Resolution",

    CorrespondenceInnerDocument: "Chancellery|Documents.Document", 
    CorrespondenceIncomingDocument: "Chancellery|Documents.Document",
    CorrespondenceOutgoingDocument: "Chancellery|Documents.Document",
    CorrespondenceResolution: "Documents|Orders|CitizenStatements.Resolution",
    CorrespondenceActItem: "Documents|Orders|CitizenStatements.Resolution",

    EdsLightInnerDocument: "Chancellery|Documents.Document", 
    EdsLightIncomingDocument: "Chancellery|Documents.Document",
    EdsLightOutgoingDocument: "Chancellery|Documents.Document",
    EdsLightResolution: "Documents|Orders|CitizenStatements.Resolution",

    ArchiveIncomingDocument: "Chancellery|Documents.Document",
    ArchiveOutgoingDocument: "Chancellery|Documents.Document",
    ArchiveOutgoingDocumentCommon: "Chancellery|Documents.Document",
    ArchiveInnerDocument: "Chancellery|Documents.Document",    
    ArchiveResolution: "Documents|Orders|CitizenStatements.Resolution",
    ArchiveActItem: "Documents|Orders|CitizenStatements.Resolution",
    ArchiveEuolStatement: "CitizenStatements.EuolStatement",
    ArchiveEuolAnswer: "Chancellery|Documents.Document",
    ArchiveEuolAnswerCommon: "Chancellery|Documents.Document",
    ArchiveEuolResolution: "Documents|Orders|CitizenStatements.Resolution",

    Meeting: "None.Meeting",

    ProtocolDocument: "Chancellery|Documents.Document",
    ProtocolActItemDocument: "Chancellery|Documents.Document",
    ProtocolActItemDocumentOnExecution: "Chancellery|Documents.Document",
    ProtocolActItemDocumentOnControl: "Chancellery|Documents.Document",
    ProtocolActItemDocumentOnAdjustment: "Chancellery|Documents.Document",
    ProtocolActItemDocumentOnExecutionControl: "Chancellery|Documents.Document",
    ProtocolResolution: "Documents|Orders|CitizenStatements.Resolution",
    ProtocolActItemResolution: "Documents|Orders|CitizenStatements.Resolution",
    ProtocolActItemResolutionOnExecution: "Documents|Orders|CitizenStatements.Resolution",
    ProtocolActItemResolutionOnControl: "Documents|Orders|CitizenStatements.Resolution",
    ProtocolActItemResolutionOnAdjustment: "Documents|Orders|CitizenStatements.Resolution",
    ProtocolActItemResolutionOnExecutionControl: "Documents|Orders|CitizenStatements.Resolution",

    ReportQuery: "Documents.ReportGeneration.Query",
    MyCreatedReportResolution: "Documents|Orders|CitizenStatements.Resolution",
    MyExecutionReportResolution: "Documents|Orders|CitizenStatements.Resolution",
    ReportInteraction: "Documents.ReportGeneration.Form",
    AllMyReportReportResolution: "Documents|Orders|CitizenStatements.Resolution"
};

const shouldUpdateSource = function(route) {
    return route.params?.fromSource !== true && routeNameOpenTypes[route.name] !== undefined;
};

router.beforeEach(async (to, from, next) => {    
    
    store.dispatch('setOverlayVisible', { visible: true, text: `Загрузка` });

    if (!store.state.global.auth.authorized) {
        if (to.matched.some((record) => record.meta.authRequired)) {
            next('/login');
        }
        else {
            next();
        }
    }
    else if (to.name === 'Login') {
        next('/');
    }
    else {
        let restrictions = to.meta?.access;

        if (restrictions != null && !router.app.$validatePermission(restrictions)) {
            next(false);
            store.dispatch('setOverlayVisible', { visible: false });
            router.app.$notify.alert("Права пользователя не соответствуют требуемым!");
        }
        else {
            store.commit('documents/toolbar/SET_MENU', { menu: [] });
            
            next();

            if (shouldUpdateSource(to)) {
                if (to.name == "ReportInteraction"){
                    await store.dispatch('global/actionsource/loadDataSource',
                        { 
                            id: "ShowReportForm",
                            openType: "Documents.ReportGeneration.Form",
                            params:  to.params,
                        }, { root: true });
                }
                else {
                    await store.dispatch('global/actionsource/loadDataSource',
                        { 
                            id: to.params?.id,
                            openType: routeNameOpenTypes[to.name],
                            common: to.query?.common === "true" && store.getters['global/auth/isCommonServiceEnabled']
                        }, { root: true });
                }
            }
        }
    }
});

router.afterEach(() => {
    store.dispatch('setOverlayVisible', { visible: false });
});

export default router;
