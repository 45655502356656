export default {
    namespaced: true,
    state: () => ({
        tableOptions:
        {
            page: 1,
            itemsPerPage : 25,
            sortBy : [ "fullName" ],
            sortDesc : [ false ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : true
        },

        loadWhenMounted: false,
        searchType: 0,

        filterFio: {
            surname: "",
            name: "",
            patronymic: "",
            birthDateFrom: null,
            region: null
        },
        filterIin: {
            iin: "",
            region: null
        },
        filterAddress: {
            region: null,
            city: null,
            street: null,
            house: null
        }
    }),
    mutations: {
        SET_TABLE_OPTION(state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE(state, payload) {
            state.tableOptions.page = payload;
        },
        SET_LOAD_WHEN_MOUNTED(state, payload) {
            state.loadWhenMounted = payload;
        },
        SET_SEARCH_TYPE(state, payload) {
            state.searchType = payload;
        },
        SET_FILTER_FIO(state, payload) {
            state.filterFio = payload;
        },
        SET_FILTER_IIN(state, payload) {
            state.filterIin = payload;
        },
        SET_FILTER_ADDRESS(state, payload) {
            state.filterAddress = payload;
        }
    },
    getters: {
        getTableOptions: (s) => s.tableOptions,
        isLoadWhenMounted: (s) => s.loadWhenMounted,
        getSearchType: (s) => s.searchType,
        getFilterFio: (s) => s.filterFio,
        getFilterIin: (s) => s.filterIin,
        getFilterAddress: (s) => s.filterAddress
    }
}