import incomings from './incomings';
import outgoings from './outgoings';
import inners from './inners';
import resolutions from './resolutions';

const chancellery = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        incomings,
        outgoings,
        inners,
        resolutions
    }
}

export default chancellery;