import i18n from '@/i18n';
import sys from '@/services/system';

const importCertificate = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        password: null,

        profile: null,
        profiles: [],
        
        distinguishedName: null,
        distinguishedNames: [],
        distinguishedNamesLoading: false,

        ariadnaGrant: null,

        pendingData: false,

        resolve: null,
        reject: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_PASSWORD(state, payload) {
            state.password = payload;
        },
        SET_PROFILE(state, payload) {
            state.profile = payload;
        },
        SET_PROFILES(state, payload) {
            state.profiles = payload;
        },
        SET_DISTINGUISHED_NAME(state, payload) {
            state.distinguishedName = payload;
        },
        SET_DISTINGUISHED_NAMES(state, payload) {
            state.distinguishedNames = payload;
        },
        SET_DISTINGUISHED_NAMES_LOADING(state, payload) {
            state.distinguishedNamesLoading = payload;
        },
        SET_ARIADNA_GRANT(state, payload) {
            state.ariadnaGrant = payload;
        },
        SET_PENDING_DATA(state, payload) {
            state.pendingData = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
    },
    actions: {
        async open({ commit, dispatch, state }, { password }) {
            commit('SET_PASSWORD', password);

            commit('SET_ARIADNA_GRANT', null);
            commit('SET_PROFILES', []);
            commit('SET_PROFILE', null);
            commit('SET_DISTINGUISHED_NAMES', []);
            commit('SET_DISTINGUISHED_NAME', null);

            commit('SET_VISIBLE', true);
            commit('SET_LOADING', true);

            let handshake = await dispatch('global/signing/fetchTumarHandshake', { password: state.password }, { root: true });

            if (handshake.success === false) {
                commit('SET_VISIBLE', false);
                return Promise.reject({ message: handshake.message });                
            }

            let profilesResponse = await dispatch('global/signing/fetchTumarProfiles', { password: state.password }, { root: true });

            if (profilesResponse.success === false) {
                commit('SET_VISIBLE', false);
                return Promise.reject({ message: profilesResponse.message });
            }

            commit('SET_LOADING', false);

            commit('SET_PROFILES', profilesResponse.Profiles.map(x => ({ id: x, Value: x.replace("profile://", "") })));
            commit('SET_ARIADNA_GRANT', profilesResponse.Grant);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state, dispatch }) {

            commit('SET_PENDING_DATA', true);
            let certInfoResponse = await dispatch('global/signing/fetchTumarCertificateInfoRequest', { grant: state.ariadnaGrant, profile: state.profile, dn: state.distinguishedName }, { root: true });
            commit('SET_PENDING_DATA', false);
            commit('SET_VISIBLE', false);
            
            console.log(certInfoResponse.CertificateInfo);

            if (certInfoResponse.success === false)
                state.reject({ message: certInfoResponse.message });
            else
                state.resolve({ dn: state.distinguishedName, profile: state.profile, certificate: certInfoResponse.CertificateInfo, certificateRaw: certInfoResponse.CertificateBase64 });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: i18n.t("Импорт_прерван") });
        },
        async onProfileSelect({ commit, dispatch, state }, profile) {
            commit('SET_PROFILE', profile);
            commit('SET_DISTINGUISHED_NAME', null);
            commit('SET_DISTINGUISHED_NAMES_LOADING', true);
            let distinguishedNamesResponse = await dispatch('global/signing/fetchTumarProfileDistinguishedNames', { grant: state.ariadnaGrant, profile: state.profile }, { root: true });
            commit('SET_DISTINGUISHED_NAMES_LOADING', false);

            if (distinguishedNamesResponse.success === false) {
                commit('SET_DISTINGUISHED_NAMES', []);
                commit('SET_DISTINGUISHED_NAME', null);
                let handshake = await dispatch('global/signing/fetchTumarHandshake', { password: state.password }, { root: true });

                if (handshake.success === false) {
                    commit('SET_VISIBLE', false);
                    state.reject({ message: distinguishedNamesResponse.message });
                    return;
                }

                commit('SET_ARIADNA_GRANT', handshake.Grant);
                this._vm.$notify.alert(distinguishedNamesResponse.message);
                return;
            }
            
            commit('SET_DISTINGUISHED_NAMES', distinguishedNamesResponse.DistinguishedNames.map(x => ({ id: x, Value: sys.getNameFromDN(x) })));
            commit('SET_ARIADNA_GRANT', distinguishedNamesResponse.Grant);
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        isPendingData: (s) => s.pendingData,
        getProfile: (s) => s.profile,
        getProfiles: (s) => s.profiles,
        getDistinguishedName: (s) => s.distinguishedName,
        getDistinguishedNames: (s) => s.distinguishedNames,
        isDistinguishedNamesLoading: (s) => s.distinguishedNamesLoading
    }
}

export default importCertificate;