import selectAddressChip from "@/store/saylau/components/store/selectAddressChip";
const components = {
    namespaced: true,
    state: {

    },
    actions: {

    },
    modules: {
        selectAddressChip
    }
}

export default components;