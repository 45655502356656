import Vue from 'vue'
import VueI18n from 'vue-i18n'
import kk from '@/locales/kk.json'
import ru from '@/locales/ru.json'

Vue.use(VueI18n)

let messages = {
    kk,
    ru,
}

const i18n = new VueI18n({
    locale: localStorage.getItem('app-locale') ?? 'kk',
    fallbackLocale: 'kk',
    messages,
    silentTranslationWarn: true
});

export default i18n;