import { httpAPI } from "@/api/httpAPI";
import router from '@/router';
import store from '@/store';
import _ from 'lodash';
import i18n from '@/i18n';
import sys from '@/services/system';

const referencesMapper = {
    'OutgoingDocument': [1001, 1000, 1024],
    'InnerDocument': [1001, 1000, 1024, 1027, 1035],
    'IncomingDocument': [1001, 1000, 1024, 1018],
    'ProtocolDocument': [1001, 1000, 1024],
    'EuolStatement': [1024, 5205, 5216, 1003, 5204, 5206, 5215, 1036, 1040, 5203],
    'Resolution': [1004],
    'ActItem': [1004],
    'Order': [1008],
    'Nomenclature': [1019, 1020],
    'NPADocument': [1001, 1000, 1024],
}

const executionTreeMapper = [
    'Resolution',
    'ActItem',
    'Order',
    'IQalaOrder',
    'ReportResolution'
]

const needLoadControlInfo = function (document) {

    if (!document)
        return false;

    let type = document.__type.split(':')[0];

    if (type === 'InnerDocument' && !document.is_new_record && ( document.RegState == 1 || document.RegState == 4) && document.Card.TypeC4 == 1)
        return true;

    if (type === 'IncomingDocument' && !document.is_new_record)
        return true;

    if (type === 'ProtocolDocument' && !document.is_new_record)
        return true;        

    if (type === 'IQalaStatement' && !document.is_new_record)
        return true;

    return false;
}

//для уточнение маршрута, если тип документа имеет несколько представлений
const getRouteNameByFormId = (formId) => {
    switch(formId)
    {
        case '0201121':
        case '0201122':
            return 'OutgoingDocumentCommon';
        case '0201131':
        case '0201132':
            return 'NPADocument';
        case '18010101':
        case '18010102':
            return 'ReportQuery';
        default:
            return null;
    }
};

function getRouterParam(data)  {
    let type = data.Data.Object.__type.split(':')?.[0];
    let id = data.Data.Object.id;
    let common = data?.Data?.Object?.IsCommon ?? false;

    if (type === "DocumentContainer") {
        type = data.Data.Object.Document.__type?.split(':')?.[0];
        id = data.Data.Object.Document.id;
        common = data?.Data?.Object?.Document?.IsCommon ?? false;
    }
    else if (type === "ResolutionContainer") {
        type = data.Data.Object.Resolution.__type ? data.Data.Object.Resolution.__type.split(':')[0] : 'Resolution';
        id = data?.Data?.Object?.Resolution?.id;
        common = data?.Data?.Object?.Resolution?.IsCommon ?? false;
    }

    let toRouteName = getRouteNameByFormId(data.Data.FormId) ?? type;
    let module = store.getters['getCurrentModule'];

    if (module === "archive") {

        if (toRouteName === "OutgoingDocument")
            if (data.Data.Object.Document.Card.IsEuolAnswer === true)
                toRouteName = "EuolAnswer";

        if (toRouteName === "OutgoingDocumentCommon")
            if (data.Data.Object.Document.Card.IsEuolAnswer === true)
                toRouteName = "EuolAnswerCommon";

        if (toRouteName === "Resolution")
            if (data.Data.Object.Resolution.Document.__type === "EuolStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects")
                toRouteName = "EuolResolution";

        toRouteName = "Archive" + toRouteName;
        
    } 
    
    /*else if (module === 'chancellery') {
        if (toRouteName === "ProtocolDocument"){
            if (data.Data.Object.Document.Card.TypeC4 == 3)
                toRouteName = "ProtocolActItemDocument";
        }
        else
            toRouteName = "Chancellery" + toRouteName;
    }*/

    else if (module === 'correspondence') {
        if (toRouteName === "ProtocolDocument"){
            if (data.Data.Object.Document.Card.TypeC4 == 3)
                toRouteName = "ProtocolActItemDocument";
        }
        else 
            toRouteName = "Correspondence" + toRouteName;
    }

    else if (module === 'eds-light' && store?.getters?.['global/auth/getUserConfiguration'] == 64){
        toRouteName = "EdsLight" + toRouteName;
    }

    else if (module === "orders" || module === 'meetings'){
        let collection = store.getters['orders/getCurrentCollection'];
        if (toRouteName === "ProtocolDocument"){
            if (data.Data.Object.Document.Card.TypeC4 == 3){
                if (collection === "OnExecutionList")
                    toRouteName = "ProtocolActItemDocumentOnExecution";
                else if (collection === "OnControl")
                    toRouteName = "ProtocolActItemDocumentOnControl";
                else if (collection === "OnAdjustmentList")
                    toRouteName = "ProtocolActItemDocumentOnAdjustment";
                else if (collection === "OnExecutionControl")
                    toRouteName = "ProtocolActItemDocumentOnExecutionControl";
                else
                    toRouteName = "ProtocolActItemDocument";
            }
            else if (module === "meetings"){
                toRouteName = "MeetingProtocolDocument";
            }
        }
        if (toRouteName === "Resolution"){
            let mainDocType = data.Data.Object.Resolution?.Document?.__type.split(':')[0];
            if (mainDocType === "ProtocolDocument"){
                if (data.Data.Object.Resolution.Document?.Card.TypeC4 == 3){
                    if (collection === "OnExecutionList")
                        return { name: "ProtocolActItemResolutionOnExecution", query: { common }, params: { actitemid: data.Data.Object.Resolution.Document.id, id, fromSource: true } };
                    else if (collection === "OnControl")
                        return { name: "ProtocolActItemResolutionOnControl", query: { common }, params: { actitemid: data.Data.Object.Resolution.Document.id, id, fromSource: true } };
                    else if (collection === "OnAdjustmentList")
                        return { name: "ProtocolActItemResolutionOnAdjustment", query: { common }, params: { actitemid: data.Data.Object.Resolution.Document.id, id, fromSource: true } };
                    else if (collection === "OnExecutionControl")
                        return { name: "ProtocolActItemResolutionOnExecutionControl", query: { common }, params: { actitemid: data.Data.Object.Resolution.Document.id, id, fromSource: true } };
                    else 
                        return { name: "ProtocolActItemResolution", query: { common }, params: { actitemid: data.Data.Object.Resolution.Document.id, id, fromSource: true } };
                }
                else 
                    return { name: "ProtocolResolution", query: { common }, params: { protocolid: data.Data.Object.Resolution.Document.id, id, fromSource: true } };
            }
        }
    }
    else if (module ==="reportsgeneration"){
        if (toRouteName === "ReportResolution"){
            let collection = store.getters['reportsgeneration/getCurrentCollection'];
            toRouteName = `${collection}ReportResolution`;
        }
    }
    
    return { name: toRouteName, query: { common }, params: { id, fromSource: true } };
}

const updateControlInfo = async (getters, commit, id) => {

    let controlInfoResponse = await httpAPI({
        url: `api/references/controlinfo/${id}`,
        method: 'GET',
        headers: { 'isCommon': getters.isDataSourceCommon },
    });

    if (controlInfoResponse)
        commit('SET_CONTROL_INFO', controlInfoResponse.data.Payload);
}

const updateExecutionTree = async (getters, commit, id) => {
    let executiontreeResponse = await httpAPI({
        url: `api/actions/executiontree?id=${id}`,
        method: 'GET',
        headers: { 'isCommon': getters.isDataSourceCommon },
    });

    if (executiontreeResponse)
        commit('SET_EXECUTIONTREE', executiontreeResponse.data.Payload);
}

const setDataSourceAndMenu = async ( commit, dispatch, data) => {
    commit({ type: 'SET_DATASOURCE', datasource: data })
    commit('documents/toolbar/SET_MENU', { menu: data ? data.Data.Menu : [] }, { root: true });
    let module = store.getters['getCurrentModule'];
    if (module === "orders"){
        await dispatch('orders/updateCounter', null, { root: true });
    }
}

const actionsource = {
    namespaced: true,
    state: {
        openType: "",
        datasource: null,
        controlInfo: null,
        executiontree: null,
        activeExecuter: null,
        activeTabName: null,
        isMainExecuterGiveAnswer: false
    },
    mutations: {
        SET_ACTIVE_TAB_NAME(state, payload) {
            state.activeTabName = payload;
        },
        SET_OPENTYPE(state, payload) {
            state.openType = payload.openType;
        },
        SET_DATASOURCE(state, payload) {
            //this.commit("attachments/SET_DATASOURCE", { dataSource: [] });
            state.datasource = payload.datasource;

            if (payload.datasource) {
            //Зачем это? если мы при обновлении датасоурса загружаем новый документ, то в этом месте вкладка выполнит апдейт со старым Id документа, 
            //а потом еще раз? уже с правильным Id после выполнения маршрутизации. А если Документ тот же остался то апдейт похоже выполнится дважды
                this._vm.$eventBus.$emit('update-active-tab');
            }
            
        },
        SET_METADATA(state, payload) {
            state.datasource.Data.FormId = payload.formId;
            state.datasource.Data.Menu = payload.menu;
            state.datasource.Data.Action = payload.action;
        },
        SET_CONTROL_INFO(state, payload) {
            state.controlInfo = payload;
        },
        SET_EXECUTIONTREE(state, payload) {
            state.executiontree = payload;
            state.activeExecuter = null;
        },
        UPDATE_EXECUTIONTREE(state, payload) {
            this._vm.$_.set(state.executiontree, payload.property, payload.value)
        },
        UPDATE_DATASOURCE(state, payload) {

            if (this._vm.$_.has(state.datasource, 'Data.Object.Tasks[0].IsEdited')) {
                if (state.datasource.Data.Object.Tasks[0].IsEdited === false)
                    this._vm.$_.set(state.datasource, 'Data.Object.Tasks[0].IsEdited', true)
            }

            if (this._vm.$_.has(state.datasource, 'Data.Object.Resolution.Tasks[0].IsEdited')) {
                if (state.datasource.Data.Object.Resolution.Tasks[0].IsEdited === false)
                    this._vm.$_.set(state.datasource, 'Data.Object.Resolution.Tasks[0].IsEdited', true)
            }
            
            this._vm.$_.set(state.datasource, payload.property, payload.value)
        },
        SET_ACTIVE_EXECUTER(state, payload) {
            state.activeExecuter = payload;
        },
        SET_IS_MAIN_EXECUTER_GIVE_ANSWER(state, payload) {
            state.isMainExecuterGiveAnswer = payload;
        },
        UPDATE_PAGE_COUNTER(state, payload) {
            this._vm.$_.set(state.datasource, `Data.Object.Pages[${payload.index}].Count`, payload.value);
        },
    },
    actions: {
        updatePageCounter({ state, commit }, { tabId, increase }) {
            let pages = state.datasource?.Data?.Object?.Pages;

            if (!pages || !Array.isArray(pages))
                return;

            let page = pages.find(x => x.FormId === tabId);

            if (!page)
                return;

            let pageIndex = pages.indexOf(page);

            if (pageIndex < 0)
                return;

            commit('UPDATE_PAGE_COUNTER', { index: pageIndex, value: increase ? page.Count + 1 : page.Count - 1 });
        },
        setActiveTabName({commit}, activeTabName)
        {
            commit('SET_ACTIVE_TAB_NAME', activeTabName);
        },
        async loadDataSource({ dispatch, commit, getters/*, rootGetters*/ }, { id, openType, createType, navigate, useOverlay = true, common = false, params = null, routeMode = 'push' })
        {
            commit({ type: 'SET_OPENTYPE', openType: openType });

            if (useOverlay)
                commit({ type: 'SET_OVERLAY_VISIBLE', visible: true }, { root: true });            

            let response;

            if (id == null) {
                switch (createType) {

                    case "Resolution":
                        response = await httpAPI({
                            url: `api/tasks/addresolution?id=${getters.getDataSourceDocumentId}`,
                            method: 'GET',
                            headers: { 'isCommon': common },
                        });
                        break;

                    case "IQalaOrder":
                        response = await httpAPI({
                            url: `api/csc/requests/addiqalaorder?id=${getters.getDataSourceDocumentId}`,
                            method: 'GET',
                        });
                        break;
                    
                    case "ActItem":
                        response = await httpAPI({
                            url: `api/tasks/addactitem?id=${getters.getDataSourceDocumentId}`,
                            method: 'GET',
                        });
                        break;
                        
                    default:
                        response = await httpAPI({
                            url: `api/actions/new?type=${createType}`,
                            method: 'GET',
                            headers: { 'isCommon': common },
                        });
                        break;
                }
            }
            else if (id === "OpenLinkedPlaceholderId" && params && params.isLinkedDocument) {
                switch (params.linkType) {
                    // вызов осуществлен из компонента DocumentTypeChip (плашка документа, например на вкладке Связанные документы)
                    case "DirectLink":
                        response = await httpAPI({
                            url: `api/actions/openlinked`,
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'isCommon': common },
                            data: { Content : JSON.stringify({ linkId: params.linkId, sourceDocumentId: params.sourceDocumentId }) },
                        });
                        break;
                    // вызов открытия связи состоялся из компонента DocumentLink из вкладки Контроль (гиперссылка на документ)
                    case "NotDirectLink":
                        if (getters.getDataSourceDocumentId != null) {
                            response = await httpAPI({
                                url: `api/actions/openlinked`,
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'isCommon': common, 'version': "v2" },
                                data: { Content : JSON.stringify({ sourceDocumentId: params.sourceDocumentId ?? getters.getDataSourceDocumentId, linkedDocumentId: params.documentId }) },
                            });
                        }
                        break;
                    // вызов открытия связи состоялся из компонента DocumentLink из просмотра исполнения (гиперссылка на документ)
                    case "RTELink":
                        response = await httpAPI({
                            url: `api/actions/rtedocumentopen`,
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'isCommon': common },
                            data: { Content : JSON.stringify({ executionId: params.executionId, documentId: params.documentId }) },
                        });
                        break;

                    default:
                        break;
                }
            }
            else if (id === "ShowReportForm" && params) {
                let reportInteractionSM = localStorage.getItem('report-interaction:svod-mode');
                let reportInteractionAS = localStorage.getItem('report-interaction:auto-svod');
                let responseData = { documentId: params.queryId, executionId: params.executionId, svod: reportInteractionSM ?? false, autoSvod: reportInteractionAS ?? false };
                response = await httpAPI({
                    url: `api/actions/showreportform`,
                    method: 'POST',
                    data: { Content : JSON.stringify(responseData)},
                    headers: { 'Content-Type': 'application/json' },
                });
            }
            else {
                response = await httpAPI({
                    url: `api/actions/open?type=${openType}&id=${id}`,
                    method: 'GET',
                    headers: { 'isCommon': common },
                });
            }

            if (response) {       
                await setDataSourceAndMenu(commit, dispatch, null);
                //commit({ type: 'SET_DATASOURCE', datasource: null });

                if (navigate === true) {
                    let route = id === "ShowReportForm"
                        ? { name: "ReportInteraction", query: { common }, params: { queryId: params.queryId, executionId: params.executionId, fromSource: true } }
                        : getRouterParam(response.data.Payload);
                    if (routeMode == 'replace')
                        await router.replace(route);
                    else
                        await router.push(route);
                }
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);

                let refs = referencesMapper[getters.getDataSourceType];

                if (id == null && refs)
                    refs.forEach( async ref => await dispatch('documents/references/getReference', { id: ref }, { root: true }));
                
                //#region Обрабатываем данные для дерева исполнения в резолюциях / личных поручениях / пунтках
                commit('SET_EXECUTIONTREE', null);

                if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                    await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                //#endregion
                
                //#region Обрабатываем данные по контролю объекта

                commit('SET_CONTROL_INFO', null );

                if (needLoadControlInfo(response.data.Payload?.Data?.Object?.Document))               
                    await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);
                //#endregion

                //#region Для резолюции подменяем тип контроля в случае если резолюция периодическая
                if (response.data.Payload?.Data?.Object?.Resolution && (((response.data.Payload.Data?.AdditionalObjects?.[4] ?? false) && response.data.Payload.Data.Object.Resolution.Document.Card.Periodicity) ||
                    response.data.Payload.Data.Object.Resolution.Tasks[0].IsPeriodic)){
                    response.data.Payload.Data.Object.Resolution.Tasks[0].ControlType = {id:101, Value: 'Периодический_контроль'};
                }
                //#endregion
            }
            else {
                if (navigate !== true) {
                    //commit({ type: 'SET_DATASOURCE', datasource: null });
                    await setDataSourceAndMenu(commit, dispatch, null);
                }
            }

            let module = store.getters['getCurrentModule'];
            if (module === "correspondence"){
                this._vm.$eventBus.$emit('update_correspondents_counters');
            }
            else if (module === 'eds-light'){
                this._vm.$eventBus.$emit('update_edslight_counters');
            }
            if (useOverlay)
                commit({ type: 'SET_OVERLAY_VISIBLE', visible: false }, { root: true });

        },
        updateDataSource({ commit }, data) {
            commit('UPDATE_DATASOURCE', data);
        },
        updateExecutionTree({ commit }, data) {
            commit('UPDATE_EXECUTIONTREE', data);
        },
        async Save ({ commit, dispatch, getters }, { button }) {
            commit({ type: 'SET_OVERLAY_TEXT', overlayText: "Сохранение..." }, { root: true });
            commit({ type: 'SET_OVERLAY_VISIBLE', visible: true }, { root: true });

            let entity = JSON.parse(JSON.stringify(getters.getDataSourceEntity));
            let hasType = _.has(entity, '__type');

            if (!hasType)
                entity = Object.assign({ __type : getters.getDataSourceFullType }, entity);

            let saveResponse;
            
            if (button.Type === 'Orders.Order')
            {
                saveResponse = await httpAPI({
                    url: `api/actions/saveorder`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ order: entity, registerParam: null }) },
                });
            }
            else if (button.Type === 'Documents.NPA.Document' || button.Type === 'Documents.ReportGeneration.Query')
            {
                saveResponse = await httpAPI({
                    url: `api/actions/save?type=${button.Type}`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'isCommon': true },
                    data: { Content : JSON.stringify({ parent: entity, childrens: null }) },
                });
            }
            else
            {
                saveResponse = await httpAPI({
                    url: `api/actions/save?type=${button.Type}`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'isCommon': ["OutgoingDocument","InnerDocument","ProtocolDocument"].includes(getters.getDataSourceType) && getters.isDataSourceCommon },
                    data: { Content : JSON.stringify({ parent: entity, childrens: null }) },
                });
            }

            if (saveResponse) {
                
                commit({ type: 'SET_OPENTYPE', openType: button.Type });
                //#region Для резолюции подменяем тип контроля в случае если резолюция периодическая
                if (saveResponse.data.Payload.Data.Object.Resolution && (((saveResponse.data.Payload.Data?.AdditionalObjects?.[4] ?? false) && saveResponse.data.Payload.Data.Object.Resolution.Document.Card.Periodicity) ||
                    saveResponse.data.Payload.Data.Object.Resolution.Tasks[0].IsPeriodic)){
                    saveResponse.data.Payload.Data.Object.Resolution.Tasks[0].ControlType = {id:101, Value: 'Периодический_контроль'};
                }
                //#endregion

                dispatch('updateDataSource', { property: 'Data.Object', value: saveResponse.data.Payload.Data.Object });
                
                this._vm.$notify.success(saveResponse.data.Payload?.Message ?? saveResponse.data.message);
                if (button.Type === 'Documents.ReportGeneration.Query'){
                    await dispatch('reportsgeneration/updateCounter', {}, { root: true })
                }
            }           
            
            commit({ type: 'SET_OVERLAY_VISIBLE', visible: false }, { root: true });
            commit({ type: 'SET_OVERLAY_TEXT', overlayText: "Загрузка..." }, { root: true });           
        },
        async SaveAndClose ({ commit, dispatch, getters }, { button }) {
            commit({ type: 'SET_OVERLAY_TEXT', overlayText: "Сохранение..." }, { root: true });
            commit({ type: 'SET_OVERLAY_VISIBLE', visible: true }, { root: true });

            let entity = JSON.parse(JSON.stringify(getters.getDataSourceEntity));
            let hasType = _.has(entity, '__type');

            if (!hasType)
                entity = Object.assign({ __type : getters.getDataSourceFullType }, entity);

            let saveResponse;
            
            if (button.Type === 'Orders.Order')
            {
                saveResponse = await httpAPI({
                    url: `api/actions/saveandcloseorder`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ order: entity, registerParam: null }) },
                });
            }
            else if (button.Type === 'Documents.NPA.Document' || button.Type === 'Documents.ReportGeneration.Query')
            {
                saveResponse = await httpAPI({
                    url: `api/actions/saveandclose?type=${button.Type}`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'isCommon': true },
                    data: { Content : JSON.stringify({ parent: entity, childrens: null }) },
                });
            }
            else
            {
                saveResponse = await httpAPI({
                    url: `api/actions/saveandclose?type=${button.Type}`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'isCommon': ["OutgoingDocument","InnerDocument","ProtocolDocument"].includes(getters.getDataSourceType) && getters.isDataSourceCommon },
                    data: { Content : JSON.stringify({ parent: entity, childrens: null }) },
                });
            }

            if (saveResponse) {
                
                commit({ type: 'SET_OPENTYPE', openType: button.Type });
                await setDataSourceAndMenu(commit, dispatch, null);
                //commit({ type: 'SET_DATASOURCE', datasource: null });
                //#region Для резолюции подменяем тип контроля в случае если резолюция периодическая
                if (saveResponse.data.Payload.Data.Object.Resolution && (((saveResponse.data.Payload.Data?.AdditionalObjects?.[4] ?? false) && saveResponse.data.Payload.Data.Object.Resolution.Document.Card.Periodicity) ||
                    saveResponse.data.Payload.Data.Object.Resolution.Tasks[0].IsPeriodic)){
                    saveResponse.data.Payload.Data.Object.Resolution.Tasks[0].ControlType = {id:101, Value: 'Периодический_контроль'};
                }
                //#endregion
                await setDataSourceAndMenu(commit, dispatch, saveResponse.data.Payload);
                //commit({ type: 'SET_DATASOURCE', datasource: saveResponse.data.Payload });
                //commit('documents/toolbar/SET_MENU', {menu: saveResponse.data.Payload.Data.Menu }, { root: true });

                //#region Обрабатываем данные для дерева исполнения в резолюциях / личных поручениях / пунтках
                commit('SET_EXECUTIONTREE', null);

                if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                    await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                //#endregion

                //#region Обрабатываем данные по контролю объекта

                commit('SET_CONTROL_INFO', null );

                if (needLoadControlInfo(saveResponse.data.Payload.Data.Object.Document))
                    await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);
                //#endregion
                
                this._vm.$notify.success(saveResponse.data.Payload?.Message ?? saveResponse.data.message);
                if (button.Type === 'Documents.ReportGeneration.Query'){
                    await dispatch('reportsgeneration/updateCounter', {}, { root: true })
                }
            }           
            
            commit({ type: 'SET_OVERLAY_VISIBLE', visible: false }, { root: true });
            commit({ type: 'SET_OVERLAY_TEXT', overlayText: "Загрузка..." }, { root: true });           
        },
        async Edit ({ commit, dispatch, getters }, { button }) {
            commit({ type: 'SET_OVERLAY_TEXT', overlayText: "Переход_в_режим_редактирования..." }, { root: true });
            commit({ type: 'SET_OVERLAY_VISIBLE', visible: true }, { root: true });

            let response = await httpAPI({
                url: `api/actions/edit?type=${button.Type}&id=${getters.getDataSourceIdentifier}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon }
            });

            if (response) {

                let refs = referencesMapper[getters.getDataSourceType];

                if (refs)
                    refs.forEach( async ref => await dispatch('documents/references/getReference', { id: ref }, { root: true }));
                
                commit({ type: 'SET_METADATA', ...response.data.Payload });
                commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Menu }, { root: true });
            }

            commit({ type: 'SET_OVERLAY_VISIBLE', visible: false }, { root: true });
            commit({ type: 'SET_OVERLAY_TEXT', overlayText: "Загрузка..." }, { root: true });
        },
        async DeleteDocument({ commit, dispatch, getters }, { button }) {
            this._vm.$notify.confirm(
                i18n.t('Удалить_документ'),
                async () => {
                    dispatch('setOverlayVisible', { visible: true, text: `Документ_удаляется...` }, { root: true });

                    let deleteResponse = await httpAPI({
                        url: `api/actions/delete?type=${button.Type}&id=${getters.getDataSourceIdentifier}`,
                        method: 'DELETE',
                        headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                    });

                    if (deleteResponse) {
                        if (getters.getDataSourceType === 'ReportQuery'){
                            await router.go(-1);
                        }
                        else {

                            var openResponce = await httpAPI({
                                url: `api/actions/open?type=${button.Type}&id=${getters.getDataSourceIdentifier}`,
                                method: 'GET',
                                headers: { 'isCommon': getters.isDataSourceCommon }
                            });

                            if (openResponce){
                                await setDataSourceAndMenu(commit, dispatch, openResponce.data.Payload);
                                //commit({type: 'SET_DATASOURCE', datasource: openResponce.data.Payload });
                                //commit('documents/toolbar/SET_MENU', {menu: openResponce.data.Payload.Data.Menu }, { root: true });
                            }
                        }
                        this._vm.$notify.success(deleteResponse.data.Payload.Message);
                    }

                    dispatch('setOverlayVisible', { visible: false }, { root: true });
                }
            );
        },
        async Restore({ commit, dispatch, getters }) {
            this._vm.$notify.confirm(
                i18n.t('Вы_действительно_хотите_восстановить_документ_вопрос'),
                async () => {
                    dispatch('setOverlayVisible', { visible: true, text: `Документ_восстанавливается...` }, { root: true });

                    let restoreResponse = await httpAPI({
                        url: `api/actions/restore?id=${getters.getDataSourceIdentifier}`,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                    });

                    dispatch('setOverlayVisible', { visible: false }, { root: true });

                    if (restoreResponse) {
                        await setDataSourceAndMenu(commit, dispatch, restoreResponse.data.Payload);
                        //commit({type: 'SET_DATASOURCE', datasource: restoreResponse.data.Payload });
                        //commit('documents/toolbar/SET_MENU', {menu: restoreResponse.data.Payload.Data.Menu }, { root: true });
                        this._vm.$notify.success(restoreResponse.data.Payload.Message);
                    }
                }
            );
        },
        async AddFile({ getters }) {
            this._vm.$eventBus.$emit('on-input-file', getters.getDataSourceIdentifier);
        },
        async AddFileToComment({ getters }) {
            this._vm.$eventBus.$emit('on-input-file-to-comment', getters.getDataSourceIdentifier);
        },
        async updateDocument({state, commit, dispatch, getters}) {
            commit({ type: 'SET_OVERLAY_VISIBLE', visible: true }, { root: true });            
            let openType;

            switch (state.openType) {
                case 'Documents|Orders|CitizenStatements.ResolutionTask':
                    openType = getters.getDataSourceType === 'Order' ? 'Orders.Order' : 'Documents|Orders|CitizenStatements.Resolution'
                    break;

                default:
                    openType = state.openType
                    break;
            }

            var response = await httpAPI({
                url: `api/actions/open?type=${openType}&id=${getters.getDataSourceIdentifier}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon },
            });

            if (response) {
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                //commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu }, { root: true });

                //#region Обрабатываем данные для дерева исполнения в резолюциях / личных поручениях / пунтках
                commit('SET_EXECUTIONTREE', null);

                if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                    await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                //#endregion

                //#region Обрабатываем данные по контролю объекта

                commit('SET_CONTROL_INFO', null );

                if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                    await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);
                //#endregion
            }

            commit({ type: 'SET_OVERLAY_VISIBLE', visible: false }, { root: true });
        },
        async SendToInspect({ state, commit, dispatch, getters, rootGetters }) {
            try
            {
                let enableSelfSelect = process.env.VUE_APP_CONFIGURATION == sys.Configurations.C5 && getters.getDataSourceType === 'IQalaStatement';
                let self = enableSelfSelect ? [] : [ rootGetters['global/auth/getUserInfo'].workplaceId ];
                let implicitExclude = self.concat(state.datasource.Data.Object.Document.Card.Recipients?.map(x => x.Id) ?? []);

                let selectMemberParams =  { 
                    title: "Добавить_сотрудников",
                    includeInner: true,
                    includeExecutersGroups: true,
                    multiple: true,
                    selected: [],
                    implicitExclude,
                }

                let members = await dispatch('global/dialogs/selectMembersNew/open', selectMemberParams, { root: true });                
                dispatch('setOverlayVisible', { visible: true, text: `Документ_отправляется...` }, { root: true });

                var response = await httpAPI({
                    url: `api/actions/sendtoinspect`,
                    method: 'POST',
                    headers: {'isCommon': getters.isDataSourceCommon },
                    data: { Content : JSON.stringify({ id: state.datasource.Data.Object.Document.id, recipientWorkPlaceIds: members.map(x => x.workplaceId) }) },
                });

                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu }, { root: true });
                    this._vm.$notify.success(response.data.Payload.Message);
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async MakeDocumentSection({ dispatch, state, commit, getters}) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            var response = await httpAPI({
                url: `api/actions/makesection/${state.datasource.Data.Object.Document.id}`,
                method: 'GET',
                headers: {'isCommon': getters.isDataSourceCommon }
            });

            if (response) {
                await setDataSourceAndMenu(commit, dispatch, null);
                //commit({ type: 'SET_DATASOURCE', datasource: null });
                let refs = referencesMapper[response.data?.Payload?.Data.Object?.Document?.__type?.split(':')?.[0]];

                if (refs)
                    refs.forEach( async ref => await dispatch('documents/references/getReference', { id: ref }, { root: true }));
                
                await router.push(getRouterParam(response.data.Payload));
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                //commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu }, { root: true });
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async AddDocumentLink({ dispatch, getters, commit }) {
            try
            {
                let linkData = await dispatch('global/dialogs/addDocumentLink/open', getters.getDataSourceType, { root: true });
                
                let content =
                {
                    id: getters.getDataSourceDocumentId,
                    linkType: linkData.type,
                    secondDocumentId: linkData.document.id
                };

                dispatch('setOverlayVisible', { visible: true }, { root: true });
                var response = await httpAPI({
                    url: `api/actions/addlink`,
                    method: 'POST',
                    data: { Content : JSON.stringify(content) },
                    headers: { 'isCommon': getters.isDataSourceCommon },
                });
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });
                    
                    this._vm.$eventBus.$emit('update-linked_docs', { });

                    this._vm.$notify.success(response.data.Payload.Message);
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async deleteDocumentLink({ dispatch, commit, getters }, linkId) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            let response = await httpAPI({
                url: `api/actions/deletedocumentlink?id=${getters.getDataSourceIdentifier}&linkId=${linkId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });
            
            if (response) {
                commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                
                this._vm.$eventBus.$emit('update-linked_docs', { });

                this._vm.$notify.success(response.data.Payload.Message);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async VerifyNotify({ dispatch, getters }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            let response = await httpAPI({
                url: `api/actions/verifynotifies/${getters.getDataSourceIdentifier}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon },
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                this._vm.$notify.success(response.data.Payload.Message);
                this._vm.$eventBus.$emit('set-active-tab', { FormId: "0202130" });
            }
        },
        async Verify({ dispatch, getters }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            let response = await httpAPI({
                url: `api/actions/verify/${getters.getDataSourceIdentifier}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon },
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                let notCheckedCount = response.data.Payload.Data.Object.Data.filter(i=> i[5] == -1).length

                if (notCheckedCount) { 
                   if (notCheckedCount == response.data.Payload.Data.Object.Data.length)
                        this._vm.$notify.alert(i18n.t('Ошибка_при_проверке_ЭЦП'));
                   else
                        this._vm.$notify.warning(i18n.t('Некоторые_ЭЦП_проверить_неудалось'));
                }
                else {
                    this._vm.$notify.success(response.data.Payload.Message);
                }

                this._vm.$eventBus.$emit('set-active-tab', { FormId: "0202050" });
            }
        },
        async ChangeNomenclature({ dispatch, getters, commit }) {
            try
            {
                let nomenclature = await dispatch('global/dialogs/selectNomenclature/open', null, { root: true });

                let data =
                {
                    id: getters.getDataSourceDocumentId,
                    nomenclatureId: nomenclature[0],
                };
                
                dispatch('setOverlayVisible', { visible: true }, { root: true });
                var response = await httpAPI({
                    url: `api/actions/changenomenclature`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon },
                    data: { Content: JSON.stringify(data) },
                });
                
                if (response) {
                    commit({ type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    this._vm.$notify.success(response.data.Payload.Message);
                }

                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async CreateNewVersion({ commit, dispatch, getters }) {
            try
            {
                let { copyAttachments } = await dispatch('global/dialogs/createNewVersion/open', null, { root: true });

                dispatch('setOverlayVisible', { visible: true, text: `Создается_новая_версия...` }, { root: true });

                let response = await httpAPI({
                    url: `api/actions/createnewversion?id=${getters.getDataSourceDocumentId}&copy=${copyAttachments}`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, null);
                    let refs = referencesMapper[response.data?.Payload?.Data.Object?.Document?.__type?.split(':')?.[0]];

                    if (refs)
                        refs.forEach( async ref => await dispatch('documents/references/getReference', { id: ref }, { root: true }));
                    
                    await router.push(getRouterParam(response.data.Payload));
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                }

                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async AttachDuplicate({ state, dispatch, getters, commit }) {
            try
            {
                let duplicateData = await dispatch('global/dialogs/attachDuplicates/open', getters.getDataSourceType, { root: true });

                let onAttachDuplicate = async (notifySign) => {
                    dispatch('setOverlayVisible', { visible: true }, { root: true });
                    let response = await httpAPI({
                        url: 'api/actions/attachduplicate',
                        method: 'POST',
                        data:
                        {
                            Content: JSON.stringify({ 
                                id: getters.getDataSourceDocumentId,
                                reason: duplicateData.reason,
                                firstDocumentId: duplicateData.document.id,
                                notifySign
                            })
                        },
                        headers: { 'Content-Type': 'application/json' }
                    });
                    dispatch('setOverlayVisible', { visible: false }, { root: true });

                    if (response) {
                        await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        this._vm.$notify.success(response.data.Payload.Message);
                    }
                };

                let needSignNotify = (getters.getDataSourceType === 'IncomingDocument' || getters.getDataSourceType === 'EuolStatement') && state.datasource.Data.Object.Document.SenderID !== '00000000-0000-0000-0000-000000000000'
                
                if (needSignNotify) {

                    let tryComplete = async () => {
                        let dataToSign = duplicateData.document.regnumber;
                        let signResult = await dispatch('global/signing/signNotification', { notificationType : 'REGISTER', dataToSign }, { root: true });

                        if (signResult.success) {
                            await onAttachDuplicate(signResult.message);
                        }
                        else {
                            let { result } = await dispatch('global/dialogs/notifySignErrorConfirm/open', 'REGISTER', { root: true });
        
                            switch (result)
                            {
                                case 'ok':
                                {
                                    await onAttachDuplicate(null);
                                    break;
                                }
                                case 'retry':
                                {
                                    await tryComplete();
                                    break;
                                }
                                default:
                                    break;
                            }
                        }
                    };

                    await tryComplete();
                }
                else
                    await onAttachDuplicate(null);
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async AddDocumentCommentFromTab({ commit, dispatch, getters, rootGetters }, comment) {
            try
            {
                let commentInfo = {
                    id: getters.getDataSourceDocumentId,
                    commentText: comment
                }

                let response = await httpAPI({
                    url: `api/actions/adddocumentcomment`,
                    method: 'POST',
                    data: { Content : JSON.stringify(commentInfo) },
                    headers: { 'isCommon': getters.isDataSourceCommon },
                });

                if (response)
                {
                    commit({ type: 'SET_DATASOURCE', datasource: response.data.Payload });

                    if (rootGetters['global/comments/getIsActive'])
                        dispatch('global/comments/updateDataSource', null, {root: true});
                }                
            }
            catch(exception)
            {
                console.log(exception);
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
        },
        async ReturnDraftByAuthor({ dispatch, commit, getters }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });
            let response = await httpAPI({
                url: `api/actions/returndraftbyauthor`,
                method: 'POST',
                data: { Content : JSON.stringify({ id: getters.getDataSourceDocumentId }) },
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });

            if (response) {
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                this._vm.$notify.success(response.data.Payload.Message);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        //#region Sign
        async SendToSign({ commit, dispatch, getters, rootGetters }) {
            try
            {
                let url = null;
                let documentMaySignsInCommonSchema = ["InnerDocument", "ProtocolDocument"].includes(getters.getDataSourceType);
                if (getters.getDataSourceType === "NPADocument") {
                    url = `api/actions/sendtosign?documentId=${getters.getDataSourceDocumentId}&signerWorkplaceId=${getters.getDataSource.Data.Object.Document.Card.EmployeeSigningActId}`;
                }
                else {
                    let isInnerEnabled = !getters.isDataSourceCommon || (getters.isDataSourceCommon && documentMaySignsInCommonSchema);
                    let signersBoundaries = null;

                    if (getters.isDataSourceCommon && !documentMaySignsInCommonSchema) {

                        let boundariesResponse = await httpAPI({
                            url: `api/references/signersboundariesenterprises?id=${getters.getDataSourceDocumentId}`,
                            method: 'GET',
                            headers: {'isCommon': getters.isDataSourceCommon},
                            skipErrorHandler: true
                        });
                        
                        if (boundariesResponse?.data?.success === true) {
                            // если тут не массив, то документ не имеет ограничений по выбору подписанта
                            if (Array.isArray(boundariesResponse.data.Payload?.Data?.Object)) {
                                signersBoundaries = boundariesResponse.data.Payload.Data.Object;
                                //если в массиве ограничений присутствует id организации пользователя, разрешаем выбирать подписантов из текущей организации
                                isInnerEnabled = signersBoundaries.includes(rootGetters['global/auth/getUserInfo'].enterpriseId);
                            }
                            else {
                                isInnerEnabled =  getters.getDataSourceType === "OutgoingDocument" && getters.getDataSource.Data.Object.Document.Card.IsInitiative;
                            }
                        }
                        else
                            console.error(`Ошибка получения данных для ограничения доступных подписантов.\n${boundariesResponse?.data?.message}`);
                    }

                    let selectMemberParams =  {
                        title: "Выбор_подписанта",
                        includeInner: isInnerEnabled,
                        includeOuter: (getters.getDataSourceType === "OutgoingDocument" || documentMaySignsInCommonSchema) && getters.isDataSourceCommon && rootGetters['auth/isCommonServiceEnabled'],
                        onlySigners: true,
                        multiple: false,
                        selected: [],
                        signersBoundaries,
                        disableEmptySelect: true
                    }

                    let signer = await dispatch('global/dialogs/selectMembersNew/open', selectMemberParams, { root: true });
                    url = `api/actions/sendtosign?documentId=${getters.getDataSourceDocumentId}&signerWorkplaceId=${signer.workplaceId}`;
                }

                dispatch('setOverlayVisible', { visible: true, text: `Документ_отправляется...` }, { root: true });

                var response = await httpAPI({
                    url,
                    method: 'GET',
                    headers: {'isCommon': getters.isDataSourceCommon},
                });

                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    this._vm.$notify.success(response.data.Payload.Message);
                }

            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async Sign({ commit, dispatch, getters }) {
            try
            {
                let resultCode, signType, comment, attachment, redirect;

                if (getters.getDataSourceType === 'EuolStatement') {
                    resultCode = 1;
                    signType = 1;
                    redirect = false;
                }
                else if (getters.getDataSourceType === 'ProtocolDocument'){
                    resultCode = 1;
                    signType =  await dispatch('global/signing/getUsedSignType', null, { root: true });
                    redirect = false;
                }
                else {
                    let params = await dispatch('global/dialogs/setSign/open', getters.getDataSourceType, { root: true });
                    resultCode = params.resultCode;
                    signType = params.signType;
                    comment = params.comment;
                    attachment = params.attachment;
                    redirect = params.redirect;
                }
                
                //если выбрана доработка или отказ от подписи
                if (resultCode !== 1) {
                    let signUrl = "";
                    let formData = new FormData();
                    let sign = 
                    {
                        documentId: getters.getDataSourceIdentifier,
                        resultCode,
                        comment
                    };

                    switch (resultCode)
                    {
                        case 2:
                            signUrl = "/api/actions/setsignreturn";
                            formData.append("sign", JSON.stringify(sign));
                            formData.append("attachment", attachment);
                            break;
                        case 3:
                            signUrl = "/api/actions/setsignreject";
                            formData.append("sign", JSON.stringify({ id: sign.documentId, reason: sign.comment }));
                            break;

                        default:
                            break;                    
                    }

                    dispatch('setOverlayVisible', { visible: true }, { root: true });

                    let response = await httpAPI({
                        url: signUrl,
                        method: 'POST',
                        data: formData,
                        headers: { 'isCommon': getters.isDataSourceCommon }
                    });
                    
                    dispatch('setOverlayVisible', { visible: false }, { root: true });

                    if (response) {
                        if (redirect) {
                            let module = store.getters['getCurrentModule'];
                            if (module === "eds-light"){
                                commit('edslight/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                await router.replace({ name: 'EdsLightInWork' });
                            }
                            else {
                                commit('correspondence/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                await router.replace({ name: 'CorrespondenceInWork' });
                            }
                        }
                        else {
                            await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        }

                        this._vm.$notify.success(response.data.Payload.Message);
                    }
                }
                else {
                    switch (signType) {
                        //УЦГО
                        case 1:
                        {
                            let { success, message } = await dispatch('global/signing/signDocumentUCGO', { id: getters.getDataSourceIdentifier, documentType: getters.isDataSourceCommon ? 'CommonDocument' : 'Document' }, { root: true });
                            
                            if (success) {
                                if (redirect) {
                                    let module = store.getters['getCurrentModule'];
                                    if (module === "eds-light"){
                                        commit('edslight/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                        await router.replace({ name: 'EdsLightInWork' });
                                    }
                                    else {
                                        commit('correspondence/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                        await router.replace({ name: 'CorrespondenceInWork' });
                                    }
                                }
                                else {
                                    await dispatch('updateDocument', null);
                                }

                                this._vm.$notify.success(message);
                            }
                            else
                            {
                                if (typeof message === 'string' && message === 'Network Error')
                                    this._vm.$notify.alert(i18n.t('Подписание_сервис_недоступен')); //обработка сетевой ошибки
                                else
                                    this._vm.$notify.alert(message); //отображаем остальные сообщения
                            }
                        }
                        break;
                        //НУЦ
                        case 2:
                        {
                            let { keyInfo } = await dispatch('global/signing/getKeyInfoFromArtaSynergy', null, { root: true });

                            dispatch('setOverlayVisible', { visible: true }, { root: true });

                            let signInfoResponse = await httpAPI({
                                url: `api/signing/info?id=${getters.getDataSourceIdentifier}`,
                                method: 'POST',
                                headers: { 'isCommon': getters.isDataSourceCommon },
                                data: { Content: keyInfo.pem },
                            });
                            
                            if (!signInfoResponse) {
                                dispatch('setOverlayVisible', { visible: false }, { root: true });
                                return;
                            }

                            let signResult = await dispatch('global/signing/signDocumentNICArtaSynergy', { data: { digest: signInfoResponse.data.Payload.Data.Object.Hash, fileNames: [signInfoResponse.data.Payload.Data.Object.FileNames] } }, { root: true });

                            let saveResponse = await httpAPI({
                                url: `api/signing/save?id=${getters.getDataSourceIdentifier}`,
                                method: 'POST',
                                headers: { 'isCommon': getters.isDataSourceCommon },
                                data: { Content: signResult.keyInfo.esedoSign },
                            });

                            dispatch('setOverlayVisible', { visible: false }, { root: true });

                            if (saveResponse) {
                                if (redirect) {
                                    let module = store.getters['getCurrentModule'];
                                    if (module === "eds-light"){
                                        commit('edslight/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                        await router.replace({ name: 'EdsLightInWork' });
                                    }
                                    else {
                                        commit('correspondence/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                        await router.replace({ name: 'CorrespondenceInWork' });
                                    }
                                }
                                else {
                                    await dispatch('updateDocument', null);
                                }

                                this._vm.$notify.success(saveResponse.data.Payload.Message);
                            }
                        }
                        break;
                        //Простая подпись
                        case 3:
                        {
                            let { success, message } = await dispatch('global/signing/signSimple', getters.getDataSourceIdentifier, { root: true });

                            if (success) {
                                if (redirect) {
                                    let module = store.getters['getCurrentModule'];
                                    if (module === "eds-light"){
                                        commit('edslight/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                        await router.replace({ name: 'EdsLightInWork' });
                                    }
                                    else {
                                        commit('correspondence/inWork/SET_COLLECTION', 'Documents.My.OnSign', { root: true })
                                        await router.replace({ name: 'CorrespondenceInWork' });
                                    }
                                }
                                else {
                                    await dispatch('updateDocument', null);
                                }

                                this._vm.$notify.success(message);
                            }
                        }
                        break;

                        default:
                            break;
                    }
                }
            }
            catch (ex)
            {
                if (ex.message != "Cancelled")
                    this._vm.$notify.alert(ex.message);
            }
        },
        async RemoveSign({ dispatch, commit, getters }) {
            this._vm.$notify.confirm(
                i18n.t('Отозвать_Вашу_подпись_из_текущего_документа'),
                async () =>
                {
                    dispatch('setOverlayVisible', { visible: true }, { root: true });
                    let response = await httpAPI({
                        url: `api/actions/removesign`,
                        method: 'POST',
                        data: { Content: JSON.stringify({ id: getters.getDataSourceDocumentId }) },
                    });
                    dispatch('setOverlayVisible', { visible: false }, { root: true });

                    if (response) {
                        await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        this._vm.$notify.success(response.data.Payload.Message);
                    }
                }
            );
        },
        //#endregion

        //#region Registr
        async Register({ dispatch, getters, commit, state }) {
            try
            {
                let docId = getters.getDataSourceDocumentId;
                let docType = getters.getDataSourceType;
                if (docType == "InnerDocument" && getters.getDataSource?.Data?.Object?.Document?.Card?.IsProtocol)
                    docType = "ProtocolDocument";
                let { id, param, potentialNumber } = await dispatch('global/dialogs/registerDocument/open', { docId, docType }, { root: true });

                let onRegister = async (notifySign) => {
                    dispatch('setOverlayVisible', { visible: true }, { root: true });

                    param.NotifySign = notifySign;

                    let response = await httpAPI({
                        url: 'api/actions/registerdocument',
                        method: 'POST',
                        data: { Content: JSON.stringify({ id, param }) },
                        headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                    });

                    if (response) {
                        if (docType == "ProtocolDocument"){
                            dispatch('loadDataSource', { id: docId, openType: "Chancellery|Documents.Document", common: getters.isDataSourceCommon});
                        }
                        else {
                            if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                                await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                            await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                            dispatch('setOverlayVisible', { visible: false }, { root: true });
                        }
                        this._vm.$notify.success(response.data.Payload.Message);
                    }
                };
                
                if (getters.getDataSourceType === 'IncomingDocument') {

                    let needSignNotify = state.datasource.Data.Object.Document.SenderID !== '00000000-0000-0000-0000-000000000000';

                    //сначала проверяем документ на дубликат
                    let duplicateCheckResponse = await httpAPI({
                        url: 'api/actions/checkincomingforduplicate',
                        method: 'POST',
                        data: { Content: JSON.stringify({ id }) },
                        headers: { 'Content-Type': 'application/json' }
                    });

                    if (duplicateCheckResponse)
                    {
                        let duplicateMessage = duplicateCheckResponse.data.Payload.Message;

                        //#region если дубликаты не найдены
                        if (!duplicateMessage) {
                            //проверяем необходимость подписи уведомления
                            if (needSignNotify) {
                                let tryComplete = async () => {
                                    let dataToSign = potentialNumber;
                                    let signResult = await dispatch('global/signing/signNotification', { notificationType: 'REGISTER', dataToSign, regDate: param.RegDate }, { root: true });

                                    if (signResult.success) {
                                        await onRegister(signResult.message);
                                    }
                                    else {
                                        let { result } = await dispatch('global/dialogs/notifySignErrorConfirm/open', 'REGISTER', { root: true });
        
                                        switch (result)
                                        {
                                            case 'ok':
                                            {
                                                await onRegister(null);
                                                break;
                                            }
                                            case 'retry':
                                            {
                                                await tryComplete();
                                                break;
                                            }
                                            default:
                                                break;
                                        }
                                    }
                                };
                                
                                await tryComplete();
                            }
                            else {
                                await onRegister(null);
                            }
                        }
                        //#endregion
                        //#region сообщить о дубликате
                        else
                        {
                            let onAttachDuplicate = async (notifySign) => {
                                dispatch('setOverlayVisible', { visible: true }, { root: true });
                                let response = await httpAPI({
                                    url: 'api/actions/attachduplicate',
                                    method: 'POST',
                                    data:
                                    {
                                        Content: JSON.stringify({
                                            id: getters.getDataSourceDocumentId,
                                            reason: i18n.t("Определено_регистратором"),
                                            firstDocumentId: duplicateCheckResponse.data.Payload.Data.Object.id,
                                            notifySign
                                        })
                                    },
                                    headers: { 'Content-Type': 'application/json' }
                                });
                                dispatch('setOverlayVisible', { visible: false }, { root: true });
            
                                if (response) {
                                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                                    this._vm.$notify.success(response.data.Payload.Message);
                                }
                            };
                            
                            //Спрашиваем, хочет ли пользователь закрепить документ как дубликат
                            this._vm.$notify.confirm(
                                duplicateMessage,
                                //если да
                                async () =>
                                {
                                    //проверяем, необходимо ли подписать уведомление о регистрации
                                    if (needSignNotify) {
                                        let tryComplete = async () => {
                                            let dataToSign = duplicateCheckResponse.data.Payload.Data.Object.Card.RegNumber;
                                            let signResult = await dispatch('global/signing/signNotification', { notificationType: 'REGISTER', dataToSign }, { root: true });

                                            if (signResult.success) {
                                                await onAttachDuplicate(signResult.message);
                                            }
                                            else {
                                                let { result } = await dispatch('global/dialogs/notifySignErrorConfirm/open', 'REGISTER', { root: true });
        
                                                switch (result)
                                                {
                                                    case 'ok':
                                                    {
                                                        await onAttachDuplicate(null);
                                                        break;
                                                    }
                                                    case 'retry':
                                                    {
                                                        await tryComplete();
                                                        break;
                                                    }
                                                    default:
                                                        break;
                                                }
                                            }
                                        };
                                        
                                        await tryComplete();
                                    }
                                    else
                                    {
                                        await onAttachDuplicate(null);
                                    }
                                },
                                //если нет
                                async () =>
                                {
                                    //спрашиваем, хочет ли пользовать продолжить регистрацию документа с ранее выбранными параметрами
                                    this._vm.$notify.confirm(
                                        i18n.t('Хотите_продолжить_регистрацию_вопрос'),
                                        async () =>
                                        {
                                            //проверяем необходимость подписи уведомления
                                            if (needSignNotify) {
                                                let tryComplete = async () => {
                                                    let dataToSign = potentialNumber;
                                                    let signResult = await dispatch('global/signing/signNotification', { notificationType: 'REGISTER', dataToSign, regDate: param.RegDate }, { root: true });

                                                    if (signResult.success) {
                                                        await onRegister(signResult.message);
                                                    }
                                                    else {
                                                        let { result } = await dispatch('global/dialogs/notifySignErrorConfirm/open', 'REGISTER', { root: true });
        
                                                        switch (result)
                                                        {
                                                            case 'ok':
                                                            {
                                                                await onRegister(null);
                                                                break;
                                                            }
                                                            case 'retry':
                                                            {
                                                                await tryComplete();
                                                                break;
                                                            }
                                                            default:
                                                                break;
                                                        }
                                                    }
                                                };
                                                
                                                await tryComplete();
                                            }
                                            else {
                                                await onRegister(null);
                                            }
                                        }
                                    );
                                }
                            );
                        }
                        //#endregion
                    }
                }
                else
                {
                   await onRegister(null);
                }
            }
            catch (ex)
            {
                if (ex.showNotify === true)
                    this._vm.$notify.alert(i18n.t(ex.message));

                console.log(ex.message);
            }
        },
        async RegisterSection({ state, dispatch, getters, commit, rootGetters }) {
            try
            {
                let min = state.datasource.Data.Object.Document?.CreateDate ?? new Date(-8640000000000000);
                let max = state.datasource.Data.Object.Document?.OuterLimit ?? new Date(8640000000000000);

                // Игнорировать ограничения установки сроков внутреннего контроля (#198)
                let ignoreInnerControlLimits = rootGetters['global/auth/getUserInfo']?.permissions?.includes("IgnoreInnerControlLimits") ?? false;

                if (ignoreInnerControlLimits) {
                    min = new Date(-8640000000000000);
                    max = new Date(8640000000000000);
                }

                let registerData = await dispatch('global/dialogs/registerSection/open', { document: state.datasource.Data.Object.MainDocumentName, min, max }, { root: true });

                let onRegisterSection = async (notifySign) => {
                    dispatch('setOverlayVisible', { visible: true }, { root: true });

                    let response = await httpAPI({
                        url: 'api/actions/registersection',
                        method: 'POST',
                        data:
                        {
                            Content: JSON.stringify(
                            {
                                id: getters.getDataSourceDocumentId,
                                param:
                                {
                                    ControlType: registerData.type == 101 ? 1 : registerData.type,
                                    ControlDate: `/Date(${Date.parse(registerData.date)})/`,
                                    NotifySign: notifySign,
                                    Periodicity: registerData.deltatype,
                                    PeriodIncrement: registerData.delta,
                                    PeriodEndDate: registerData.type == 101 ? `/Date(${Date.parse(registerData.periodend)})/` : null,
                                    IsPeriodic: registerData.type == 101,
                                    IsConsiderWeekends: registerData.isConsiderWeekends
                                }
                            })
                        },
                        headers: { 'Content-Type': 'application/json' }
                    });

                    if (response) {

                        if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                            await updateControlInfo( getters, commit, getters.getDataSourceDocumentId);
                        
                            await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        this._vm.$notify.success(response.data.Payload.Message);
                    }

                    dispatch('setOverlayVisible', { visible: false }, { root: true });
                };

                let needSignNotify = getters.getDataSourceType === 'IncomingDocument' && state.datasource.Data.Object.Document.SenderID !== '00000000-0000-0000-0000-000000000000';

                if (needSignNotify) {
                    let tryComplete = async () => {
                        let dataToSign = `${state.datasource.Data.Object.MainDocumentNumber}//${state.datasource.Data.Object.Document.Card.SectionNumber}`;
                        let signResult = await dispatch('global/signing/signNotification', { notificationType: 'REGISTER', dataToSign }, { root: true });

                        if (signResult.success) {
                            await onRegisterSection(signResult.message);
                        }
                        else {
                            let { result } = await dispatch('global/dialogs/notifySignErrorConfirm/open', 'REGISTER', { root: true });
        
                            switch (result)
                            {
                                case 'ok':
                                {
                                    await onRegisterSection(null);
                                    break;
                                }
                                case 'retry':
                                {
                                    await tryComplete();
                                    break;
                                }
                                default:
                                    break;
                            }
                        }
                    };

                    await tryComplete();
                }
                else {
                    await onRegisterSection(null);
                }
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        //#endregion

        //#region Adjustment
        async BeginAdjustment({ commit, dispatch, getters }) {
            try
            {
                console.log('BeginAdjustment');

                let { parallel, workPlaceIds } = await dispatch('global/dialogs/beginAdjustment/open', null, { root: true });

                dispatch('setOverlayVisible', { visible: true, text: `Документ_отправляется...` }, { root: true });
                
                let response = await httpAPI({
                    url: 'api/actions/beginadjustment',
                    method: 'POST',
                    data: { Content : JSON.stringify({ id: getters.getDataSourceIdentifier, parallel, workPlaceIds }) },
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                });

                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu }, { root: true });
                    this._vm.$notify.success(response.data.Payload.Message);
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async WithdrawAdjustment({ commit, dispatch, getters }) {
            dispatch('setOverlayVisible', { visible: true, text: `Отзыв_согласования...` }, { root: true });

            let response = await httpAPI({
                url: `api/actions/withdrawadjustment/${getters.getDataSourceIdentifier}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                this._vm.$notify.success(response.data.Payload.Message);
            }
        },
        async BeginProtocolAdjustment({ commit, dispatch, getters }) {
            try
            {
                dispatch('setOverlayVisible', { visible: true, text: `Документ_отправляется...` }, { root: true });
                
                let response = await httpAPI({
                    url: `api/orders/beginprotocoladjustment?id=${getters.getDataSourceIdentifier}`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'isCommon': true },
                });

                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu }, { root: true });
                    this._vm.$notify.success(response.data.Payload.Message);
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async SetAdjustment({ commit, dispatch, getters }) {
            try
            {
                let { resultCode, comment, attachment, redirect } = await dispatch('global/dialogs/setAdjustment/open', null, { root: true });

                let adjustment = 
                {
                    documentId: getters.getDataSourceIdentifier,
                    resultCode,
                    comment
                };

                dispatch('setOverlayVisible', { visible: true, text: `Сохранение_результата_согласования...` }, { root: true });

                let formData = new FormData();
                formData.append("adjustment", JSON.stringify(adjustment));
                formData.append("attachment", attachment);

                let response = await httpAPI({
                    url: `api/actions/setadjustment`,
                    method: 'POST',
                    data: formData,
                    headers: { 'isCommon': getters.isDataSourceCommon },
                });
                
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    if (redirect)  {
                        if (getters.getDataSourceType == "ProtocolDocument")
                            await router.replace({ name: 'ProtocolOnAdjustmentList' });
                        else {
                            let module = store.getters['getCurrentModule'];
                            if (module === "eds-light"){
                                commit('edslight/inWork/SET_COLLECTION', 'Documents.My.OnAdjustment', { root: true })
                                await router.replace({ name: 'EdsLightInWork' });
                            }
                            else {
                                commit('correspondence/inWork/SET_COLLECTION', 'Documents.My.OnAdjustment', { root: true })
                                await router.replace({ name: 'CorrespondenceInWork' });
                            }
                        }
                    }
                    else {
                        if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                            await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);
                        
                        await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                        //commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu }, { root: true });
                    }

                    this._vm.$notify.success(response.data.Payload.Message);
                }
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async CancelAdjusment({commit, dispatch, getters}, {id, overlay}){
            if (overlay)
                dispatch('setOverlayVisible', { visible: true, text: `Удаление_согласующего...` }, { root: true });
            let response = await httpAPI({
                url: `api/actions/canceladjustment`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon },
                data: { Content : JSON.stringify({ documentId: getters.getDataSourceIdentifier, adjustmentId: id }) },
            });
            if (overlay)
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            if (response) {
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                //commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu }, { root: true });
                this._vm.$notify.success(response.data.Payload.Message);
            }
            else 
                await dispatch('updateDocument', null);
        },
        //#endregion

        //#region Print
        async PrintCard({ dispatch, getters }, { button }) {

            dispatch('setOverlayVisible', { visible: true }, { root: true });
            
            let response = await httpAPI({
                url: `api/download/report?type=${button.Type}&id=${getters.getDataSourceIdentifier}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon },
                responseType: 'blob'
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                await dispatch('global/dialogs/pdfView/open', { url, name: `Карточка` }, { root: true });
                
                URL.revokeObjectURL(url);
            }
        },
        async PrintHistory({ dispatch, getters }, { button }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });
            
            let response = await httpAPI({
                url: `api/download/report?type=${button.Type}&id=${getters.getDataSourceIdentifier}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon },
                responseType: 'blob'
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                await dispatch('global/dialogs/pdfView/open', { url, name: `История_документа` }, { root: true });
                
                URL.revokeObjectURL(url);
            }
        },
        async PrintTalon({ state, dispatch, getters }, { button }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });
            
            let response = await httpAPI({
                url: `api/download/report?type=${button.Type}&id=${getters.getDataSourceIdentifier}`,
                method: 'GET',
                responseType: 'blob',
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                await dispatch('global/dialogs/pdfView/open', { url, name: `Талон ${ state.datasource.Data.Object.Document.Card.RegNumber }` }, { root: true });
                
                URL.revokeObjectURL(url);
            }
        },
        async PrintFishka({ state, dispatch, getters }) {
            try
            {
                let printFishkaParams = await dispatch('global/dialogs/printFishka/open', null, { root: true });

                let params =
                {
                    OnBlank: printFishkaParams.onBlank,
                    HideExecuters: printFishkaParams.hideExecuters,
                    Language: printFishkaParams.language,
                    Resolution: {
                        id: getters.getDataSourceIdentifier,
                        DocumentID: _.has(state.datasource, 'Data.Object.Resolution')
                            ? state.datasource.Data.Object.Resolution.Document?.id
                            : state.datasource.Data.Object.Document?.id
                    },
                    ResolutionTask: {
                        id: getters.getDataSourceTaskId
                    }
                };

                dispatch('setOverlayVisible', { visible: true }, { root: true });
            
                let response = await httpAPI({
                    url: `api/download/reportbyparams?type=Documents|Orders|CitizenStatements.Fishka&param=${JSON.stringify(params)}`,
                    method: 'GET',
                    responseType: 'blob',
                    headers: { 'isCommon': getters.isDataSourceCommon }
                });

                dispatch('setOverlayVisible', { visible: false }, { root: true });
                console.log('response', response);
                if (response) {
                    const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                    await dispatch('global/dialogs/pdfView/open', { url, name: `Фишка` }, { root: true });
                    
                    URL.revokeObjectURL(url);
                }
            }
            catch(ex)
            {
                console.log('error', ex.message);
            }
        },
        async PrintSendingList({ state, dispatch, getters }, { button }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });
            
            let response = await httpAPI({
                url: `api/download/report?type=${button.Type}&id=${getters.getDataSourceIdentifier}`,
                method: 'GET',
                responseType: 'blob',
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (response) {
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                await dispatch('global/dialogs/pdfView/open', { url, name: `Лист рассылки ${state.datasource.Data.Object.Document.Card.RegNumber}` }, { root: true });
                
                URL.revokeObjectURL(url);
            }
        },
        //#endregion

        //#region Control
        async TakeOnControl({ commit, state, dispatch, getters, rootGetters }) {
            let minControlDate = state.datasource?.Data?.Object?.Document?.CreateDate ?? new Date(-8640000000000000); // в минимальную дату контроля берем либо дату создания документа либо минимально возможную дату

            if (getters.getDataSourceType === 'IncomingDocumentDSP')
                minControlDate = state.datasource?.Data?.Object?.Document?.Card?.ActualIncomingDate ?? new Date(-8640000000000000);

            let now = this._vm.$moment(await dispatch('documents/references/getTimestamp', null, { root: true })).startOf('day');
            /**Внешний срок (если есть - из карточки, если нет - максимально возможная дата)*/
            let outerLimit = this._vm.$moment(state.datasource?.Data?.Object?.Document?.Card?.OuterLimit ?? new Date(8640000000000000)).startOf('day');
            /**Документ просрочен (по текущей дате сервера)*/
            let isOutdated = outerLimit.isBefore(now);
            /**Дата поступления/создания документа*/
            let createDate = this._vm.$moment(state.datasource?.Data?.Object?.Document?.CreateDate).startOf('day');
            /**Документ поступил/создан просроченным*/
            let isOutdatedCreation = createDate.isAfter(outerLimit);
            /**Признак поступления из ЕСЭДО*/
            let IsFromESEDO = state.datasource?.Data?.Object?.IsFromESEDO === true;
            /**Максимальная дата, доступная при выборе контрольного срока */
            let maxControlDate = outerLimit;
            
            if (isOutdated && isOutdatedCreation) {
                if (IsFromESEDO) {
                    maxControlDate = createDate.add(2, 'days');
                }
                else {
                    maxControlDate = createDate.add(1, 'days');
                }
            }

            // Игнорировать ограничения установки сроков внутреннего контроля (#198)
            let ignoreInnerControlLimits = rootGetters['global/auth/getUserInfo']?.permissions?.includes("IgnoreInnerControlLimits") ?? false;

            if (ignoreInnerControlLimits) {
                maxControlDate = new Date(8640000000000000);
            }
            
            try
            {
                let setControlParams = await dispatch(
                    'global/dialogs/setDocumentControl/open',
                    { 
                        min: minControlDate,
                        max: maxControlDate
                    },
                    { 
                        root: true
                    }
                );

                let content =
                {
                    id: getters.getDataSourceDocumentId,
                    param:
                    {
                        ControlType: setControlParams.type == 101 ? 1 : setControlParams.type,
                        ControlDate: `/Date(${Date.parse(setControlParams.date)})/`,
                        Periodicity: setControlParams.deltatype,
                        PeriodIncrement: setControlParams.delta,
                        PeriodEndDate: setControlParams.type == 101 ? `/Date(${Date.parse(setControlParams.periodend)})/` : null,
                        IsPeriodic: setControlParams.type == 101,
                        IsConsiderWeekends: setControlParams.isConsiderWeekends
                    }
                };

                dispatch('setOverlayVisible', { visible: true }, { root: true });

                let response = await httpAPI({
                    url: 'api/actions/takeoncontrol',
                    method: 'POST',
                    data: { Content: JSON.stringify(content) },
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                });

                if (response) {

                    if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });

                    this._vm.$notify.success(response.data.Payload.Message);
                }

                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
            catch (ex)
            {
                console.log(ex); 
            }
        },
        async ChangeControl({ state, dispatch, getters, commit, rootGetters }) {
            try
            {
                let outerLimit = state.datasource?.Data?.Object?.Document?.Card?.OuterLimit;
                let isPeriodic = state.datasource?.Data?.Object?.Document?.Card?.Periodicity > 0 ?? false;
                let minDate = this._vm.$moment(await dispatch('documents/references/getTimestamp', null, { root: true })).subtract(1,'d');
                let maxDate = outerLimit ?? new Date(8640000000000000);

                if (isPeriodic) {
                    minDate = state.datasource?.Data?.Object?.Document?.Card?.ControlDate;
                    maxDate = this._vm.$moment(state.datasource?.Data?.Object?.Document?.Card?.PeriodNextDate);
                    if (maxDate)
                        maxDate = maxDate -1;
                    else 
                        maxDate = this._vm.$moment(state.datasource?.Data?.Object?.Document?.Card?.PeriodEndDate);
                }
                else {
                    // Игнорировать ограничения установки сроков внутреннего контроля (#198)
                    let ignoreInnerControlLimits = rootGetters['global/auth/getUserInfo']?.permissions?.includes("IgnoreInnerControlLimits") ?? false;

                    if (ignoreInnerControlLimits) {
                        maxDate = new Date(8640000000000000);
                    }
                }

                let changeData = await dispatch('global/dialogs/changeControl/open', { min: minDate, max: maxDate }, { root: true });
                
                let content =
                {
                    id: getters.getDataSourceDocumentId,
                    controlDate: `/Date(${Date.parse(changeData.date)})/`,
                    reason: changeData.reason
                }

                dispatch('setOverlayVisible', { visible: true }, { root: true });

                let response = await httpAPI({
                    url: 'api/actions/changecontrol',
                    method: 'POST',
                    data: { Content: JSON.stringify(content) },
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                });

                if (response) {

                    if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    
                    this._vm.$notify.success(response.data.Payload.Message);
                }

                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async TakeOffWrongControl({ commit, dispatch, getters }) {

            let onTakeOffWrongControl = async () => {
                dispatch('setOverlayVisible', { visible: true }, { root: true });
                let response = await httpAPI({
                    url: 'api/actions/takeoffwrongcontrol',
                    method: 'POST',
                    data:
                    {
                        Content: JSON.stringify({ id: getters.getDataSourceDocumentId })
                    },
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                });

                if (response) {

                    if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                        await updateControlInfo( getters, commit, getters.getDataSourceDocumentId);
                    
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });
                    this._vm.$notify.success(response.data.Payload.Message);
                }
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            };

            this._vm.$notify.confirm(
                i18n.t('Снять_документ_с_ошибочного_контроля'),
                async () =>
                {
                    await onTakeOffWrongControl();
                }
            );
        },
        async TakeOffControl({ commit, dispatch, getters }) {

            let onTakeOffControl = async () => {
                dispatch('setOverlayVisible', { visible: true }, { root: true });
                let response = await httpAPI({
                    url: 'api/actions/takeoffcontrol',
                    method: 'POST',
                    data: { Content: JSON.stringify({ id: getters.getDataSourceDocumentId, takeOffDate: `/Date(${Date.now()})/` }) },
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }                    
                });
                
                if (response) {
                    
                    if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });
                    this._vm.$notify.success(response.data.Payload.Message);
                }
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            };

            this._vm.$notify.confirm(
                i18n.t('Снять_документ_с_контроля'),
                async () =>
                {
                    await onTakeOffControl();
                }
            );
        },
        async ClosePeriod({ state, dispatch, getters, commit }) {
            try
            {
                let isRandomPeriodic = state.datasource?.Data?.Object?.Document?.Card?.Periodicity == 4 ?? false;
                let content = {
                    id: getters.getDataSourceDocumentId
                }
                if (isRandomPeriodic){
                    let minDate = state.datasource?.Data?.Object?.Document?.Card?.ControlDate;
                    let maxDate = state.datasource?.Data?.Object?.Document?.Card?.PeriodEndDate;
                    let nextPeriodDate = await dispatch('global/dialogs/nextPeriodDate/open', { min: minDate, max: maxDate }, {  root: true });
                    content.nextDate = `/Date(${Date.parse(nextPeriodDate)})/`
                }

                dispatch('setOverlayVisible', { visible: true }, { root: true });

                let response = await httpAPI({
                    url: 'api/actions/closeperiod',
                    method: 'POST',
                    data: { Content: JSON.stringify( content)},
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                });

                if (response) {

                    if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);

                    this._vm.$notify.success(response.data.Payload.Message);
                }

                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async ReturnByControler({ dispatch, commit, getters }) {
            this._vm.$notify.confirm(
                i18n.t('Вернуть_на_доработку'),
                async () => {
                    try
                    {
                        dispatch('setOverlayVisible', { visible: true }, { root: true });

                        let response = await httpAPI({
                            url: 'api/actions/returnbycontroler',
                            method: 'POST',
                            data: { Content: JSON.stringify({ id: getters.getDataSourceDocumentId }) },
                            headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                        });

                        if (response) {

                            if (needLoadControlInfo(response.data.Payload.Data.Object.Document))
                                await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                            await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                            this._vm.$notify.success(response.data.Payload.Message);
                        }

                        dispatch('setOverlayVisible', { visible: false }, { root: true });
                    }
                    catch
                    {
                        console.error("Произошла непредвиденная ошибка");
                    }
                }
            );
        },
        //#endregion

        //#region Meeting
        async CancelMeeting({ commit, dispatch, getters}){
            this._vm.$notify.confirm(
                i18n.t('Отменить_совещание'),
                async () => {
                    try
                    {
                        dispatch('setOverlayVisible', { visible: true, text: `Совещание_отменяется...` }, { root: true });

                        let response = await httpAPI({
                            url: `api/eventmanagement/cancelmeeting?id=${getters.getDataSourceIdentifier}`,
                            method: 'GET',
                        });

                        if (response) {
                            await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                            //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                            //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });
                            this._vm.$notify.success(response.data.Payload.message);
                        }
                        else {
                            this._vm.$notify.alert(response.data.Payload.message);
                        }

                        dispatch('setOverlayVisible', { visible: false }, { root: true });
                    }
                    catch
                    {
                        console.error("Произошла непредвиденная ошибка");
                    }
                }
            );
        },
        async AddProtocol({ commit, dispatch, getters}){
            try
            {
                let linkData = await dispatch('eventManagement/addProtocolLink/open', null, { root: true });
                
                let content =
                {
                    id: getters.getDataSourceIdentifier,
                    protocolId: linkData.protocol.id,
                    fromCommon: linkData.protocol.iscommon
                };
                
                dispatch('setOverlayVisible', { visible: true }, { root: true });
                var response = await httpAPI({
                    url: `api/eventmanagement/addprotocollink`,
                    method: 'POST',
                    data: { Content : JSON.stringify(content) },
                    headers: { 'isCommon': true },
                });
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    this._vm.$notify.success(response.data.Payload.message);
                }
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
        },
        //#endregion

        //#region Resolutions
        async AddResolution({ dispatch, getters })
        {
            dispatch('loadDataSource', { id: null, openType: "Documents|Orders|CitizenStatements.ResolutionTask", createType: "Resolution", navigate: true, common: getters.isDataSourceCommon });
        },
        async ExecuterShowExecution({ dispatch }, { executionData }) {
            try
            {
                let decision = await dispatch('global/dialogs/executerAnswerView/open', executionData, { root: true });
                await dispatch(`${decision.action}`, decision.param);
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async ExecuterSetExecuted({ state, dispatch, commit, getters }, executionData) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            let response = await httpAPI({
                url: `api/actions/executersetexecuted`,
                method: 'POST',
                data: { Content: JSON.stringify({ id: executionData.resolutionId, taskId: executionData.taskId, executerId: executionData.executerId }) },
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });

            if (response) {
                if (!getters.isDataSourceDocument) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    commit('SET_EXECUTIONTREE', null);

                    if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                        await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                }
                else {
                    if (needLoadControlInfo(state.datasource.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    this._vm.$eventBus.$emit('update-active-tab');
                }

                this._vm.$notify.success(response.data.Payload.Message);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async ExecuterReturnToRework({ state, dispatch, commit, getters }, executionData) {
            let reason = i18n.t("Карточка_возвращена_в_работу_исполнителем");

            if (executionData.byAuthor === false) {
                reason = "Доработать!";
            }

            dispatch('setOverlayVisible', { visible: true }, { root: true });

            let response = await httpAPI({
                url: `api/actions/executerreturntorework`,
                method: 'POST',
                data: { Content: JSON.stringify({ id: executionData.resolutionId, taskId: executionData.taskId, executerId: executionData.executerId, reason }) },
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });
            
            if (response) {
                if (!getters.isDataSourceDocument) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    commit('SET_EXECUTIONTREE', null);

                    if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                        await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                }
                else {
                    if (needLoadControlInfo(state.datasource.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    this._vm.$eventBus.$emit('update-active-tab');
                }

                this._vm.$notify.success(response.data.Payload.Message);
            }
            
            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async ExecuterGiveAnswer({ dispatch, state, commit, getters, rootGetters }, { updateSource, actionData }) {
            try
            {
                let resolutionId = actionData ? actionData.resolutionId : getters.getDataSourceIdentifier;
                let taskId = actionData ? actionData.taskId : getters.getDataSourceTaskId;
                
                let executerId = actionData
                    ? actionData.executerId
                    : state.activeExecuter
                        ?  state.activeExecuter
                        : '00000000-0000-0000-0000-000000000000';

                let isMainTaskExecution = actionData ? actionData.isMainTask : getters.getDataSource?.Data?.AdditionalObjects?.[4] ?? false;

                if (isMainTaskExecution && getters.getDataSourceDocumentId) {
                    let controlInfoResponse = await httpAPI({
                        url: `api/references/controlinfo/${getters.getDataSourceDocumentId}`,
                        method: 'GET',
                        headers: { 'isCommon': getters.isDataSourceCommon },
                    });
                    
                    if (!controlInfoResponse) {
                        this._vm.$notify.alert(i18n.t('При_выполнении_действия_возникла_непредвиденная_ошибка'));
                        return;
                    }

                    isMainTaskExecution = controlInfoResponse.data.Payload.MainExecuterId == (actionData ? actionData.executerWorkplaceId : rootGetters['global/auth/getUserWorkplaceId']);
                }
                
                commit('SET_IS_MAIN_EXECUTER_GIVE_ANSWER', isMainTaskExecution);
                let answer = await dispatch('global/dialogs/executerAnswer/open', null, { root: true });

                dispatch('setOverlayVisible', { visible: true }, { root: true });

                let giveAnswerResponse = await httpAPI({
                    url: `api/actions/executergiveanswer?id=${resolutionId}&taskId=${taskId}&executerId=${executerId}`,
                    method: 'GET',
                    headers: { 'isCommon': getters.isDataSourceCommon }
                });
                
                if (giveAnswerResponse) {
                    executerId = giveAnswerResponse.data.Payload.Data.Object.Executer.id;
                    let hasFiles = answer.files.length > 0;

                    if (hasFiles)
                        giveAnswerResponse.data.Payload.Data.Object.OtherAttachments = { data_list: answer.files.map(file => ({ Name: file.name, is_new_record: true })) };
                    
                    giveAnswerResponse.data.Payload.Data.Object.DocumentIds = answer.document ? [ answer.document.id ] : [];
                    giveAnswerResponse.data.Payload.Data.Object.Comment = answer.text;

                    let content = 
                    {
                        id: resolutionId,
                        taskId,
                        executerId,
                        execution: giveAnswerResponse.data.Payload.Data.Object
                    };

                    let saveAnswerResponse = await httpAPI({
                        url: `api/actions/executersaveanswer`,
                        method: 'POST',
                        data: { Content: JSON.stringify(content) },
                        headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                    });

                    if (saveAnswerResponse) {
                        let saveFileResponse = null;

                        if (hasFiles) {

                            let formdata = new FormData();
                            answer.files.forEach((file, index) => {
                                formdata.append(`file${index}`, file);
                            });

                            saveFileResponse = await httpAPI({
                                url: `api/actions/executersaveanswerattachments?executionId=${giveAnswerResponse.data.Payload.Data.Object.id}`,
                                method: 'POST',
                                data: formdata,
                                headers: { "Content-Type": "multipart/form-data", 'isCommon': getters.isDataSourceCommon },
                            });

                            if (!saveFileResponse)
                                return;
                        }
                        if (getters.getDataSourceType == "ProtocolDocument" && state.datasource.Data.Object.Document.Card.TypeC4==3){
                            await dispatch('updateDocument', null);
                        }
                        else if (updateSource !== false) {
                            await setDataSourceAndMenu(commit, dispatch, hasFiles ? saveFileResponse.data.Payload : saveAnswerResponse.data.Payload);
                            //commit({type: 'SET_DATASOURCE', datasource: hasFiles ? saveFileResponse.data.Payload : saveAnswerResponse.data.Payload });
                            //commit('documents/toolbar/SET_MENU', {menu: saveFileResponse.data.Payload.Data.Menu }, { root: true });
                            commit('SET_EXECUTIONTREE', null);

                            if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                                await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                        }
                        else {
                            if (needLoadControlInfo(state.datasource.Data.Object.Document))
                                await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                            this._vm.$eventBus.$emit('update-active-tab');
                        }

                        this._vm.$notify.success(hasFiles ? saveFileResponse.data.Payload.Message : saveAnswerResponse.data.Payload.Message);
                    }
                }
            }
            catch (ex)
            {
                console.log(ex.message);
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
        },
        async ExecuterResend({ dispatch, state, commit, getters }, { actionData }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            let resolutionId = actionData ? actionData.resolutionId : getters.getDataSourceIdentifier;
            let taskId = actionData ? actionData.taskId : getters.getDataSourceTaskId;
            let executerId = actionData || state.activeExecuter == null ? sys.guidEmpty() : state.activeExecuter;
            var response = await httpAPI({
                url: `api/actions/executerresend?id=${resolutionId}&taskId=${taskId}&executerId=${executerId}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon }
            });

            if (response) {
                await setDataSourceAndMenu(commit, dispatch, null);
                //commit({ type: 'SET_DATASOURCE', datasource: null });
                await router.push(getRouterParam(response.data.Payload));
                //#region Для резолюции подменяем тип контроля в случае если резолюция периодическая
                if (response.data.Payload.Data.Object.Resolution && (((response.data.Payload.Data?.AdditionalObjects?.[4] ?? false) && response.data.Payload.Data.Object.Resolution.Document.Card.Periodicity) ||
                    response.data.Payload.Data.Object.Resolution.Tasks[0].IsPeriodic)){
                    response.data.Payload.Data.Object.Resolution.Tasks[0].ControlType = {id:101, Value: 'Периодический_контроль'};
                }
                //#endregion
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });
                let refs = referencesMapper[getters.getDataSourceType];

                if (refs)
                    refs.forEach( async ref => await dispatch('documents/references/getReference', { id: ref }, { root: true }));
                
                commit('SET_EXECUTIONTREE', null);

                if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                    await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async CancelResolution({ dispatch, commit, getters }) {
            this._vm.$notify.confirm(
                i18n.t('Отменить_поручение_вопрос'),
                async () =>
                {
                    let content =
                    {
                        id: getters.getDataSourceIdentifier,
                        taskId: getters.getDataSourceTaskId,
                        reason: "Отменено_автором"
                    };

                    dispatch('setOverlayVisible', { visible: true }, { root: true });

                    let response = await httpAPI({
                        url: 'api/tasks/cancelresolution',
                        method: 'POST',
                        data: { Content: JSON.stringify(content) },
                        headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                    });

                    if (response) {
                        await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                        //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });
                        commit('SET_EXECUTIONTREE', null);

                        if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                            await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);

                        this._vm.$notify.success(response.data.Payload.Message);
                    }

                    dispatch('setOverlayVisible', { visible: false }, { root: true });
                }
            );
        },
        async CancelExecution({ state, dispatch, commit, getters }, { updateSource, actionData }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });
            
            // получаем идентификаторы
            let resolutionId = actionData ? actionData.id : getters.getDataSourceIdentifier;
            let taskId = actionData ? actionData.taskId : getters.getDataSourceTaskId;
            let executerId = actionData
                ? actionData.executerId
                : state.activeExecuter
                    ? state.activeExecuter
                    : sys.guidEmpty();

            // отправляем запрос
            let response = await httpAPI({
                url: `api/tasks/cancelexecution`,
                method: 'POST',
                data: { Content: JSON.stringify({ id: resolutionId, taskId, executerId }) },
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });

            // если запрос обработан успешно запрос
            if (response) {
                if (getters.getDataSourceType == "ReportQuery" || (getters.getDataSourceType == "ProtocolDocument" && state.datasource.Data.Object.Document.Card.TypeC4==3)){
                    await dispatch('updateDocument', null);
                }
                // если действие вызвано из панели действий
                else if (updateSource !== false) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    //commit({type: 'SET_DATASOURCE', datasource: response.data.Payload });
                    //commit('documents/toolbar/SET_MENU', {menu: response.data.Payload.Data.Menu }, { root: true });
                    commit('SET_EXECUTIONTREE', null);

                    if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                        await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                }
                // если действие вызвано из дерева резолюций
                else {
                    if (needLoadControlInfo(state.datasource.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    this._vm.$eventBus.$emit('update-active-tab');
                }

                this._vm.$notify.success(response.data.Payload.Message);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async AcceptResolution({ state, dispatch, commit, getters }, { updateSource, actionData }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });
            
            // получаем идентификаторы
            let resolutionId = actionData ? actionData.resolutionId : getters.getDataSourceIdentifier;
            let taskId = actionData ? actionData.taskId : getters.getDataSourceTaskId;
            let executerId = actionData || state.activeExecuter == null ? sys.guidEmpty() : state.activeExecuter;

            // отправляем запрос
            let response = await httpAPI({
                url: `api/actions/executeraccept`,
                method: 'POST',
                data: { Content: JSON.stringify({ id: resolutionId, taskId, executerId }) },
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });

            // если запрос обработан успешно запрос
            if (response) {

                // если действие вызвано из панели действий
                if (updateSource !== false) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);

                    commit('SET_EXECUTIONTREE', null);

                    if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                        await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                }
                // если действие вызвано из дерева резолюций
                else {
                    if (needLoadControlInfo(state.datasource.Data.Object.Document))
                        await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);

                    this._vm.$eventBus.$emit('update-active-tab');
                }

                this._vm.$notify.success(response.data.Payload.Message);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async TaskProlongate({ state, dispatch, commit, getters }) {
            try
            {
                let task = getters.getDataSourceTask;
                let min = task.ProlongationDate ?? task.InnerLimit;
                let max = _.has(state.datasource, 'Data.Object.Resolution')
                    ? state.datasource.Data.Object.Resolution.Document?.Card.ControlDate
                    : state.datasource.Data.Object.Document?.Card.ControlDate;

                if (!max)
                    max = new Date(8640000000000000);
                
                if (this._vm.$moment(min).isSameOrAfter(this._vm.$moment(max))) {
                    this._vm.$notify.alert(i18n.t('Невозможно_продлить_срок_исполнения,_максимальный_возможный_срок_уже_установлен'));
                    return;
                }

                let prolongateDate = await dispatch('global/dialogs/prolongate/open', { min, max }, { root: true });

                dispatch('setOverlayVisible', { visible: true }, { root: true });

                let response = await httpAPI({
                    url: 'api/actions/taskprolongate',
                    method: 'POST',
                    data:
                    { 
                        Content: JSON.stringify(
                        {
                            id: getters.getDataSourceIdentifier,
                            taskId: task.id,
                            prolongationDate: `/Date(${Date.parse(prolongateDate)})/`
                        })
                    },
                    headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                });

                if (response) {
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    this._vm.$notify.success(response.data.Payload.Message);
                }

                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        //#endregion

        //#region Protocols
        async SendForExecution({ dispatch, getters, rootGetters}){
            try {

                let dlgResult = await dispatch('orders/dialogs/sendForExecution/open', {controlDate: getters.getDataSource.Data?.Object?.Document?.Card?.ControlDate ?? null}, {root: true});

                dispatch('setOverlayVisible', { visible: true, text: `Документ_отправляется...` }, { root: true });

                let response = await httpAPI({
                    url: `api/orders/addresolutionforprotocol?id=${getters.getDataSourceDocumentId}`,
                    method: 'GET',
                    headers: { 'isCommon': getters.isDataSourceCommon },
                });
                if (response){
                    let newResolitionData = response.data.Payload;

                    newResolitionData.Data.Object.Resolution.Tasks[0].IsPeriodic = getters.getDataSource.Data?.Object?.Document?.Card?.Periodicity!=0 ?? false;
                    newResolitionData.Data.Object.Resolution.Tasks[0].InnerLimit = `/Date(${Date.parse(dlgResult.controldate)})/`;
                    newResolitionData.Data.Object.Resolution.Tasks[0].ResolutionText = dlgResult.text;
                    newResolitionData.Data.Object.Resolution.SignerID = dlgResult.from.employeeId;
                    newResolitionData.Data.Object.Resolution.SignerWorkPlaceId = dlgResult.from.workplaceId;
                    newResolitionData.Data.Object.Resolution.SignerName = dlgResult.from.name;

                    let currResolutionTaskExecuters = [];
    
                    let resolutionTaskExecuters = dlgResult.executers.reduce((executersList, selectedExecuter, index) => {
                        executersList.push(sys.prepareResolutionTaskExecuter(newResolitionData.Data.Object.Resolution.Tasks[0].id, index, selectedExecuter));
                        return executersList;
                    }, currResolutionTaskExecuters);

                    if (![3,4].includes(newResolitionData.Data.Object.Resolution.Tasks[0].ControlType) && !resolutionTaskExecuters.some(x => x.Svod === true))
                        resolutionTaskExecuters[0].Svod = true;

                    newResolitionData.Data.Object.Resolution.Tasks[0].Executers = resolutionTaskExecuters;

                    if (!newResolitionData.Data.Object.Resolution.IsCommon){
                    
                        let isParentDocumentCommon =  newResolitionData.Data?.Object?.Resolution?.Document?.IsCommon;
                        let isParentDocumentHasOuterExecuter =
                            newResolitionData.Data?.AdditionalObjects && // есть доп. массив со сведениями
                            newResolitionData.Data?.AdditionalObjects.length && // он не пустой
                            newResolitionData.Data?.AdditionalObjects[1] !== undefined && // и 2-ой элемент массива существует
                            newResolitionData.Data?.AdditionalObjects[1] === true; // и значение 2-ого элемента имеет значение "false" типа boolean (на документе еще нет внешних исполнителей)
                        let isParentProtocolCanBeCommon = 
                            newResolitionData.Data?.AdditionalObjects && // есть доп. массив со сведениями
                            newResolitionData.Data?.AdditionalObjects.length && // он не пустой
                            newResolitionData.Data?.AdditionalObjects[3] !== undefined && // и 4-ий элемент массива существует
                            newResolitionData.Data?.AdditionalObjects[3] === true; // ДирОРД может быть перенесен в общую схему
                        let hasOuterExecuters = newResolitionData.Data?.Object?.Resolution?.Tasks?.[0].Executers.some(x => x.IsInner === false); //some это any
                        let hasOuterEnterprise = newResolitionData.Data?.Object?.Resolution?.Tasks?.[0].Executers.some(x => x.ExecuterID === this.guidEmpty); //some это any

                        newResolitionData.Data.Object.Resolution.IsCommon = isParentDocumentCommon ||
                            (
                                rootGetters['global/auth/isCommonServiceEnabled'] && // и у сервера, с которым работает пользователь есть возможность работы с общими док-тами
                                typeof newResolitionData.Data?.Object?.Resolution?.Document?.Card?.ControlType != 'undefined' &&
                                [1, 2].includes(newResolitionData.Data.Object.Resolution.Document.Card.ControlType) && // если документ контрольный
                                !isParentDocumentHasOuterExecuter && //родительский документ может быть перенесен в общую схему
                                hasOuterExecuters === true && // и в списке выбранных исполнителей есть внешние исполнители
                                hasOuterEnterprise === false && // и в списке выбранных исполнителей нет внешних корреспондентов
                                isParentProtocolCanBeCommon // и родительский ДирОРД может быть перенесен в общую схему
                            );
                        
                    }

                    let entity = JSON.parse(JSON.stringify(newResolitionData.Data.Object.Resolution));
                    let hasType = _.has(entity, '__type');
                    if (!hasType)
                        entity = Object.assign({ __type : 'Resolution:#Avrora.Objects.Modules.Docflow.DocflowObjects' }, entity);

                    let saveResponse = await httpAPI({
                        url: `api/actions/saveandclose?type=Documents|Orders|CitizenStatements.Resolution`,
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'isCommon': newResolitionData.Data.Object.Resolution.IsCommon }, //нужно как-то определить именно новая резолюция коммоновская или нет
                        data: { Content : JSON.stringify({ parent: entity, childrens: null }) },
                    });

                    if (saveResponse){

                        await dispatch('loadDataSource', { id: getters.getDataSourceDocumentId, openType: "Chancellery|Documents.Document", useOverlay: false, navigate: false, common: getters.isDataSourceCommon });
                        this._vm.$notify.success(i18n.t('Действие_успешно_выполнено'));
                    }
                    
                }
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async ContinueProtocolSectionWork({ dispatch, getters, rootGetters}) {
            let userInfo = rootGetters['global/auth/getUserInfo'];
            let dlgResult = await dispatch('orders/dialogs/sendForExecution/open', {from: {type: 1, workplaceId: userInfo.WorkplaceId, name: userInfo.UserName, inner: true, enterprise: userInfo.EnterpriseId}}, {root: true});
            dispatch('setOverlayVisible', { visible: true }, { root: true });
            let content = {
                OriginId: getters.getDataSourceDocumentId,
                FromId: dlgResult.from.workplaceId,
                Text: dlgResult.text,
                ControlDate: `/Date(${Date.parse(dlgResult.controldate)})/`,
                Executers:dlgResult.executers.map(item => item.workplaceId)
            }
            let response = await httpAPI({
                url: `api/orders/continueprotocolsectionwork`,
                method: 'POST',
                data: { Content: JSON.stringify(content) },
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });
            if (response){

                await dispatch('loadDataSource', { id: response.data.Payload.Data.Object, openType: "Chancellery|Documents.Document", useOverlay: true, navigate: true, common: true });                
                /*await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                
                //#region Обрабатываем данные для дерева исполнения в резолюциях / личных поручениях / пунтках
                commit('SET_EXECUTIONTREE', null);

                if (!getters.isDataSourceNewRecord && executionTreeMapper.includes(getters.getDataSourceType))
                    await updateExecutionTree(getters, commit, getters.getDataSourceIdentifier);
                //#endregion
                
                //#region Обрабатываем данные по контролю объекта

                commit('SET_CONTROL_INFO', null );

                if (needLoadControlInfo(response.data.Payload?.Data?.Object?.Document))               
                    await updateControlInfo(getters, commit, getters.getDataSourceDocumentId);
                //#endregion
                */
                this._vm.$notify.success(response.data.Payload.Message);
            }   
            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        //#endregion

        //#region ReportGeneration        
        async SendReportOnExecuting({ dispatch, getters}){
            try {
                let dlgResult = await dispatch('reportsgeneration/dialogs/sendreportonexecution/open', {}, {root: true});
                dispatch('setOverlayVisible', { visible: true, text: `Запрос_отправляется...` }, { root: true });
                let content = {
                    id : getters.getDataSourceDocumentId,
                    executerid: dlgResult.executer.workplaceId,
                    controldate: `/Date(${Date.parse(dlgResult.controldate)})/`
                    
                }

                let response = await httpAPI({
                    url: `api/actions/sendreportonexecution`,
                    method: 'POST',
                    data: { Content : JSON.stringify(content) }
                });

                if (response.data.Payload.Result == "OK")
                {                                                
                    await dispatch('updateDocument', null);
                    this._vm.$notify.success(response.data.Payload.Message);
                }
                else
                {
                    this._vm.$notify.alert(response.data.Payload.Message);
                }                
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },        
        async CancelReport({ dispatch, rootGetters}){
            this._vm.$notify.confirm(
                i18n.t('Отменить_запрос_отчета'),
                async () =>{
                    try {
                        dispatch('setOverlayVisible', { visible: true, text: `Запрос_отменяется...` }, { root: true });
                        let id = rootGetters['global/actionsource/getDataSource'].Data.Object.Document.id;
                        let response = await httpAPI({
                            url: `api/actions/cancelreport?id=${id}`,
                            method: 'GET',
                        });

                        if (response.data.Payload.Result == "OK")
                        {                                                
                            await dispatch('updateDocument', null);
                            this._vm.$notify.success(response.data.Payload.Message);
                        }
                        else
                        {
                            this._vm.$notify.alert(response.data.Payload.Message);
                        }                

                        dispatch('setOverlayVisible', { visible: false }, { root: true });
                    }
                    catch
                    {
                        console.error("Произошла непредвиденная ошибка");
                    }
                }
            );
        },
        async ShowReportForm({ dispatch, getters }, actionData) {

            try
            {
                localStorage.setItem('report-interaction:svod-mode', false);
                localStorage.setItem('report-interaction:auto-svod', false);
                await dispatch('loadDataSource',
                    { 
                        id: "ShowReportForm",
                        openType: "Documents.ReportGeneration.Form",
                        navigate: true,
                        params: actionData.executionData
                            ? { queryId: actionData.executionData.queryId, executionId: actionData.executionData.executionId }
                            : { queryId: getters.getDataSourceIdentifier, executionId: sys.guidEmpty() }
                    });
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
        },
        async ShowSvodReportForm({ dispatch, getters }, actionData) {
            try
            {
                localStorage.setItem('report-interaction:svod-mode', true);
                localStorage.setItem('report-interaction:auto-svod', false);
                await dispatch('loadDataSource',
                    { 
                        id: "ShowReportForm",
                        openType: "Documents.ReportGeneration.Form",
                        navigate: true,
                        params: actionData.executionData ? { ...actionData.executionData } : { queryId: getters.getDataSourceIdentifier, executionId: sys.guidEmpty() }
                    });
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
        },
        async CreateSvodReportForm({ dispatch, getters }, actionData) {
            try
            {
                localStorage.setItem('report-interaction:svod-mode', true);
                localStorage.setItem('report-interaction:auto-svod', true);
                await dispatch('loadDataSource',
                    { 
                        id: "ShowReportForm",
                        openType: "Documents.ReportGeneration.Form",
                        navigate: true,
                        params: actionData.executionData ? { ...actionData.executionData } : { queryId: getters.getDataSourceIdentifier, executionId: sys.guidEmpty() }
                    });
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
        },
        async SaveReportForm({ dispatch }, actionData) {
            try
            {
                //если в действие не передан айди основания, мы не сможем его обработать
                if (!actionData?.queryId)
                    return;

                dispatch('setOverlayVisible', { visible: true, text: "Сохранение..." }, { root: true });
                //получаем текущие данные заполнения
                let reportData = await dispatch('reportsgeneration/reportinteraction/fetchCurrentFillData', null, { root: true });
                //формируем данные для отправки
                let reportInteractionSM = localStorage.getItem('report-interaction:svod-mode');
                let requestData = { reportQueryId: actionData.queryId, svod: reportInteractionSM, reportData: JSON.stringify(reportData) };

                await httpAPI({
                    url: `api/report/savereportform`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify(requestData) },
                    
                });
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
        },
        async SaveReportFormAndClose({ dispatch }, actionData) {
            try
            {
                //если в действие не передан айди основания, мы не сможем его обработать
                if (!actionData?.queryId)
                    return;

                dispatch('setOverlayVisible', { visible: true, text: "Сохранение..." }, { root: true });
                //получаем текущие данные заполнения
                let reportData = await dispatch('reportsgeneration/reportinteraction/fetchCurrentFillData', null, { root: true });
                //формируем данные для отправки
                let reportInteractionSM = localStorage.getItem('report-interaction:svod-mode');
                let requestData = { reportQueryId: actionData.queryId, svod: reportInteractionSM, reportData: JSON.stringify(reportData) };

                let response = await httpAPI({
                    url: `api/report/savereportform`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify(requestData) },
                    
                });

                if (response) {
                    await dispatch('loadDataSource', { id: actionData.queryId, openType: "Documents.ReportGeneration.Query", navigate: true, common: true });
                    this._vm.$notify.success(response.data.Payload.Message);
                }
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
        },
        async SendReportForm({ dispatch }, actionData) {
            try
            {
                //если в действие не передан айди основания, мы не сможем его обработать
                if (!actionData?.queryId)
                    return;

                dispatch('setOverlayVisible', { visible: true, text: "Сохранение..." }, { root: true });
                //получаем текущие данные заполнения
                let reportData = await dispatch('reportsgeneration/reportinteraction/fetchCurrentFillData', null, { root: true });
                //формируем данные для отправки
                let reportInteractionSM = localStorage.getItem('report-interaction:svod-mode');
                let reportInteractionAS = localStorage.getItem('report-interaction:auto-svod');
                let requestData = { reportQueryId: actionData.queryId, svod: reportInteractionSM, auto: reportInteractionAS, reportData: JSON.stringify(reportData) };

                let response = await httpAPI({
                    url: `api/report/sendreportform`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify(requestData) },
                    
                });

                if (response) {
                    await dispatch('loadDataSource', { id: actionData.queryId, openType: "Documents.ReportGeneration.Query", navigate: true, common: true });
                    this._vm.$notify.success(response.data.Payload.Message);
                }
            }
            catch
            {
                console.error("Произошла непредвиденная ошибка");
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
        },
        //#endregion

        //#region Reject
        async Reject({ state, commit, dispatch, getters }) {
            try
            {
                let { reason, comment } = await dispatch('global/dialogs/reject/open', null, { root: true });

                let needSignNotify = (getters.getDataSourceType === 'IncomingDocument' || getters.getDataSourceType === 'EuolStatement')
                                     && state.datasource.Data.Object.Document.SenderID !== '00000000-0000-0000-0000-000000000000'

                let onReject = async (notifySign) => {
                    dispatch('setOverlayVisible', { visible: true, text: `Отклонение_документа...` }, { root: true });
                    let response = await httpAPI({
                        url: 'api/actions/reject',
                        method: 'POST',
                        data:
                        {
                            Content: JSON.stringify({
                                id: getters.getDataSourceIdentifier,
                                reason,
                                comment,
                                notifySign
                            })
                        },
                        headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
                    });
                    dispatch('setOverlayVisible', { visible: false }, { root: true });

                    if (response) { 
                        console.log(response);
                        await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        this._vm.$notify.success(response.data.Payload.Message);
                    }
                };

                if (needSignNotify) {

                    let tryComplete = async () => {
                        let dataToSign = reason != null ? reason.id : comment;
                        let signResult = await dispatch('global/signing/signNotification', { notificationType : 'REJECT', dataToSign }, { root: true });

                        if (signResult.success) {
                            await onReject(signResult.message);
                        }
                        else {

                            let { result } = await dispatch('global/dialogs/notifySignErrorConfirm/open', 'REJECT', { root: true });
                            
                            switch (result)
                            {
                                case 'ok':
                                {
                                    await onReject(null);
                                    break;
                                }
                                case 'retry':
                                {
                                    await tryComplete();
                                    break;
                                }
                                default:
                                    break;
                            }
                        }
                    };

                    await tryComplete();
                }
                else
                    await onReject(null);
            }
            catch (ex)
            {
                console.log(ex);
                console.log(ex.message);
            }
        },
        async CancelReject({ commit, dispatch, getters }) {
            this._vm.$notify.confirm(
                i18n.t('Отменить_отклонение_документа'),
                async () => {
                    dispatch('setOverlayVisible', { visible: true, text: `Документ_восстанавливается...` }, { root: true });

                    let response = await httpAPI({
                        url: `api/actions/cancelreject?id=${getters.getDataSourceIdentifier}`,
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' }
                    });

                    dispatch('setOverlayVisible', { visible: false }, { root: true });

                    if (response) {
                        await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                        this._vm.$notify.success(response.data.Payload.Message);
                    }
                }
            );
        },
        //#endregion

        async SendDocument({ dispatch, getters, commit }) {
            let onSendDocument = async () => {
                dispatch('setOverlayVisible', { visible: true }, { root: true });
                let response = await httpAPI({
                    url: `api/actions/senddocument/${getters.getDataSourceDocumentId}`,
                    method: 'GET'
                });
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {                    
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    this._vm.$notify.success(response.data.Payload.Message);
                }
            };

            this._vm.$notify.confirm(
                i18n.t("Отправить_документ_получателям_вопрос"),
                async () => {
                    await onSendDocument();
                }
            );
        },

        async SendToRegister({ commit, dispatch, getters }) {
            dispatch('setOverlayVisible', { visible: true, text: `Отправка_документа_на_регистрацию...` }, { root: true });

            let response = await httpAPI({
                url: `api/actions/sendtoregister?id=${getters.getDataSourceDocumentId}`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });

            if (response) {
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                this._vm.$notify.success(response.data.Payload.Message);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async Copy({ state, dispatch, commit, getters }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            var response = await httpAPI({
                url: `api/actions/copy/${state.datasource.Data.Object.Document.id}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon },
            });

            if (response) {
                commit({ type: 'SET_DATASOURCE', datasource: null });
                let refs = referencesMapper[response.data?.payload?.Data.Object?.Document?.__type?.split(':')?.[0]];

                if (refs)
                    refs.forEach( async ref => await dispatch('documents/references/getReference', { id: ref }, { root: true }));
                
                await router.push(getRouterParam(response.data.Payload));
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async AddActItem({ dispatch }) {
            dispatch('loadDataSource', { id: null, openType: "Documents|Orders|CitizenStatements.ResolutionTask", createType: "ActItem", navigate: true});
        },
        async SetInspected({ dispatch, commit, getters }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            let response = await httpAPI({
                url: 'api/actions/setinspected',
                method: 'POST',
                data: { Content: JSON.stringify({ id: getters.getDataSourceDocumentId }) },
                headers: { 'Content-Type': 'application/json', 'isCommon': getters.isDataSourceCommon }
            });

            if (response) {
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                this._vm.$notify.success(response.data.Payload.Message);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async MakeOutgoingFromIncoming({ dispatch, state, commit, getters }) {
            dispatch('setOverlayVisible', { visible: true }, { root: true });

            var response = await httpAPI({
                url: `api/actions/makeoutgoingfromincoming/${state.datasource.Data.Object.Document.id}`,
                method: 'GET',
                headers: { 'isCommon': getters.isDataSourceCommon }
            });

            if (response) {
                commit({ type: 'SET_DATASOURCE', datasource: null });
                let refs = referencesMapper[response.data?.Payload?.Data?.Object?.Document?.__type?.split(':')?.[0]];

                if (refs)
                    refs.forEach( async ref => await dispatch('documents/references/getReference', { id: ref }, { root: true }));
                
                await router.push(getRouterParam(response.data.Payload));
                await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
            }

            dispatch('setOverlayVisible', { visible: false }, { root: true });
        },
        async CancelRegister({ dispatch, getters, commit }) {

            let onCancelRegister = async () => {
                dispatch('setOverlayVisible', { visible: true }, { root: true });
                let response = await httpAPI({
                    url: `api/actions/cancelregister`,
                    method: 'POST',
                    headers: { 'isCommon': getters.isDataSourceCommon },
                    data: { Content: JSON.stringify({ id: getters.getDataSourceDocumentId }) },
                });
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (response) {
                    // commit({type: 'SET_DATASOURCE', datasource: response.data.payload });
                    await setDataSourceAndMenu(commit, dispatch, response.data.Payload);
                    this._vm.$notify.success(response.data.Payload.Message);
                }
            };

            this._vm.$notify.confirm(
                i18n.t("Отменить_регистрацию_вопрос"),
                async () => {
                    await onCancelRegister();
                }
            );
        },
    },
    getters: {
        getActiveTabName: (s) => s.activeTabName,
        getDataSource: (s) => s.datasource,
        getControlInfo: (s) => s.controlInfo,
        getExecutionTree: (s) => s.executiontree,

        //#region Утилитарные геттеры
        getDataSourceEntity: (s) => {
            let entity = s.datasource?.Data?.Object;
            let type = s.datasource?.Data?.Object?.__type?.split(':')[0] ?? null;

            switch (type)
            {
                case 'DocumentContainer':
                    entity = s.datasource?.Data?.Object?.Document ?? null;
                    break;

                case 'ResolutionContainer':
                    entity = s.datasource?.Data?.Object?.Resolution ?? null;
                    break;

                default:
                    break;
            }

            return entity;
        },
        getDataSourceIdentifier: (s) => {
            let id = s.datasource?.Data?.Object?.id;
            let type = s.datasource?.Data?.Object?.__type?.split(':')[0] ?? null;

            switch (type)
            {
                case 'DocumentContainer':
                    id = s.datasource?.Data?.Object?.Document?.id ?? null;
                    break;

                case 'ResolutionContainer':
                    id = s.datasource?.Data?.Object?.Resolution?.id ?? null;
                    break;

                default:
                    break;
            }

            return id;
        },
        getDataSourceType: (s) => {
            let type = s.datasource?.Data?.Object?.__type?.split(':')[0] ?? null;

            switch (type)
            {
                case 'DocumentContainer':
                    type = s.datasource?.Data?.Object?.Document?.__type?.split(':')[0] ?? null;
                    break;

                case 'ResolutionContainer':
                {
                    let hasType = _.has(s.datasource, 'Data.Object.Resolution.__type');
                    
                    hasType
                        ? type = s.datasource?.Data?.Object?.Resolution?.__type?.split(':')[0]
                        : type = 'Resolution';

                    break;
                }
                default:
                    break;
            }
            return type;
        },
        getDataSourceFullType: (s) => {
            let type = s.datasource?.Data?.Object?.__type ?? null;

            switch (type)
            {
                case 'DocumentContainer:#Avrora.Objects.Modules.Docflow.Web':
                    type = s.datasource?.Data?.Object?.Document?.__type ?? null;
                    break;

                case 'ResolutionContainer:#Avrora.Objects.Modules.Docflow.Web':
                {
                    let hasType = _.has(s.datasource, 'Data.Object.Resolution.__type');
                    
                    hasType
                        ? type = s.datasource?.Data?.Object?.Resolution?.__type
                        : type = 'Resolution:#Avrora.Objects.Modules.Docflow.DocflowObjects';

                    break;
                }
                default:
                    break;
            }
            
            return type;
        },
        getDataSourceDocumentId: (s) => {
            let id = s.datasource?.Data?.Object?.Document?.id ?? null;
            let type = s.datasource?.Data?.Object?.__type?.split(':')[0] ?? null;

            switch (type)
            {
                case 'DocumentContainer':
                    id = s.datasource?.Data?.Object?.Document?.id ?? null;
                    break;

                case 'ResolutionContainer':
                    id = s.datasource?.Data?.Object?.Resolution?.DocumentID ?? null;
                    break;

                default:
                    break;
            }

            return id;
        },
        getDocumentParentId: (s) => {
            return s.datasource?.Data?.Object?.Document?.ParentId ?? null;
        },
        getDataSourceTaskId: (s) => {
            let taskId = null;
            let type = s.datasource?.Data?.Object?.__type?.split(':')?.[0];

            if (type === 'ResolutionContainer')
                taskId = s.datasource?.Data?.Object?.Resolution?.Tasks?.[0]?.id;
            else
                taskId = s.datasource?.Data?.Object?.Tasks?.[0]?.id;

            return taskId;
        },
        getDataSourceTask: (s) => {
            let task = null;
            let type = s.datasource?.Data?.Object?.__type?.split(':')?.[0];

            if (type === 'ResolutionContainer')
                task = s.datasource?.Data?.Object?.Resolution?.Tasks?.[0];
            else
                task = s.datasource?.Data?.Object?.Tasks?.[0];

            return task;
        },
        isDataSourceNewRecord: (s) => {
            let isNew = s.datasource?.Data?.Object?.is_new_record ?? false;
            let type = s.datasource?.Data?.Object?.__type?.split(':')?.[0];

            switch (type)
            {
                case 'DocumentContainer':
                    isNew = s.datasource?.Data?.Object?.Document?.is_new_record;
                    break;

                case 'ResolutionContainer':
                    isNew = s.datasource?.Data?.Object?.Resolution?.is_new_record;
                    break;

                default:
                    break;
            }
            
            return isNew;
        },
        isDataSourceCommon: (s) => {
            let common = s.datasource?.Data?.Object?.IsCommon;
            let type = s.datasource?.Data?.Object?.__type?.split(':')[0] ?? null;

            switch (type)
            {
                case 'DocumentContainer':
                    common = s.datasource?.Data?.Object?.Document?.IsCommon ?? null;
                    break;

                case 'ResolutionContainer':
                    common = s.datasource?.Data?.Object?.Resolution?.IsCommon ?? null;
                    break;

                default:
                    break;
            }

            return common;
        },
        isDataSourceDocument: (s) => {
            let type = s.datasource?.Data?.Object?.__type?.split(':')[0] ?? null;
            return type === 'DocumentContainer';
        },
        isEuolStatementDataSource: (s) => {
            return s.datasource?.Data?.Object?.Document?.__type === 'EuolStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects'
        },
        isDocumentIsArchive: (s) => {
            return !!s.datasource?.Data?.Object?.Document?.ArchYear;
        },
        isMainExecuterGiveAnswer: (s) => s.isMainExecuterGiveAnswer
        //#endregion
    },
};

export default actionsource;
