import { httpAPI } from "@/api/httpAPI";

import lists from './lists'
import dialogs from './dialogs'
import reportinteraction from "@/store/reportsgeneration/reportinteraction";

const reportsgeneration = {
    namespaced: true,
    state: {
        currentCollection: null,
        updateCounterInProcess: false,
        allmayreports_count: null,
        mycreated_count: null,
        myexecution_count: null,
    },
    mutations: {
        SET_CURRENT_COLLECTION(state, payload) {
            state.currentCollection = payload;
        },
        SET_UPDATE_IN_PROCESS(state, payload) {
            state.updateCounterInProcess = payload;
        },
        SET_ALLMYREPORTS_COUNT(state, payload){
            state.allmayreports_count = payload;
        },
        SET_MYCREATED_COUNT(state, payload){
            state.mycreated_count = payload;
        },
        SET_MYEXECUTION_COUNT(state, payload){
            state.myexecution_count = payload;
        },
    },
    getters: {
        getCurrentCollection: (s) => s.currentCollection,
        getAllMyRportsCount: (s) => s.allmayreports_count,
        getMyCreatedCount: (s) => s.mycreated_count,
        getMyExecutionCount: (s) => s.myexecution_count,
    },
    actions:{
        async updateCounter({commit, state}){
            if (!state.updateCounterInProcess){
                commit('SET_UPDATE_IN_PROCESS', true);
                try {
                    let response = await httpAPI({
                        url: `api/report/counter`,
                        method: 'GET',
                    });

                    if (response?.data?.Payload?.Data?.Object && Array.isArray(response.data.Payload.Data.Object)){
                        commit('SET_ALLMYREPORTS_COUNT', response.data.Payload.Data.Object[0]);
                        commit('SET_MYCREATED_COUNT', response.data.Payload.Data.Object[7]);
                        commit('SET_MYEXECUTION_COUNT', response.data.Payload.Data.Object[13]);
                    }
                }
                finally {
                    commit('SET_UPDATE_IN_PROCESS', false);
                }
            }
        }
    },
    modules: {
        lists,
        dialogs,
        reportinteraction
    }
}

export default reportsgeneration